import { reactive } from 'vue';
import {
  get,
  isArray,
  isEmpty,
  each,
  has,
  set,
  cloneDeep,
} from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { Pinia, defineStore } from 'pinia';
import type {
  SelectListItemType,
  MultichoiceQuestionOptionType,
  MultichoiceQuestionType,
  QuestionType,
  SalaryRangeType,
  SelectListRecordType,
} from '@/types/logged';
import { FileType } from '@/types/attachment';

type StepsType = Record<
  'form' | 'questions' | 'preview',
  { isDone: boolean; canSkip: boolean }
>;

type preRecordedVideoRequestType = {
  id: string;
  question: string;
  isEditing?: boolean;
};

interface State {
  id: string | '';
  title: string | '';
  department: SelectListRecordType | null;
  type: string | '';
  positionsCount: number;
  country: SelectListRecordType | null;
  city: SelectListRecordType | null;
  isRemote: boolean;
  hiringManager: string | '';
  assignedRecruiter: string | '';
  salaryType: string | '';
  currency: string | '';
  externalJobLink: string | null;
  salary: SalaryRangeType;
  preRecordedVideo: preRecordedVideoRequestType[];
  preRecordedVideoRequest: preRecordedVideoRequestType[];
  experience: string | '';
  isNegotiable: boolean;
  description: string | '';
  selectedSkills: SelectListRecordType[];
  aboutCompany: string | '';
  assessmentSkills: Array<
    SelectListRecordType & { isMandatory: boolean }
  >;
  closingDate: Date | null;
  fileIds: string[];
  savedFiles: (string | FileType)[];
  questions: QuestionType[];
  referralBounty: number | null | undefined;
  referralBountyCurrency: string | null | undefined;
  referralBountyCurrencyMap: SelectListItemType | null | undefined;
  steps: StepsType;
  currentStep: keyof StepsType;
  editor: Record<string, unknown>;
  companyJobTypes: SelectListItemType[];
  experienceLevels: SelectListItemType[];
  salaryTypes: SelectListItemType[];
  currencies: SelectListItemType[];
  errors: Record<string, string>;
}

export const useCreateJob = defineStore(
  'create_job',
  () => {
    const state = reactive<State>({
      id: '',
      title: '',
      department: null,
      type: '',
      positionsCount: 0,
      country: null,
      city: null,
      isRemote: false,
      hiringManager: '',
      assignedRecruiter: '',
      salaryType: '',
      currency: '',
      salary: {
        min: 0,
        max: 0,
      },
      preRecordedVideo: [],
      preRecordedVideoRequest: [],
      experience: '',
      isNegotiable: false,
      description: '',
      selectedSkills: [],
      aboutCompany: '',
      assessmentSkills: [],
      closingDate: null,
      fileIds: [],
      savedFiles: [],
      questions: [],
      externalJobLink: null,
      referralBounty: null,
      referralBountyCurrency: null,
      referralBountyCurrencyMap: null,
      steps: reactive<StepsType>({
        form: {
          isDone: false,
          canSkip: false,
        },
        questions: {
          isDone: false,
          canSkip: true,
        },
        preview: {
          isDone: false,
          canSkip: false,
        },
      }),
      currentStep: 'form',
      editor: {
        questionUuid: '',
        optionUuid: '',
      },
      companyJobTypes: [],
      experienceLevels: [],
      salaryTypes: [],
      currencies: [],
      errors: {},
    });

    const hasErrors = computed<boolean>(() => {
      return !isEmpty(state.errors);
    });

    const mode = computed<'edit' | 'create'>(() => {
      return state.id ? 'edit' : 'create';
    });

    const isEditingAQuestion = computed<boolean>(() => {
      return (
        !!state.editor.questionUuid &&
        !!state.questions.find(
          (question) => question.uuid === state.editor.questionUuid,
        )
      );
    });

    const skills = computed<string[]>(() => {
      return state.selectedSkills.map((skill) => skill.value);
    });

    const selectedName = computed(() => {
      return (
        name:
          | 'country'
          | 'city'
          | 'industry'
          | 'department'
          | 'companyJobType'
          | 'experienceLevel'
          | 'salaryType'
          | 'currency',
        nameKey = 'name',
      ): string | null => {
        switch (name) {
          case 'country':
            return state.country?.label || null;

          case 'city':
            return state.city?.label || null;

          case 'department':
            return state.department?.label || null;

          case 'companyJobType':
            return get(
              state.companyJobTypes.find(
                ({ id }) => id === state.type,
              ) || {},
              nameKey,
            );

          case 'experienceLevel':
            return get(
              state.experienceLevels.find(
                ({ id }) => id === state.experience,
              ) || {},
              nameKey,
            );

          case 'salaryType':
            return get(
              state.salaryTypes.find(
                ({ id }) => id === state.salaryType,
              ) || {},
              nameKey,
            );

          case 'currency':
            return get(
              state.currencies.find(
                ({ id }) => id === state.currency,
              ) || {},
              nameKey,
            );

          default:
            return null;
        }
      };
    });

    const canSave = computed<boolean>(() => {
      return state.title.length > 3 && isQuestionsValid.value;
    });

    const canSkipStep = computed(() => {
      return (step: keyof StepsType): boolean => {
        return state.steps[step].canSkip;
      };
    });

    const questionEditorError = computed(() => {
      return (
        question: QuestionType,
        updatedString?: string,
      ): string => {
        const contentLength =
          String(updatedString || question.text).trim().length ||
          question.text.length;

        if (!contentLength) {
          return 'Ooops.. Please input a valid question';
        }

        if (contentLength > 10000) {
          return 'Ooops.. Please question should be less tahn 10,000 characters';
        }

        return '';
      };
    });

    const questionValidityError = computed(() => {
      return (question: QuestionType): string => {
        const _questionEditorError =
          questionEditorError.value(question);
        if (_questionEditorError) return _questionEditorError;

        if (question.type === 'multichoice') {
          if (!isArray(question.options)) {
            return 'Ooops.. Please create options for this question';
          } else if (question.options.length < 2) {
            return 'Ooops.. Please create more than one (1) option for this question';
          } else if (
            question.options.find(
              (option) => option.text.length === 0,
            )
          ) {
            return 'Ooops.. An option cannot be empty';
          }
        }

        return '';
      };
    });

    const questionOptionError = computed(() => {
      return (data: {
        question: MultichoiceQuestionType;
        option: MultichoiceQuestionOptionType;
        text?: string;
      }): string => {
        const savedQuestionIndex = state.questions.findIndex(
          ({ uuid }) => uuid === data.question.uuid,
        );

        if (savedQuestionIndex < 0) return '';

        const savedQuestionOptionIndex =
          data.question.options.findIndex(
            ({ uuid }) => uuid === data.option.uuid,
          );

        if (savedQuestionOptionIndex < 0) return '';

        const contentLength = String(
          data.text || data.option.text,
        ).trim().length;

        if (!contentLength) {
          return 'Ooops.. Please input a valid option';
        }

        if (contentLength > 5000) {
          return 'Ooops.. Please option should be less tahn 5,000 characters';
        }

        return '';
      };
    });

    const isEditingQuestion = computed(() => {
      return (question: QuestionType): boolean => {
        return state.editor.questionUuid === question.uuid;
      };
    });

    const isEditingQuestionOption = computed(() => {
      return (data: {
        question: MultichoiceQuestionType;
        option: MultichoiceQuestionOptionType;
      }): boolean => {
        return state.editor.optionUuid === data.option.uuid;
      };
    });

    const canAddNewOptionToQuestion = computed(() => {
      return (question: MultichoiceQuestionType): boolean => {
        if (question.type === 'multichoice') {
          if (
            question.options &&
            isArray(question.options) &&
            question.options.length > 0
          ) {
            return !question.options.some(
              (option) =>
                !!questionOptionError.value({ question, option }),
            );
          }

          return !questionEditorError.value(question);
        }

        return false;
      };
    });

    const isQuestionsValid = computed<boolean>(() => {
      return !state.questions.find(
        (question) => !!questionValidityError.value(question),
      );
    });

    const clearPreRecordedVideoInterview = (): void => {
      state.preRecordedVideoRequest = [];
    };

    const clearAssessmentSkills = (): void => {
      state.assessmentSkills = [];
    };

    const setAssessmentSkills = (
      assessmentSkills: (SelectListRecordType & {
        isMandatory: boolean;
      })[],
    ) => {
      state.assessmentSkills = assessmentSkills;
    };

    const clearAll = (): void => {
      state.id = '';
      state.title = '';
      state.department = null;
      state.type = '';
      state.positionsCount = 0;
      state.country = null;
      state.city = null;
      state.isRemote = false;
      state.hiringManager = '';
      state.assignedRecruiter = '';
      state.salaryType = '';
      state.preRecordedVideo = [];
      state.currency = '';
      state.salary = {
        min: 0,
        max: 0,
      };
      state.experience = '';
      state.isNegotiable = false;
      state.description = '';
      state.selectedSkills = [];
      state.assessmentSkills = [];
      state.preRecordedVideoRequest = [];
      state.referralBounty = null;
      state.referralBountyCurrency = null;
      state.referralBountyCurrencyMap = null;
      state.aboutCompany = '';
      state.closingDate = null;
      state.fileIds = [];
      state.savedFiles = [];
      state.questions = [];
      state.steps = reactive<StepsType>({
        form: {
          isDone: false,
          canSkip: false,
        },
        questions: {
          isDone: false,
          canSkip: true,
        },
        preview: {
          isDone: false,
          canSkip: false,
        },
      });
      state.currentStep = 'form';
      state.editor = {
        questionUuid: '',
        optionUuid: '',
      };
      state.companyJobTypes = [];
      state.experienceLevels = [];
      state.salaryTypes = [];
      state.currencies = [];
      state.errors = {};
    };

    const setVideoInterview = (
      interviewQuestion: preRecordedVideoRequestType[],
    ) => {
      state.preRecordedVideo = reactive<
        preRecordedVideoRequestType[]
      >(cloneDeep(interviewQuestion));
    };

    const getDefaultPreRecordedQuestions =
      async (): Promise<void> => {
        try {
          const videoRequestResp = await useRequest(
            `ats/video-interview-questions?default=true`,
          );

          if (videoRequestResp.status === 200) {
            const data = videoRequestResp._data;
            const question =
              (get(
                data,
                'body',
                [],
              ) as preRecordedVideoRequestType[]) || [];

            setVideoInterview(question);
          }
        } catch (e) {
          console.log(
            'error in getting default video interview questions ===> ',
            e,
          );
        }
      };

    const setCompanyJobTypes = (
      companyJobTypes: SelectListItemType[],
    ): void => {
      state.companyJobTypes = companyJobTypes;
    };

    const setExperienceLevels = (
      experienceLevels: SelectListItemType[],
    ): void => {
      state.experienceLevels = experienceLevels;
    };

    const setSalaryTypes = (
      salaryTypes: SelectListItemType[],
    ): void => {
      state.salaryTypes = salaryTypes;
    };

    const setCurrencies = (
      currencies: SelectListItemType[],
    ): void => {
      state.currencies = currencies;
    };

    const setSelectedSkills = (
      selectedSkills: SelectListRecordType[],
    ): void => {
      state.selectedSkills = selectedSkills;
    };

    const setCurrentStep = (step: keyof StepsType): void => {
      state.currentStep = step;
    };

    const skipToStep = (step: keyof StepsType): boolean => {
      if (canSkipStep.value(step)) {
        setCurrentStep(step);

        return true;
      }

      return false;
    };

    const setEditedQuestion = (question: QuestionType): void => {
      state.editor.questionUuid = question.uuid;
      state.editor.optionUuid = '';
    };

    const editQuestion = (question: QuestionType): boolean => {
      if (isQuestionsValid.value) {
        setEditedQuestion(question);
        return true;
      }

      setEditedQuestion(question);
      return false;
    };

    const blurEditedQuestion = (question: QuestionType): void => {
      if (state.editor.questionUuid === question.uuid) {
        state.editor.questionUuid = '';
      }
    };

    const stopEditingQuestion = (
      question?: QuestionType,
    ): boolean => {
      if (question && questionEditorError.value(question)) {
        blurEditedQuestion(question);
        return true;
      }

      return false;
    };

    const blurEditedQuestionOption = (
      option: MultichoiceQuestionOptionType,
    ): void => {
      if (state.editor.optionUuid === option.uuid) {
        state.editor.optionUuid = '';
      }
    };

    const stopEditingQuestionOption = (data?: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
    }): boolean => {
      if (
        data &&
        !questionEditorError.value(data.question) &&
        !questionOptionError.value(data)
      ) {
        blurEditedQuestionOption(data.option);
        return true;
      }

      return false;
    };

    const setEditedQuestionOption = (
      option: MultichoiceQuestionOptionType,
    ): void => {
      state.editor.optionUuid = option.uuid;
      state.editor.questionUuid = '';
    };

    const editQuestionOption = (data: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
    }): boolean => {
      if (!questionEditorError.value(data.question)) {
        setEditedQuestionOption(data.option);
        return true;
      }

      return false;
    };

    const updateState = (updatedState: Partial<State>): void => {
      each(updatedState, (value, key) => {
        if (has(state, key)) {
          set(state, key, value);
        }
      });
    };

    const saveAsDraft = (): void => {
      if (!canSave.value) throw new Error('CANNOT-SAVE-POST');
      const id = '0';
      updateState({
        id,
      });
    };

    const updateQuestionText = (data: {
      question: QuestionType;
      text: string;
    }): void => {
      const questionIndex = state.questions.findIndex(
        ({ uuid: savedQuestionUuid }) =>
          savedQuestionUuid === data.question.uuid,
      );

      if (questionIndex > -1) {
        state.questions[questionIndex].text = data.text;
      }
    };

    const setQuestionText = (data: {
      question: QuestionType;
      text: string;
    }): boolean => {
      if (!questionEditorError.value(data.question, data.text)) {
        updateQuestionText(data);
        return true;
      }

      return false;
    };

    const updateQuestionOptionText = (data: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
      text: string;
    }): void => {
      const savedQuestionIndex = state.questions.findIndex(
        ({ uuid }) => uuid === data.question.uuid,
      );

      if (savedQuestionIndex > -1) {
        const savedQuestionOptionIndex =
          data.question.options.findIndex(
            ({ uuid }) => uuid === data.option.uuid,
          );

        (
          state.questions[
            savedQuestionIndex
          ] as MultichoiceQuestionType
        ).options[savedQuestionOptionIndex].text = data.text;
      }
    };

    const setQuestionOptionText = (data: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
      text: string;
    }): boolean => {
      if (!questionOptionError.value(data)) {
        updateQuestionOptionText(data);
        return true;
      }

      return false;
    };

    const addNewQuestion = (type: QuestionType['type']): void => {
      const question: QuestionType = {
        type,
        uuid: uuidv4(),
        text: '',
      } as QuestionType;

      if (question.type === 'multichoice') {
        question.options = [];
      }

      state.questions.push(question);
      state.editor.questionUuid = question.uuid;
      state.editor.optionUuid = '';
    };

    const createQuestion = (type: QuestionType['type']): boolean => {
      const editingOptionUuid = state.editor.optionUuid as string;

      const questions = state.questions as QuestionType[];

      if (editingOptionUuid) {
        const editingOptionQuestion = questions.find(
          (question) =>
            question.type === 'multichoice' &&
            !!question.options.find(
              (option) => option.uuid === editingOptionUuid,
            ),
        );

        if (
          editingOptionQuestion &&
          editingOptionQuestion.type === 'multichoice'
        ) {
          const option = editingOptionQuestion.options.find(
            (option) => option.uuid === editingOptionUuid,
          );

          if (option) {
            if (
              option.text.length === 0 &&
              editingOptionQuestion.options.length >= 3
            ) {
              removeQuestionOption({
                question: editingOptionQuestion,
                option,
              });
            } else {
              return false;
            }
          }
        }
      }

      if (isQuestionsValid) {
        addNewQuestion(type);
        return true;
      }

      return false;
    };

    const addNewQuestionOption = (
      question: MultichoiceQuestionType,
    ): void => {
      const savedQuestionIndex = state.questions.findIndex(
        ({ uuid }) => uuid === question.uuid,
      );

      if (savedQuestionIndex > -1) {
        const option: MultichoiceQuestionOptionType = {
          uuid: uuidv4(),
          type: 'wysiwyg',
          text: '',
          isAnswer: false,
        };

        (
          state.questions[
            savedQuestionIndex
          ] as MultichoiceQuestionType
        ).options.push(option);
        state.editor.questionUuid = '';
        state.editor.optionUuid = option.uuid;
      }
    };

    const createQuestionOption = (
      question?: MultichoiceQuestionType,
    ): boolean => {
      if (question && !questionEditorError.value(question)) {
        addNewQuestionOption(question);
        return true;
      }

      return false;
    };

    const removeQuestion = (question: QuestionType): void => {
      const savedQuestionIndex = state.questions.findIndex(
        ({ uuid }) => uuid === question.uuid,
      );

      if (savedQuestionIndex > -1) {
        state.questions.splice(savedQuestionIndex, 1);
      }
    };

    const deleteQuestion = (question: QuestionType): boolean => {
      removeQuestion(question);
      return true;
    };

    const removeQuestionOption = (data: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
    }): void => {
      const savedQuestionIndex = state.questions.findIndex(
        ({ uuid }) => uuid === data.question.uuid,
      );

      if (savedQuestionIndex > -1) {
        const options = (
          state.questions[
            savedQuestionIndex
          ] as MultichoiceQuestionType
        ).options;

        const savedQuestionOptionIndex = options.findIndex(
          ({ uuid }) => uuid === data.option.uuid,
        );

        if (savedQuestionOptionIndex > -1) {
          options.splice(savedQuestionOptionIndex, 1);
        }
      }
    };

    const deleteQuestionOption = (data: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
    }): boolean => {
      removeQuestionOption(data);
      return true;
    };

    const makeQuestionOptionAnswer = (data: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
      isAnswer: boolean;
    }): void => {
      const savedQuestionIndex = state.questions.findIndex(
        ({ uuid }) => uuid === data.question.uuid,
      );

      if (savedQuestionIndex > -1) {
        const options = (
          state.questions[
            savedQuestionIndex
          ] as MultichoiceQuestionType
        ).options;

        const savedQuestionOptionIndex = options.findIndex(
          ({ uuid }) => uuid === data.option.uuid,
        );

        if (savedQuestionOptionIndex > -1) {
          (
            state.questions[
              savedQuestionIndex
            ] as MultichoiceQuestionType
          ).options = options.map((savedOption) => {
            const state = data.isAnswer
              ? data.option.uuid === savedOption.uuid
              : false;

            savedOption.isAnswer = state;
            return savedOption;
          });
        }
      }
    };

    const setQuestionOptionAnswer = (data: {
      question: MultichoiceQuestionType;
      option: MultichoiceQuestionOptionType;
      isAnswer: boolean;
    }): boolean => {
      if (
        !questionEditorError.value(data.question) &&
        !questionOptionError.value(data)
      ) {
        makeQuestionOptionAnswer(data);
        return true;
      }

      return false;
    };

    const initialState = cloneDeep(state);

    const isModuleReady = async () => {
      const nuxt = useNuxtApp();
      const createJob = useCreateJob(nuxt.$pinia as Pinia);

      if (
        createJob.$persistedState &&
        createJob.$persistedState.isReady
      ) {
        await createJob.$persistedState.isReady();
      }

      return true;
    };

    const $reset = async () => {
      const nuxt = useNuxtApp();
      const createJob = useCreateJob(nuxt.$pinia as Pinia);
      await createJob.isModuleReady();

      createJob.$patch(
        cloneDeep(initialState) as Record<string, unknown>,
      );
    };

    return {
      isModuleReady,
      $reset,
      ...toRefs(state),
      hasErrors,
      mode,
      isEditingAQuestion,
      skills,
      selectedName,
      canSave,
      canSkipStep,
      questionEditorError,
      questionValidityError,
      questionOptionError,
      isEditingQuestion,
      isEditingQuestionOption,
      canAddNewOptionToQuestion,
      isQuestionsValid,
      clearPreRecordedVideoInterview,
      clearAssessmentSkills,
      clearAll,
      setVideoInterview,
      getDefaultPreRecordedQuestions,
      setCompanyJobTypes,
      setExperienceLevels,
      setSalaryTypes,
      setCurrencies,
      setSelectedSkills,
      setCurrentStep,
      skipToStep,
      setEditedQuestion,
      editQuestion,
      blurEditedQuestion,
      stopEditingQuestion,
      blurEditedQuestionOption,
      stopEditingQuestionOption,
      setEditedQuestionOption,
      editQuestionOption,
      updateState,
      saveAsDraft,
      updateQuestionText,
      setQuestionText,
      updateQuestionOptionText,
      setQuestionOptionText,
      addNewQuestion,
      createQuestion,
      addNewQuestionOption,
      createQuestionOption,
      removeQuestion,
      deleteQuestion,
      deleteQuestionOption,
      makeQuestionOptionAnswer,
      setQuestionOptionAnswer,
      setAssessmentSkills,
    };
  },
  {
    persistedState: {
      persist: true,
    },
  },
);
