import validate from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45to_45valid_45page_45global from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/middleware/redirect-to-valid-page.global.ts";
import set_45partner_45domain_45global from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/middleware/set-partner-domain.global.ts";
export const globalMiddleware = [
  validate,
  redirect_45to_45valid_45page_45global,
  set_45partner_45domain_45global
]
export const namedMiddleware = {
  messaging: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/middleware/messaging.ts"),
  "redirect-to-valid-page": () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/middleware/redirect-to-valid-page.ts")
}