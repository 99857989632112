import { set, cloneDeep, isArray } from 'lodash-es';
import { defineStore } from 'pinia';
import isNumeric from 'fast-isnumeric';
import {
  applicationRequirementsType,
  CandidateJobDetailType,
  jobAssessmentsType,
} from '@/types/job';
interface State {
  videoInterviewFileId: string | null | '';
  referrerId: string | '' | null;
  isSubmittingApplication: boolean;
  jobAppliedFor: CandidateJobDetailType | null;
  jobAssessments: jobAssessmentsType;
  isLoadingApplicationRequirements: boolean;
  applicationRequirements: applicationRequirementsType | null;
  videoInterviewStage:
    | 'test'
    | 'retry-test'
    | 'record'
    | 'intro'
    | '';
}

export const useJobApplicationFlow = defineStore(
  'job_application_flow',
  () => {
    const state = reactive<State>({
      videoInterviewFileId: '',
      referrerId: '',
      isSubmittingApplication: false,
      jobAppliedFor: null,
      jobAssessments: [],
      isLoadingApplicationRequirements: false,
      applicationRequirements: null,
      videoInterviewStage: '',
    });

    const videoInterviewQuestions = computed<
      { id: string; text: string }[]
    >(() => {
      const questions =
        state.applicationRequirements?.videoInterviewQuestions || [];

      if (isArray(questions)) {
        return questions;
      }

      return [];
    });

    const canContinueProcess = computed<unknown>(() => {
      return !requiredFailedAssessment.value;
    });

    const requiredFailedAssessment = computed(() => {
      return state.jobAssessments.find((assessment) => {
        if (assessment.isRequired) {
          if (
            isNumeric(assessment.assessmentScore) &&
            !assessment.hasPassedAssessment
          ) {
            return true;
          }
        }

        return false;
      });
    });

    const applicationDuration = computed<number>(() => {
      let duration = 0;

      if (videoInterviewQuestions.value.length > 0) {
        duration += 5;
      }

      if (state.jobAssessments.length > 0) {
        const durationInMinutes =
          state.jobAssessments.reduce(
            (prev: number, obj: { duration: number }) => {
              return prev + parseInt(String(obj.duration), 10);
            },
            0,
          ) / 60;

        if (durationInMinutes > 0) {
          duration += durationInMinutes;
        }
      }

      return duration + 3;
    });

    const hasFinishedVideoInterview = computed<unknown>(() => {
      return !!state.videoInterviewFileId;
    });

    const hasFinishedAssessments = computed(() => {
      if (state.jobAssessments && state.jobAssessments.length > 0) {
        return state.jobAssessments.every((assessment) =>
          isNumeric(assessment.assessmentScore),
        );
      }

      return true;
    });

    const currentJobAssessment = computed(() => {
      if (
        !hasFinishedAssessments.value &&
        state.jobAssessments &&
        state.jobAssessments.length > 0
      ) {
        return state.jobAssessments.find(
          (assessment) => !isNumeric(assessment.assessmentScore),
        );
      }

      return null;
    });

    const hasSatisfiedRequirements = computed(() => {
      if (state.jobAssessments && state.jobAssessments.length > 0) {
        if (
          videoInterviewQuestions.value &&
          videoInterviewQuestions.value.length > 0
        ) {
          return (
            hasFinishedAssessments.value &&
            hasFinishedVideoInterview.value
          );
        }

        return hasFinishedAssessments.value;
      }

      if (
        videoInterviewQuestions.value &&
        videoInterviewQuestions.value.length > 0
      ) {
        return hasFinishedVideoInterview.value;
      }

      return true;
    });

    const setReferrerId = (referrerId: string) => {
      state.referrerId = referrerId;
    };

    const resetJobApplicationFlowStates = () => {
      state.isSubmittingApplication = false;
      state.videoInterviewFileId = '';
      state.jobAppliedFor = null;
      state.jobAssessments = [];
      state.referrerId = '';
      state.isLoadingApplicationRequirements = false;
      state.applicationRequirements = null;
      state.videoInterviewStage = '';
    };

    const setVideoInterviewStage = (
      stage: typeof state.videoInterviewStage,
    ) => {
      state.videoInterviewStage = stage;
    };

    const setApplicationRequirements = (
      applicationRequirements: applicationRequirementsType | null,
    ) => {
      state.applicationRequirements = JSON.parse(
        JSON.stringify(applicationRequirements),
      );
    };

    const setIsLoadingApplicationRequirements = (
      isLoadingApplicationRequirements: boolean,
    ) => {
      state.isLoadingApplicationRequirements =
        isLoadingApplicationRequirements;
    };

    const setJobAppliedFor = (
      jobDetails: CandidateJobDetailType | null,
    ) => {
      state.jobAppliedFor = cloneDeep(jobDetails);
    };

    const setJobAssessments = (
      assessmentsDetails: jobAssessmentsType | [],
    ) => {
      const jobAssessments = cloneDeep(assessmentsDetails);

      if (isArray(jobAssessments)) {
        state.jobAssessments = jobAssessments;
      } else {
        state.jobAssessments = [];
      }
    };

    const setJobAssessmentScore = (data: {
      assessmentId: string | number;
      score: string | number;
      hasPassedAssessment: boolean;
    }) => {
      const assessmentIndex = state.jobAssessments.findIndex(
        (assessment) =>
          String(assessment.id) === String(data.assessmentId),
      );

      if (assessmentIndex > -1) {
        set(
          state.jobAssessments,
          `${assessmentIndex}.assessmentScore`,
          parseInt(String(data.score), 10),
        );

        set(
          state.jobAssessments,
          `${assessmentIndex}.hasPassedAssessment`,
          data.hasPassedAssessment,
        );
      }
    };

    const setVideoInterviewFileId = (id: string) => {
      state.videoInterviewFileId = id;
    };

    const fetchApplicationRequirements = async (jobId: string) => {
      setIsLoadingApplicationRequirements(true);

      try {
        const reqResp = await useRequest(
          `ats/${jobId}/application-requirements`,
        );

        const data = reqResp._data as {
          body: applicationRequirementsType;
        };
        const dataBody = data.body;
        setIsLoadingApplicationRequirements(false);
        setApplicationRequirements(dataBody);
        setJobAssessments(dataBody.assessments);

        if (dataBody.videoInterviewFileId) {
          setVideoInterviewFileId(data.body.videoInterviewFileId);
        }
      } catch (e: unknown) {
        setIsLoadingApplicationRequirements(false);

        console.log(e);
      }
    };

    return {
      ...toRefs(state),
      requiredFailedAssessment,
      videoInterviewQuestions,
      canContinueProcess,
      applicationDuration,
      hasFinishedVideoInterview,
      currentJobAssessment,
      hasFinishedAssessments,
      setIsLoadingApplicationRequirements,
      setJobAssessments,
      fetchApplicationRequirements,
      setVideoInterviewFileId,
      setJobAppliedFor,
      setJobAssessmentScore,
      setVideoInterviewStage,
      resetJobApplicationFlowStates,
      hasSatisfiedRequirements,
      setReferrerId,
      setApplicationRequirements,
    };
  },
  {
    persistedState: {
      persist: true,
    },
  },
);
