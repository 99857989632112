import {
  ConstantBackoff,
  ArrayQueue,
  WebsocketBuilder,
  Websocket as WebSocketTs,
} from 'websocket-ts';
import {
  get,
  each,
  has,
  set,
  unset,
  isString,
  isFunction,
  isNull,
  omit,
  pick,
  cloneDeep,
} from 'lodash-es';
import { defineStore, Pinia } from 'pinia';
import uniqid from 'uniqid';
// import { useUser } from './user';
// const user = useUser();

type CallbackType = (
  data: Record<string, unknown> | unknown,
) => Promise<void> | void | unknown;

type TimerType = {
  timer:
    | number
    | ReturnType<typeof setInterval>
    | ReturnType<typeof setTimeout>;
  secondsLeft: number;
};
interface State {
  connections: Record<string, WebSocketTs>;
  handlers: Record<string, CallbackType>;
  timers: Record<string, TimerType>;
  requestTimeout: number;
}

export const useWebSocket = defineStore(
  'websocket',
  () => {
    const state = reactive<State>({
      connections: {},
      handlers: {},
      timers: {},
      requestTimeout: 60000,
    });

    const user = computed(() => {
      return useUser();
    });

    const initialState = cloneDeep(state);

    const handler = computed(() => {
      return (requestId: string): CallbackType => {
        return get(state.handlers, requestId, () => undefined);
      };
    });

    const isConnected = computed(() => {
      return (connectionStr: string): boolean => {
        const connection = get(
          state.connections,
          connectionStr,
          null,
        );

        if (connection) {
          return (
            connection.underlyingWebsocket?.readyState ===
            WebSocket.OPEN
          );
        }

        return false;
      };
    });

    const connection = computed(() => {
      return (connectionStr: string): WebSocketTs => {
        return get(state.connections, connectionStr);
      };
    });

    const generateRequestId = computed<string>(() => {
      let id = uniqid('websocket_request_');
      while (has(state.handlers, id)) {
        id = uniqid('websocket_request_');
      }

      return id;
    });

    const setState = (data: Partial<State>): void => {
      each(data, (value, key) => {
        if (has(state, key)) {
          set(state, key, value);
        }
      });
    };

    const addConnection = (data: {
      connectionStr: string;
      connection: WebSocketTs;
    }): void => {
      state.connections[data.connectionStr] = data.connection;
    };

    const removeConnection = (data: {
      connectionStr: string;
    }): void => {
      unset(state.connections, data.connectionStr);
    };

    const addNewHandler = (data: {
      uniqueStr: string;
      handler: CallbackType;
    }): void => {
      state.handlers[data.uniqueStr] = data.handler;
    };

    const deleteHandler = (data: { uniqueStr: string }): void => {
      state.handlers = omit(state.handlers, data.uniqueStr);
    };

    const addNewTimer = (data: {
      uniqueStr: string;
      data: TimerType;
    }): void => {
      state.timers[data.uniqueStr] = data.data;
    };

    const deleteTimer = (data: { uniqueStr: string }): void => {
      state.timers = omit(state.timers, data.uniqueStr);
    };

    const setTimerData = (data: {
      uniqueStr: string;
      data: Partial<TimerType>;
    }): void => {
      const timer = state.timers[data.uniqueStr];
      if (timer) {
        each(data.data, (value, key) => {
          if (has(timer, key)) {
            set(timer, key, value);
          }
        });
      }
    };

    const addHandler = (data: {
      uniqueStr: string;
      handler: CallbackType;
    }) => {
      addNewHandler(data);
      addNewTimer({
        uniqueStr: data.uniqueStr,
        data: {
          timer: 0,
          secondsLeft: state.requestTimeout,
        },
      });

      const timer = setInterval(() => {
        const secondsLeft = parseInt(
          get(
            state.timers,
            `${data.uniqueStr}.secondsLeft`,
            '-1',
          ) as string,
          10,
        );

        const newSecondsLeft = secondsLeft - 5000;
        if (newSecondsLeft < 0) {
          const removeData = pick(data, ['uniqueStr']);
          removeHandler(removeData);
        } else {
          setTimerData({
            uniqueStr: data.uniqueStr,
            data: {
              secondsLeft: newSecondsLeft,
            },
          });
        }
      }, 5000);

      setTimerData({
        uniqueStr: data.uniqueStr,
        data: {
          timer,
        },
      });
    };

    const removeHandler = (data: { uniqueStr: string }) => {
      deleteHandler(data);

      const timer = get(
        state.timers,
        `${data.uniqueStr}.timer`,
        null,
      ) as number | null;

      if (!isNull(timer)) {
        clearInterval(timer);
      }
      deleteTimer(data);
    };

    const connectWebSocket = (
      connectionStr: string,
    ): Promise<WebSocketTs> => {
      return new Promise((resolve, reject) => {
        try {
          if (isConnected.value(connectionStr)) {
            const ws = get(state.connections, connectionStr);
            if (ws) {
              resolve(ws);
              return;
            }
          }

          if (!(user.value.isLogged && user.value.hasProfile)) {
            reject(
              new Error(
                'Ooops... you must be logged and have a valid profile to access',
              ),
            );

            return;
          }

          new WebsocketBuilder(connectionStr)
            .withBuffer(new ArrayQueue())
            .withBackoff(new ConstantBackoff(2000))
            .onOpen((ws) => {
              sendAuthToken(ws, user.value.token);
              addConnection({ connectionStr, connection: ws });

              console.log('connected here is ===> ', ws);
              resolve(ws);
            })
            .onMessage(async (_, ev) => {
              const data = JSON.parse(ev.data);
              const requestId = get(data, 'requestId');
              const response = get(data, 'response', undefined);

              if (isString(requestId)) {
                const savedHandler = handler.value(requestId);
                if (isFunction(savedHandler)) {
                  await removeHandler({
                    uniqueStr: requestId,
                  });

                  await savedHandler(response);
                }
              }
            })
            .onClose((_, ev) => {
              console.log('closed over here ohhh ==> ', ev);
              removeConnection({ connectionStr });
            })
            .build();
        } catch (e) {
          console.dir(e);
          reject(e);
        }
      });
    };

    const reconnectWebSocket = async (connectionStr: string) => {
      await removeConnection({
        connectionStr,
      });
      await connectWebSocket(connectionStr);
    };

    const sendAuthToken = (ws: WebSocketTs, token: string): void => {
      try {
        ws.send(
          JSON.stringify({
            authToken: token,
          }),
        );
      } catch (e) {
        console.log('error sending auth token ===> ', e);
      }
    };

    const isModuleReady = async () => {
      const nuxt = useNuxtApp();
      const websocket = useWebSocket(nuxt.$pinia as Pinia);

      if (
        websocket.$persistedState &&
        websocket.$persistedState.isReady
      ) {
        await websocket.$persistedState.isReady();
      }

      return true;
    };

    const $reset = async () => {
      const nuxt = useNuxtApp();
      const websocket = useWebSocket(nuxt.$pinia as Pinia);
      await websocket.isModuleReady();

      websocket.$patch(
        cloneDeep(initialState) as Record<string, unknown>,
      );
    };

    return {
      isModuleReady,
      $reset,
      ...toRefs(state),
      generateRequestId,
      connection,
      isConnected,
      user,
      handler,
      sendAuthToken,
      connectWebSocket,
      removeHandler,
      addHandler,
      setTimerData,
      deleteTimer,
      addNewTimer,
      deleteHandler,
      addNewHandler,
      removeConnection,
      reconnectWebSocket,
      setState,
    };
  },
  {
    persistedState: {
      persist: false,
      overwrite: false,
      merge(state /*, savedState */) {
        return state;
      },
    },
  },
);
