export enum SubscriptionPlanName {
  FREEMIUM = 'freemium',
  LITE = 'lite',
  PRO = 'pro',
  ENTERPRISE = 'enterprise',
}

export type SubscriptionPlan = {
  name: SubscriptionPlanName;
  expireOn: Date;
  isRecurring: boolean;
  for: 'talent' | 'company' | 'referrer' | 'all';
};
