<template>
  <div>
    <Loader
      v-if="user.isLoggingOut"
      size="large"
      class="app entry loader"
    />
    <div v-show="!user.isLoggingOut" class="app entry">
      <NuxtLayout />
      <div id="afterApp" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { get, isString } from 'lodash-es';
import { useSound } from '@vueuse/sound';
import { RouteLocation } from 'vue-router';
import newMessageSound from '@/assets/sounds/new-message.mp3';
import newMessageInOpenedConversationSound from '@/assets/sounds/new-message-in-opened-conversation.mp3';
import { setSounds } from '@/utils/sound';

const route = useRoute() as RouteLocation;
const user = useUser();
await user.isModuleReady();

// user.$subscribe((mutation, state) => {
//   console.log(
//     'mutation in user ===> ',
//     mutation.type,
//     mutation.events,
//   );
//   // import { MutationType } from 'pinia'
//   // mutation.type // 'direct' | 'patch object' | 'patch function'
//   // same as cartStore.$id
//   // mutation.storeId // 'cart'
//   // only available with mutation.type === 'patch object'
//   // mutation.payload // patch object passed to cartStore.$patch()

//   // persist the whole state to the local storage whenever it changes
//   // localStorage.setItem('cart', JSON.stringify(state))
// });

const communityMessage = useCommunityMessage();
await communityMessage.isModuleReady();

const webSocketStore = useWebSocket();
await webSocketStore.isModuleReady();

const hasTokenExpired = computed(() => user.hasTokenExpired);
watch(hasTokenExpired, (curr, prev) => {
  if (curr !== prev && curr && route.meta.requiresAuth === true) {
    return navigateTo('/login');
  }
});

const processUniversalLogout = async () => {
  const cookie = getCookie();

  if (!user.subdomain) {
    if (user.isLogged && cookie.get('central-goodtalent-action')) {
      await user.logout(false);
    }

    cookie.remove('central-goodtalent-action');
    cookie.remove('central-goodtalent-action', {
      domain: undefined,
    });
  }
};

watch(
  () => route.fullPath,
  async () => {
    await processUniversalLogout();
    await user.syncToken();
    await nextTick();

    if (!user.isInValidDomain) {
      return await user.rewriteRouteToValidDomain(route);
    }
  },
  {
    immediate: true,
  },
);

const router = useRouter();
const redirectToJob = async () => {
  const jobData = user.redirectAfterAuthData;
  const id = get(jobData, 'id') as string;
  const title = get(jobData, 'title') as string;
  const route = get(jobData, 'route') as string;
  const referrerId = get(jobData, 'referrerId') as string;

  if (referrerId) {
    await navigateTo({
      path: route,
      params: {
        jobTitle: title,
        jobUniqueId: id,
      },
      query: {
        referrerId,
      },
    });
    return;
  }

  await navigateTo({
    path: route,
    params: {
      jobTitle: title,
      jobUniqueId: id,
    },
  });
};

const redirectToMarketPlace = async () => {
  const productData = user.redirectAfterAuthData;
  const id = get(productData, 'id') as string;

  if (id) {
    await navigateTo({
      name: 'marketplace.courseInfo',
      params: {
        id,
      },
    });
  }
};

const redirectToFinishReferral = () => {
  const action = get(user.redirectAfterAuthData, 'action');

  if (action === 'create-referrer-profile') {
    return navigateTo({
      name: 'referral-dashboard',
    });
  } else if (action === 'refer-a-talent') {
    return navigateTo({ name: 'referral-jobs' });
  }
};

watch(
  [
    () => user.isLogged,
    () => user.continue,
    () => user.redirectAfterAuthData,
    () => user.profileType,
  ],
  async () => {
    console.log('app vue effect');

    if (!user.isLogged || !user.continue) {
      return;
    }

    switch (user.profileType) {
      case 'talent': {
        console.log(
          'redirect user data is ====> ',
          user.redirectAfterAuthFor,
        );

        switch (user.redirectAfterAuthFor) {
          case 'job': {
            return redirectToJob();
          }

          case 'refer': {
            // create referrer profile
            const res = await useRequest('profile/referrer', {
              method: 'post',
            });

            if (res.status === 200 || res.status === 204) {
              const data = res._data as {
                body: {
                  token: string;
                };
              };
              const continueData = user.continue;
              user.setToken(data.body.token);
              console.log({ continueData });

              await nextTick();

              return redirectToFinishReferral();
            }
            break;
          }

          case 'marketplace': {
            return redirectToMarketPlace();
          }

          case 'redirect-to-path': {
            const path = user.redirectAfterAuthData?.path || '';

            console.log('path is ====> ', path);

            if (isString(path) && path) {
              await navigateTo(path);
            }

            return;
          }
        }

        break;
      }

      case 'company': {
        const redirectData = user.redirectAfterAuthData;

        if (user.redirectAfterAuthFor === 'company-registration') {
          const plan = get(redirectData, 'plan', '');

          if (plan) {
            return navigateTo('pricing-plans');
          }
        }
        break;
      }

      case 'referrer': {
        switch (user.redirectAfterAuthFor) {
          case 'refer': {
            return redirectToFinishReferral();
          }
        }

        break;
      }

      default: {
        // default switch block for if not a known profile type
        if (!user.profileType) {
          switch (user.redirectAfterAuthFor) {
            case 'talent-registration': {
              return navigateTo({
                name: 'profile-talent-create',
              });
            }

            case 'company-registration': {
              return navigateTo({
                name: 'company-create',
              });
            }

            case 'refer': {
              // create referrer profile
              console.log(
                'DOES NOT HAVE PROFILE YET - CREATE REFERRER PROFILE',
              );
              const res = await useRequest('profile/referrer', {
                method: 'post',
              });

              if (res.status === 200 || res.status === 204) {
                const data = res._data as {
                  body: {
                    token: string;
                  };
                };

                const continueData = user.continue;
                user.setToken(data.body.token);
                user.setState({ continue: continueData });

                await nextTick();

                return redirectToFinishReferral();
              }
              break;
            }
          }
        }
      }
    }

    // no applicable after-auth actions, just continue
    user.clearContinueAfterAuth();
    await nextTick();

    if (user.hasProfile) {
      if (user.userType === 'vendor') {
        return navigateTo({ name: 'marketplace.dashboard' });
      }
      console.log('NO ACTION - GO TO DASHBOARD');
      return user.goToDashboard();
    } else {
      return router.push({
        name: 'profiles',
        query: {
          active: 'new',
        },
      });
    }
  },
);

onMounted(() => {
  try {
    document.body.click();
    const newMessageSoundHandler = useSound(newMessageSound, {
      volume: 1,
      interrupt: true,
    });

    const newMessageInOpenedConversationSoundHandler = useSound(
      newMessageInOpenedConversationSound,
      {
        volume: 1,
        interrupt: true,
      },
    );

    document.body.click();
    setSounds({
      newMessage: newMessageSoundHandler,
      newMessageInOpenedConversation:
        newMessageInOpenedConversationSoundHandler,
    });
  } catch {}
});

onErrorCaptured((err, vm, info) => {
  // showError(err);
  if (!isProduction.value) {
    console.log('an error occurred --->', err, vm, info);
  } else if (isProduction.value) {
    console.log('a production error occurred --->', err, vm, info);
  }

  return !isProduction.value;
});
</script>

<style lang="scss" scoped>
.app.entry.loader {
  height: 100vh;
  width: 100%;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
@use 'animate.css';
@use '@/assets/scss/fonts/lato.scss';
@use '@/assets/scss/_colors.scss' as color;
@use 'sass:color' as sasscolor;

:root {
  --ms-radius: 7px;
  --ms-tag-bg: #{color.$shaded-black-1};
  --ms-tag-color: #{color.$white-1};
  --ms-tag-radius: 7px;
  --ms-tag-font-weight: 400;
  --ms-border-color: #{color.$ash};
  --ms-option-bg-selected-pointed: #{color.$brown-1};
  --ms-option-bg-selected: #{color.$brown-2};
  --ms-dropdown-border-color: #c85310;
  --ms-dropdown-border-width: 1px;
  --ms-dropdown-radius: 6px;
  --ms-ring-width: 3px;
  --ms-ring-color: rgba(170, 170, 170, 0.7);
}

* {
  font-family: Lato, Campton, Verdana, Helvetica, sans-serif, Arial;
  box-sizing: border-box;
}

.multiselect {
  min-height: 50px;

  > .multiselect-tags {
    max-width: 100%;
    overflow-x: auto;
  }

  > .multiselect-spinner {
    border-left: 1px solid brown;
    border-radius: 50%;
    border-top: 1px solid brown;
    background-image: initial;
    background-position: initial;
  }

  > .multiselect-dropdown {
    overflow: hidden;
    overflow-y: auto;
  }
}

/* Make clicks pass-through */
.v-popper__popper {
  display: block !important;
  z-index: 10000;

  > .v-popper__wrapper {
    > .v-popper__inner {
      background: color.$brown-2;
      color: color.$white-1;
      border-radius: 16px;
      padding: 5px 10px;
      font-size: 13px;
    }

    > .v-popper__arrow-container {
      > .v-popper__arrow-outer {
        width: 0;
        height: 0;
        margin: 0;
        border-style: solid;
        position: absolute;
        border-color: color.$brown-2;
        z-index: 1;
      }
    }
  }

  &[data-popper-placement^='top'] {
    > .v-popper__wrapper {
      margin-bottom: 10px;
    }
  }

  &[data-popper-placement^='bottom'] {
    > .v-popper__wrapper {
      > .v-popper__arrow-container {
        > .v-popper__arrow {
          border-width: 0 5px 5px 5px;
          border-left-color: transparent !important;
          border-right-color: transparent !important;
          border-top-color: transparent !important;
          top: -5px;
          left: calc(50% - 5px);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &[data-popper-placement^='right'] {
    > .v-popper__wrapper {
      > .v-popper__arrow-container {
        left: -3px;

        > .v-popper__arrow-outer,
        > .v-popper__arrow-innter {
          left: -1px;
        }
      }
    }
  }

  &[data-popper-placement^='left'] {
    > .v-popper__wrapper {
      > .v-popper__arrow-container {
        right: -9px;
      }
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.15s,
      visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

#afterApp > .options {
  z-index: 1000;
  box-shadow: 4px 3px 8px 0
    sasscolor.adjust(color.$ash-1, $alpha: -0.4);
  width: auto;
  max-width: 30%;
  min-width: 217px;

  > .stage {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;

    .name {
      border-top: 0.2px solid color.$brown-2;
      border-bottom: 0.2px solid color.$brown-2;
      padding: 8px;
      font-size: 13px;
      letter-spacing: 0.1px;
      font-family: 'Lato', sans-serif;
      line-height: 14px;
      color: #000;
      font-weight: 500;
    }

    .status {
      border-style: none;
      background: color.$white-1;
      font-family: 'Lato', sans-serif;
      font-size: 11px;
      letter-spacing: 0.1px;
      padding: 10px;
      font-weight: normal;
      cursor: pointer;
      text-align: left;
      padding-left: 19px;

      &:hover,
      &.active {
        background: color.$light-brown-2;
      }

      &.active {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}

a.iubenda-tp-btn.iubenda-cs-preferences-link {
  display: none !important;
}

body,
body > #__nuxt,
body > #__nuxt > div,
body > #__nuxt > div > .app.entry {
  width: 100%;
}
</style>
