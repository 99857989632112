import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2/dist/index.es.prod.mjs';
import stringify from 'json-stringify-safe';
import { getStoreInstance } from '~~/composables/localforage';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const storage = getStoreInstance(
      'state',
      'pinia',
      'This is the local forage state representation of pinia in memory state',
    );

    nuxtApp.$pinia.use(
      createPersistedStatePlugin({
        serialize: (value) => stringify(value),
        storage,
      }),
    );
  }
});
