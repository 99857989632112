import {
  isObject,
  isString,
  get,
  isArray,
  isBoolean,
} from 'lodash-es';
import { FileType } from '@/types/attachment';

export type PostMediaType = {
  type: 'picture' | 'video' | 'attachment';
  file: FileType;
};

export type GeneralPostProfileType = {
  id: string;
  imageUrl: string;
  name: string;
  isCompany: boolean;
  canView: boolean;
};

export type TalentPostProfileType = GeneralPostProfileType & {
  role: string;
  isCompany: false;
  skills?: {
    id: string;
    name: string;
  }[];
};

export type CompanyPostProfileType = GeneralPostProfileType & {
  industry: string;
  isCompany: true;
};

export type PostProfileType =
  | TalentPostProfileType
  | CompanyPostProfileType;

export type CommunityPostDataType = {
  id: string;
  profile: PostProfileType;
  text: string;
  files: FileType[];
  date: string;
};

export type FeedDataType = CommunityPostDataType & {
  lastRender: number;
  reactionCount: string;
  commentCount: string;
  didUserReact: boolean;
  didUserComment: boolean;
};

export type FeedCommentDataType = CommunityPostDataType & {
  feedId: string;
  reactionCount: string;
  replyCount: string;
  didUserReact: boolean;
  didUserReply: boolean;
};

export type FeedCommentReplyDataType = CommunityPostDataType & {
  feedCommentId: string;
  reactionCount: string;
  didUserReact: boolean;
};

export type FeedCommentReplyState = {
  data: FeedCommentReplyDataType;
};

export type FeedCommentState = {
  data: FeedCommentDataType;
  replies: {
    list: FeedCommentReplyState[];
    hasMore: boolean;
  };
};

export type FeedState = {
  data: FeedDataType;
  comments: {
    list: FeedCommentState[];
    hasMore: boolean;
  };
};

export type CommunityCompanyRecord = {
  companyProfileId: string;
  companyName: string;
  industryName: string;
  companyLogoUrl: string;
  followersCount: string;
};

export type CommunityTalentRecord = {
  talentProfileId: string;
  talentName: string;
  isFollowing: string;
  isConnected: string;
  talentSkills: Array<string>;
  canView: boolean;
  profilePictureUrl: string;
  talentRole: string;
};

export type CommunityProfile = {
  id: string;
  name: string;
  industry?: string;
  role?: string;
  isCompany: boolean;
  files: FileType[];
  viewCount: string;
  postViewCount: string;
  followerCount: string;
  followedCount: string;
  talentConnectionCount: string;
  companyConnectionCount: string;
  pendingConnectionCount: string;
};

export enum ReactionTypes {
  NONE = 0,
  LIKE = 1,
  LOVE = 2,
  CLAP = 3,
  SAD = 4,
  ANGRY = 5,
}

export type ConnectionDataType = {
  id: string;
  profile: PostProfileType;
  followersCount: string;
  date: string;
};

export enum ChatStatus {
  SENDING = 0,
  PENDING = 1,
  SENT = 2,
  DELIVERED = 3,
  READ = 4,
  CANCELED = 5,
}

export type ChatProfileType = {
  imageUrl: string;
  name: string;
  isOwner: boolean;
  isAdmin: boolean;
  canView: boolean;
  type: 'talent' | 'company';
  lastSeen?: string | Date;
  isOnline: boolean;
  uniqueId?: string;
  id: string;
};

export type ChatMediaType = PostMediaType;

export type ChatType = {
  id: string;
  status: ChatStatus;
  user: ChatProfileType;
  text: string;
  files: FileType[];
  date: Date | string;
  showImage: boolean;
  showSubmissionDetails: boolean;
  isRead: boolean;
  isDelivered: boolean;
  isReadForAll?: boolean;
  isDeliveredForAll?: boolean;
  kind: 'chat' | 'divider' | 'loader';
  classNames?: string[];
  hasSubmitted?: boolean;
};

export type ChatDividerType = {
  id: string;
  kind: 'divider';
  value: string;
  style?: Record<string, unknown>;
};

export type ChatLoaderType = {
  id: string;
  kind: 'loader';
  size?: string;
};

export type ChatIntersectLoader = {
  id: string;
  kind: 'intersect-loader';
  lastChatId?: string;
};

export type GeneralFloatingConversationType = {
  id: string;
  isExpanded: boolean;
  isHidden: boolean;
  isFocused: boolean;
  chats: (
    | ChatType
    | ChatDividerType
    | ChatLoaderType
    | ChatIntersectLoader
  )[];
};

export type GeneralConversationType = {
  id: string;
  isFocused: boolean;
  chats: (
    | ChatType
    | ChatDividerType
    | ChatLoaderType
    | ChatIntersectLoader
  )[];
};

export type SingleFloatingConversationType =
  GeneralFloatingConversationType & {
    type: 'single';
    users: ChatProfileType[];
  };

export type SingleConversationType = GeneralConversationType & {
  type: 'single';
  users: ChatProfileType[];
};

export type GroupFloatingConversationType =
  GeneralFloatingConversationType & {
    type: 'group';
    groupId: string;
    title: string;
    users: (ChatProfileType & {
      lastReadId: string;
      lastDeliveredId: string;
    })[];
  };

export type GroupConversationType = GeneralConversationType & {
  type: 'group';
  groupId: string;
  title: string;
  users: (ChatProfileType & {
    lastReadId: string;
    lastDeliveredId: string;
  })[];
};

export type FloatingConversationType =
  | SingleFloatingConversationType
  | GroupFloatingConversationType;

export type ConversationType =
  | SingleConversationType
  | GroupConversationType;

export type GeneralConversationWindowType = {
  id: string;
  chat: ChatType;
  unreadCount?: number;
};

export type SingleConversationWindowType =
  GeneralConversationWindowType & {
    isGroup: false;
    users: ChatProfileType[];
  };

export type GroupConversationWindowType =
  GeneralConversationWindowType & {
    isGroup: true;
    title: string;
    users: (ChatProfileType & {
      lastReadId: string;
      lastDeliveredId: string;
    })[];
  };

export type ConversationWindowType =
  | SingleConversationWindowType
  | GroupConversationWindowType;

export function isConversation(
  data: Record<string, unknown>,
): data is ConversationType {
  return (
    isObject(data) &&
    isString(get(data, 'id')) &&
    isString(get(data, 'type')) &&
    isArray(get(data, 'chats')) &&
    (isArray(get(data, 'users')) || isObject(get(data, 'user'))) &&
    isBoolean(get(data, 'isFocused'))
  );
}

export function isSingleConversation(
  data: Record<string, unknown>,
): data is SingleConversationType {
  return (
    isConversation(data) &&
    get(data, 'type') === 'single' &&
    isObject(get(data, 'user'))
  );
}

export function isGroupConversation(
  data: Record<string, unknown>,
): data is GroupConversationType {
  return (
    isConversation(data) &&
    get(data, 'type') === 'group' &&
    isArray(get(data, 'users'))
  );
}

export function isFloatingConversation(
  data: Record<string, unknown>,
): data is FloatingConversationType {
  return (
    isConversation(data) &&
    isBoolean(get(data, 'isExpanded')) &&
    isBoolean(get(data, 'isHidden')) &&
    isBoolean(get(data, 'isFocused'))
  );
}

export function isFloatingSingleConversation(
  data: Record<string, unknown>,
): data is SingleFloatingConversationType {
  return isFloatingConversation(data) && isSingleConversation(data);
}

export function isFloatingGroupConversation(
  data: Record<string, unknown>,
): data is SingleFloatingConversationType {
  return isFloatingConversation(data) && isGroupConversation(data);
}

export function isConversationWindow(
  data: Record<string, unknown>,
): data is ConversationWindowType {
  return (
    isObject(data) &&
    isString(get(data, 'id')) &&
    isObject(get(data, 'chat')) &&
    isBoolean(get(data, 'isGroup'))
  );
}

export function isSingleConversationWindow(
  data: Record<string, unknown>,
): data is SingleConversationWindowType {
  return isConversationWindow(data) && get(data, 'isGroup') === false;
}

export function isGroupConversationWindow(
  data: Record<string, unknown>,
): data is GroupConversationWindowType {
  return (
    isConversationWindow(data) &&
    get(data, 'isGroup') === true &&
    isArray(get(data, 'users'))
  );
}
