import normalizeUrl from 'normalize-url';
import { useUser } from '~~/store/user';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const { $pinia } = useNuxtApp();
    const user = useUser($pinia);
    await user.isModuleReady();
    await user.syncToken();
    await nextTick();

    const toast = useToast();

    console.log('from path is ==> ', from.fullPath);
    const destinationPageName = String(to.name || '');
    console.log(
      'destination name is ===> ',
      destinationPageName,
      to.fullPath,
      user.isLogged,
    );

    const unloggedPages = [
      'index',
      'find-a-role',
      'login',
      'register',
      'forgot-password',
      'pricing',
      'jobs',
      'job-jobTitle--jobUniqueId-index',
      'job-jobTitle--jobUniqueId',
      'jobs-jobTitle--jobUniqueId',
      'jobs-jobTitle--jobUniqueId-index',
      'acceptable-use',
      'privacy',
      'terms',
    ];

    if (
      !user.isLogged &&
      !unloggedPages.includes(destinationPageName)
    ) {
      const runTimeConfig = useRuntimeConfig();
      user.setState({
        continue: {
          action: 'redirect-to-path',
          url: normalizeUrl(
            `${runTimeConfig.public.uiFullUrl}${to.fullPath}`,
          ),
          path: to.fullPath,
        },
      });

      toast.info('Please login to access secured page.', {
        id: 'redirect-to-valid-page.global',
      });

      return { name: 'login' };
    } /* on initial load, go to dashboard if logged in */ else if (
      user.isLogged &&
      from.fullPath === '/' &&
      to.name === 'index'
    ) {
      return { name: 'dashboard' };
    }

    // Force clearing talent profile cache for company talent creation
    if (from.name && to.name && from.name !== to.name) {
      const fromName = String(from.name);
      const toName = String(to.name);
      const list = ['talents', 'company-talent-create'];

      if (list.includes(fromName) && list.includes(toName)) {
        const talentStore = useTalentProfile();
        await talentStore.isModuleReady();
        await talentStore.reset();
      }
    }
  }
});
