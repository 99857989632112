import { get } from 'lodash-es';

type ColorOpacity = {
  color: string;
  opacity: number | string;
};

class AlphaColor {
  color: string;

  constructor(color: string) {
    this.color = color;
  }

  parseAlphaColor(): ColorOpacity {
    if (
      /^rgba\((\d{1,3}%?\s*,\s*){3}(\d*(?:\.\d+)?)\)$/.test(
        this.color,
      )
    ) {
      return this.parseRgba();
    } else if (
      /^hsla\(\d+\s*,\s*([\d.]+%\s*,\s*){2}(\d*(?:\.\d+)?)\)$/.test(
        this.color,
      )
    ) {
      return this.parseHsla();
    } else if (
      /^#([0-9A-Fa-f]{4}|[0-9A-Fa-f]{8})$/.test(this.color)
    ) {
      return this.parseAlphaHex();
    } else if (/^transparent$/.test(this.color)) {
      return this.parseTransparent();
    }

    return {
      color: this.color,
      opacity: '1',
    };
  }

  parseRgba(): ColorOpacity {
    return {
      color: this.color.replace(/,(?!.*,).*(?=\))|a/g, ''),
      opacity: get(this.color.match(/\.\d+|[01](?=\))/), 0) as string,
    };
  }

  parseHsla(): ColorOpacity {
    return {
      color: this.color.replace(/,(?!.*,).*(?=\))|a/g, ''),
      opacity: get(this.color.match(/\.\d+|[01](?=\))/), 0) as string,
    };
  }

  parseAlphaHex(): ColorOpacity {
    return {
      color:
        this.color.length === 5
          ? this.color.substring(0, 4)
          : this.color.substring(0, 7),
      opacity:
        this.color.length === 5
          ? (
              parseInt(
                this.color.substring(4, 5) +
                  this.color.substring(4, 5),
                16,
              ) / 255
            ).toFixed(2)
          : (parseInt(this.color.substring(7, 9), 16) / 255).toFixed(
              2,
            ),
    };
  }

  parseTransparent(): ColorOpacity {
    return {
      color: '#fff',
      opacity: 0,
    };
  }
}

export default AlphaColor;
