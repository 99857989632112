export enum CompanyProfilePermissionType {
  DASHBOARD_GENERAL = 'dashboard-general',
  JOB_GENERAL = 'job-general',
  TALENT_GENERAL = 'talent-general',
  ASSESSMENT_GENERAL = 'assessment-general',
  INTERVIEW_GENERAL = 'interview-general',
  SOCIAL_GENERAL = 'social-general',
  WALLET_GENERAL = 'wallet-general',
  ACCOUNT_USER_GENERAL = 'account-user-general',
  ACCOUNT_DEPARTMENT_GENERAL = 'account-department-general',
  ACCOUNT_CUSTOM_MESSAGE_GENERAL = 'account-custom-message-general',
  ACCOUNT_COMPANY_PROFILE_GENERAL = 'account-company-profile-general',
  ACCOUNT_COMPANY_SETTING_GENERAL = 'account-company-setting-general',
}

export enum CompanyProfilePermissionPageNameType {
  DASHBOARD_GENERAL = 'dashboard',
  JOB_GENERAL = 'jobs',
  TALENT_GENERAL = 'talents',
  ASSESSMENT_GENERAL = 'assessments',
  INTERVIEW_GENERAL = 'interviews',
  SOCIAL_GENERAL = 'community',
  WALLET_GENERAL = 'wallet',
  ACCOUNT_USER_GENERAL = 'company.users',
  ACCOUNT_DEPARTMENT_GENERAL = 'company.departments',
  ACCOUNT_CUSTOM_MESSAGE_GENERAL = 'company.account.custom-messages',
  ACCOUNT_COMPANY_PROFILE_GENERAL = 'view.company.profile',
  ACCOUNT_COMPANY_SETTING_GENERAL = 'company.settings',
}

export enum CompanyProfilePermissionActionType {
  SEE = 'see',
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}
