import { FetchOptions } from 'ofetch';
import { defineStore } from 'pinia';
import ucwords from 'locutus/php/strings/ucwords';
import {
  cloneDeep,
  has,
  isEmpty,
  set,
  each,
  isEqual,
  isBoolean,
  capitalize,
  isArray,
  findLast,
  isObject,
  get,
  uniq,
  omit,
  uniqBy,
} from 'lodash-es';
import isEmail from 'validator/lib/isEmail';
import { Ref } from 'vue';
import {
  TalentProfileEditType,
  TalentProfileType,
} from '@/types/talentProfile';
import { FileType } from '@/types/attachment';
import { FilePurpose } from '@/support/uploader';

interface State {
  newData: Partial<TalentProfileEditType>;
  oldData: Partial<TalentProfileType>;
  asyncIsEmailValid: boolean | null;
  isCheckingIfEmailIsValid: boolean;
  fileRecords: FileType[];
  isSaving: boolean;
}

export const useReferralProfile = defineStore(
  'referral_profile',
  () => {
    const state = reactive<State>({
      newData: {},
      oldData: {},
      asyncIsEmailValid: false,
      isCheckingIfEmailIsValid: false,
      fileRecords: [],
      isSaving: false,
    });

    const mode = computed(() => {
      const user = useUser();

      if (user.userType === 'company-user') {
        return 'create-company-talent';
      } else if (user.userType === 'candidate') {
        return 'edit-profile';
      } else if (user.userType === 'referrer') {
        return 'edit-profile';
      } else {
        return 'create-profile';
      }
    });

    const name = computed(() => {
      return ucwords(
        `${state.oldData.firstName ?? ''} ${
          state.oldData.lastName ?? ''
        }`,
      );
    });

    const isInCompanyCreateMode = computed(() => {
      return mode.value === 'create-company-talent';
    });
    const isInEditMode = computed(() => {
      return mode.value === 'edit-profile';
    });
    const isNameValid = computed(() => {
      return (
        state.newData.firstName &&
        state.newData.lastName &&
        !(
          isInEditMode.value &&
          state.newData.firstName === state.oldData.firstName &&
          state.newData.lastName === state.oldData.lastName
        )
      );
    });

    const gender = computed(() => {
      return ucwords(state.oldData.gender || '');
    });

    const isGenderValid = computed(() => {
      return (
        !!state.newData.gender &&
        !(
          isInEditMode.value &&
          String(state.newData.gender.value) ===
            String(state.oldData.genderId)
        )
      );
    });

    const headline = computed(() => {
      return ucwords(state.oldData.headline || '');
    });
    const oldProfileFiles = computed(() => {
      if (state.oldData.files) {
        return state.oldData.files;
      }

      return [];
    });

    const oldFiles = computed(() => {
      return state.oldData.files || [];
    });
    const files = computed(() => {
      let fileStack: FileType[] = [];

      fileStack = uniqBy(
        oldFiles.value.concat(state.fileRecords),
        'id',
      );

      return fileStack;
    });

    const isHeadlineValid = computed(() => {
      return (
        !!state.newData.headline &&
        !(
          isInEditMode.value &&
          state.newData.headline === state.oldData.headline
        )
      );
    });

    const phoneNumber = computed(() => {
      if (state.oldData && state.oldData.phoneNumber) {
        let phoneString = '';

        if (state.oldData.phoneCode) {
          phoneString = state.oldData.phoneCode;
        }

        return phoneString
          ? `${phoneString} ${state.oldData.phoneNumber}`
          : state.oldData.phoneNumber;
      }

      return '';
    });

    const isPhoneNumberValid = computed(() => {
      return (
        state.newData.phoneCode &&
        state.newData.phoneNumber &&
        !(
          isInEditMode.value &&
          String(state.newData.phoneCode.value) ===
            String(state.oldData.phoneCodeId) &&
          state.newData.phoneNumber === state.oldData.phoneNumber
        )
      );
    });

    const location = computed(() => {
      let locationString = '';

      if (state.oldData.country) {
        locationString = capitalize(
          String(state.oldData.country).trim(),
        );
      }

      if (state.oldData.city) {
        const city = capitalize(String(state.oldData.city).trim());

        if (locationString) {
          locationString = `${locationString}, ${city}`;
        } else {
          locationString = city;
        }
      }

      return locationString;
    });

    const isLocationValid = computed(() => {
      return (
        state.newData.city &&
        state.newData.country &&
        !(
          isInEditMode.value &&
          state.newData.city.value === state.oldData.cityId &&
          state.newData.country.value === state.oldData.countryId
        )
      );
    });
    const isEmailTokensValid = computed(() => {
      return (
        !!state.newData.oldEmailToken && !!state.newData.newEmailToken
      );
    });

    const email = computed(() => {
      return ucwords(state.oldData.email || '');
    });

    const isEmailValid = computed(() => {
      const isEmailValid = (state.newData.email &&
        isEmail(state.newData.email) &&
        !(
          isInEditMode.value && state.newData.email === email.value
        )) as boolean;

      let checkAsyncEmailValid = true;
      if (isInCompanyCreateMode.value) {
        if (state.isCheckingIfEmailIsValid) {
          checkAsyncEmailValid = false;
        } else {
          checkAsyncEmailValid = isBoolean(state.asyncIsEmailValid)
            ? state.asyncIsEmailValid
            : false;
        }
      }

      return isEmailValid && checkAsyncEmailValid;
    });

    const preferedRoles = computed((): string[] => {
      return (state.oldData.preferedRoles || [])
        .map((role) => ucwords(role.name))
        .reduce(
          (prevVal, currVal) =>
            prevVal ? `${prevVal}, ${currVal}` : currVal,
          '',
        );
    });

    const isPreferedRolesValid = computed((): boolean => {
      const profilePreferedRoles = state.oldData.preferedRoles || [];
      const newProfilePreferedRoles = state.newData.preferedRoles;

      return !(
        isInEditMode.value &&
        isEqual(profilePreferedRoles, newProfilePreferedRoles)
      );
    });

    const isOldAndNewEmailValid = computed(() => {
      return (
        state.newData.oldEmail &&
        isEmail(state.newData.oldEmail) &&
        state.newData.newEmail &&
        isEmail(state.newData.newEmail) &&
        state.newData.oldEmail !== state.newData.newEmail &&
        state.newData.newEmail !== email.value
      );
    });

    const setNewData = (data: Record<string, unknown>): void => {
      let destObj = state.newData as Record<string, unknown>;
      if (!isObject(destObj)) {
        state.newData = reactive({});
        destObj = state.newData;
      }
      setObjDataDeepForStore(data, destObj);
      triggerRef(state.newData as Ref);
    };

    const profileImageUrl = computed(() => {
      const file = findLast(
        files.value,
        (file) => file.purpose === FilePurpose.TALENT_PROFILE_PICTURE,
      );

      return file?.url || '';
    });

    const getFormattedDataFor = computed(() => {
      return (
        name: string,
        options: Record<string | number | symbol, unknown> = {},
      ) => {
        const obj: Record<string, unknown> = {};
        let trackedKey = name;
        let isValid = false;
        const updatedProfileData: Partial<TalentProfileType> = {};

        switch (name) {
          case 'name':
            isValid = !!isNameValid.value;
            obj.firstName = state.newData.firstName;
            obj.lastName = state.newData.lastName;
            break;
          case 'gender':
            isValid = !!isGenderValid.value;
            obj.genderId = get(state.newData, 'gender.value');
            updatedProfileData.genderId = obj.genderId as string;
            updatedProfileData.gender = get(
              state.newData,
              'gender.label',
            );
            break;
          case 'headline':
            isValid = !!isHeadlineValid.value;
            obj.headline = get(state.newData, 'headline');
            break;

          case 'email':
            isValid = !!isEmailValid.value;
            obj.email = get(state.newData, 'email');
            break;
          case 'action_send_verification_emails':
            trackedKey = 'email';
            isValid = !!isOldAndNewEmailValid.value;
            obj.oldEmail = get(state.newData, 'oldEmail');
            obj.newEmail = get(state.newData, 'newEmail');
            break;
          case 'action_verify_email_tokens':
            trackedKey = 'email';
            isValid = !!isEmailTokensValid.value;
            obj.oldEmailToken = get(state.newData, 'oldEmailToken');
            obj.newEmailToken = get(state.newData, 'newEmailToken');
            break;
          case 'phoneNumber':
            isValid = !!isPhoneNumberValid.value;
            obj.phoneCodeId = get(state.newData, 'phoneCode.value');
            obj.phoneNumber = get(state.newData, 'phoneNumber');
            updatedProfileData.phoneCodeId = get(
              state.newData,
              'phoneCode.value',
            );
            updatedProfileData.phoneCode = get(
              state.newData,
              'phoneCode.label',
            );
            updatedProfileData.phoneNumber = get(
              state.newData,
              'phoneNumber',
            ) as string;
            break;
          case 'location':
            isValid = !!isLocationValid.value;
            obj.countryId = get(state.newData, 'country.value');
            obj.cityId = String(get(state.newData, 'city.value', ''));
            updatedProfileData.countryId = get(
              state.newData,
              'country.value',
            );
            updatedProfileData.country = get(
              state.newData,
              'country.label',
            );
            updatedProfileData.cityId = get(
              state.newData,
              'city.value',
            );
            updatedProfileData.city = get(
              state.newData,
              'city.label',
            );
            break;

          case 'files': {
            const removedId = get(options, 'removedId', undefined);
            const fileIds = state.newData.fileIds || [];

            isValid = true;
            obj.fileIds = uniq(
              state.fileRecords
                .map((file) => file.id)
                .concat(cloneDeep(fileIds))
                .filter((fileId) => fileId !== removedId),
            );
            break;
          }
        }

        if (
          [
            'isActivelySeeking',
            'isPassivelySeeking',
            'isAvailable',
            'onlyGigs',
            'onlyProjects',
            'onlyPartTime',
            'interestedInShortTermContract',
            'interestedInFullTimeSalaried',
            'interestedInFreelance',
            'interestedInRemote',
            'interestedInOnSite',
          ].includes(name)
        ) {
          isValid = true;
        }

        if (isValid && isEmpty(updatedProfileData)) {
          if (has(state.newData, name)) {
            set(updatedProfileData, name, get(state.newData, name));
          }
        }

        return {
          talentProfileRecord: obj,
          isValid,
          trackedKey,
          updatedProfileData,
        };
      };
    });

    const reset = (): void => {
      state.newData = {};
      state.oldData = {};
      state.isSaving = false;
    };

    const clearNewData = (): void => {
      state.newData = {};
    };

    const setEmail = (email: string): void => {
      if (!isObject(state.newData)) {
        state.newData = reactive({});
      }

      set(state.newData, 'email', email);
    };

    const setOldData = (data: Record<string, unknown>): void => {
      state.oldData = reactive(cloneDeep(data));

      each(
        omit(state.oldData, [
          'files',
          'preferedRoles',
          'genderId',
          'gender',
          'countryId',
          'country',
          'cityId',
          'city',
          'phoneCodeId',
          'phoneCode',
          'skills',
        ]),
        (value, key) => {
          set(state.newData, key, value);
        },
      );

      if (
        state.oldData.preferedRoles &&
        state.oldData.preferedRoles.length
      ) {
        set(
          state.newData,
          'preferedRoles',
          state.oldData.preferedRoles.map((role) => ({
            label: ucwords(role.name),
            value: String(role.id),
          })) || [],
        );
      }

      if (state.oldData.gender && state.oldData.genderId) {
        set(state.newData, 'gender', {
          label: ucwords(state.oldData.gender),
          value: state.oldData.genderId,
        });
      }

      if (state.oldData.country && state.oldData.countryId) {
        set(state.newData, 'country', {
          label: ucwords(state.oldData.country),
          value: state.oldData.countryId,
        });
      }

      if (
        state.oldData.cityId &&
        state.oldData.city &&
        state.oldData.countryId &&
        state.oldData.country
      ) {
        set(state.newData, 'city', {
          label: ucwords(state.oldData.city),
          value: state.oldData.cityId,
          country: {
            id: state.oldData.countryId,
            name: state.oldData.country,
          },
        });
      }

      if (state.oldData.phoneCodeId && state.oldData.phoneCode) {
        set(state.newData, 'phoneCode', {
          label: ucwords(state.oldData.phoneCode),
          value: state.oldData.phoneCodeId,
        });
      }

      if (state.oldData.skills && state.oldData.skills.length) {
        set(
          state.newData,
          'skills',
          state.oldData.skills.map((skill) => ({
            label: ucwords(skill.name),
            value: String(skill.id),
          })),
        );
      }

      if (isArray(state.oldData.files)) {
        each(state.oldData.files, (file) => {
          if (!isArray(state.newData.fileIds)) {
            state.newData.fileIds = [];
          }

          if (!state.newData.fileIds.includes(file.id)) {
            state.newData.fileIds.push(file.id);
          }
        });

        state.newData.fileIds = uniq(state.newData.fileIds);
      }
    };

    const setState = (data: Partial<State>): void => {
      each(data, (value, key) => {
        if (has(state, key)) {
          value = isObject(value)
            ? reactive(cloneDeep(value))
            : value;
          set(state, key, value);
        }
      });
    };

    const error = computed(() => {
      if (!isNameValid.value) {
        return 'Please input a valid first name and last name';
      }

      if (isInCompanyCreateMode.value) {
        if (!isEmailValid.value) {
          if (!state.asyncIsEmailValid && isEmail(email.value)) {
            return 'Email address already exist';
          }

          return 'Please input a valid candidate email address';
        }
      }

      if (!isGenderValid.value) {
        return 'Please select a valid gender';
      }

      if (!isLocationValid.value) {
        return 'Please input a valid location data by selecting or inputing country and city';
      }

      if (!isHeadlineValid.value) {
        return 'Please input a or select a valid headline';
      }

      if (!isPhoneNumberValid.value) {
        return 'Contact information is invalid. Please select a valid phone code and input a valid phone number';
      }

      return '';
    });

    const doesEmailExists = async (
      email: string,
    ): Promise<boolean> => {
      if (!isInCompanyCreateMode.value) {
        return false;
      }

      try {
        const options: FetchOptions = {
          params: {
            email,
            for: 'talent',
          },
        };

        const response = await useRequest('profile/check-email', {
          ...options,
          method: 'GET',
        });

        if (response.status === 204) {
          return false;
        }

        return true;
      } catch (e) {
        return true;
      }
    };
    const initWatchers = () => {
      /** watch email changes and update state if the email is valid */
      watch(
        () => state.newData.email,
        async (email) => {
          if (
            email &&
            isInCompanyCreateMode.value &&
            isEmail(email)
          ) {
            setState({
              asyncIsEmailValid: null,
              isCheckingIfEmailIsValid: true,
            });

            const doesExists = await doesEmailExists(email);

            setState({
              asyncIsEmailValid: !doesExists,
              isCheckingIfEmailIsValid: false,
            });
          }
        },
      );

      /** Watch fileIds and reset delete work history tracker */

      /** Watch fileIds and fetch files */
      watch(
        () => state.newData.fileIds,
        async (fileIds, prev) => {
          if (isEqual(fileIds, prev)) {
            return;
          }

          if (isArray(fileIds)) {
            if (isEmpty(fileIds)) {
              setState({
                fileRecords: [],
              });
            } else {
              const res = await useRequest(`files`, {
                params: {
                  ids: fileIds,
                },
              });

              if (res.status === 200) {
                const data = res._data as Record<string, unknown>;
                const files = data.body as FileType[];

                setState({
                  fileRecords: files,
                });
              }
            }
          }
        },
        {
          deep: true,
          immediate: true,
        },
      );

      /** Watch mode and redirect where necessary */
      // watch(
      //   () => talentProfileStore.mode,
      //   async (mode) => {
      //     if (
      //       mode === 'edit-profile' &&
      //       !router.currentRoute.value.matched.some(
      //         (route) => route.name === 'edit.profile',
      //       )
      //     ) {
      //       router.push({
      //         name: 'edit.profile',
      //       });

      //       return;
      //     }

      //     if (
      //       mode === 'create-company-talent' &&
      //       !router.currentRoute.value.matched.some(
      //         (route) => route.name === 'create.company.talent',
      //       )
      //     ) {
      //       router.push({
      //         name: 'create.company.talent',
      //       });

      //       return;
      //     }

      //     if (
      //       mode === 'create-profile' &&
      //       !router.currentRoute.value.matched.some(
      //         (route) => route.name === 'create.talent.profile',
      //       )
      //     ) {
      //       router.push({
      //         name: 'create.talent.profile',
      //       });

      //       return;
      //     }

      //     if (!talentProfileStore.hasVideo && mode === 'edit-profile') {
      //       if (!this.error) {
      //         await until(
      //           () => talentProfileStore.hasOldData,
      //           (value) => {
      //             return !!value;
      //           },
      //           {
      //             wait: 1000,
      //           },
      //         );

      //         router.push({
      //           name: 'talent.english.fluency.intro',
      //         });
      //       }
      //     }
      //   },
      //   {
      //     immediate: true,
      //   },
      // );

      /** watch server profile reload key and refetch profile */
    };
    const initialState = cloneDeep(state);
    const isModuleReady = async () => {
      const nuxt = useNuxtApp();
      const ReferralProfile = useReferralProfile(nuxt.$pinia);

      if (
        ReferralProfile.$persistedState &&
        ReferralProfile.$persistedState.isReady
      ) {
        await ReferralProfile.$persistedState.isReady();
      }

      return true;
    };

    const $reset = async () => {
      const nuxt = useNuxtApp();
      const ReferralProfile = useReferralProfile(nuxt.$pinia);
      await ReferralProfile.isModuleReady();

      ReferralProfile.$patch(
        cloneDeep(initialState) as Record<string, unknown>,
      );
    };
    return {
      isModuleReady,
      $reset,
      ...toRefs(state),
      mode,
      name,
      isInCompanyCreateMode,
      isInEditMode,
      isNameValid,
      isGenderValid,
      gender,
      headline,
      oldProfileFiles,
      files,
      oldFiles,
      isHeadlineValid,
      phoneNumber,
      isPhoneNumberValid,
      location,
      getFormattedDataFor,
      profileImageUrl,
      isOldAndNewEmailValid,
      isEmailTokensValid,
      isEmailValid,
      email,
      initWatchers,
      doesEmailExists,
      isLocationValid,
      error,
      setState,
      setOldData,
      setEmail,
      clearNewData,
      reset,
      isPreferedRolesValid,
      preferedRoles,
      setNewData,
    };
  },
  {
    persistedState: {
      persist: true,
    },
  },
);
