import * as svgIcon from '@yzfe/svgicon';
import {
  get,
  has,
  isArray,
  isBoolean,
  isObject,
  isString,
} from 'lodash-es';
import { ComputedRef, Ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { PostProfileType } from './community';

export type SocialLink = {
  name: string;
  link: string;
};

type DefaultSideNavMenuType = {
  name: string;
  icon?: svgIcon.Icon | string | Ref<svgIcon.Icon | string>;
  type: 'link' | 'button';
  isActive?: boolean | Ref<boolean>;
  isSubMenuExpanded?: boolean | Ref<boolean>;
  // eslint-disable-next-line no-use-before-define
  subMenu?: SideNavMenuType[];
  canIsolateSubMenu?: boolean | Ref<boolean>;
  toggleExpandSubMenu?: () => void | Promise<void>;
};

export type LinkSideNavMenuType = DefaultSideNavMenuType & {
  type: 'link';
  url: RouteLocationRaw;
};

export type ButtonSideNavMenuType = DefaultSideNavMenuType & {
  type: 'button';
  events: Record<string, () => unknown>;
};

export type SideNavMenuType =
  | LinkSideNavMenuType
  | ButtonSideNavMenuType;

export type SelectListItemType = {
  id: string;
  name: string;
};

export type SelectListRecordType = {
  value: string;
  label: string;
};
export type adminType = {
  email: string;
  name: string;
};

export type CompanyProfileRequestBodyType = {
  aboutCompany: string;
  city: string;
  cityId: string;
  companyBrandTags: string[];
  companyName: string;
  country: string;
  countryId: string;
  timeZoneId: string;
  industryId: string;
  companyAdminUser: adminType;
  website: string;
  id: string;
  industry: string;
  profileLogoUrl: string;
  timeZone: string;
  isAnonymous: boolean;
};

export type CompanyDepartmentRequestBodyType = {
  departmentLogo: string;
  description: string;
  fileId: string;
  fileUniqueKey: string;
  id: string;
  name: string;
  imageUrl: string;
};

export type CompanyUserProfileRequestBodyType = {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneCode: string;
  email: string;
  phoneCodeId: string;
  imageUrl: string;
  fileId: string;
  departments: SelectListItemType[];
  permissions: string[];
  isAdmin: boolean;
  isCloaked?: boolean;
};

export type RecordListDataType = {
  reEvaluate: () => void;
  list: Ref<SelectListRecordType[]>;
  itemList: ComputedRef<SelectListItemType[]>;
  values: ComputedRef<SelectListRecordType['value'][]>;
};

export type SalaryRangeType = {
  min: number;
  max: number;
};

export type CurrencyResponse = {
  id: number;
  name: string;
};

export enum NotePurpose {
  JOB_APPLICATION = 'jobApplication',
  TALENT_PROFILE = 'talentProfile',
}

export type NoteAuthorType = Omit<PostProfileType, 'role'> & {
  role?: string;
  isAuthor: boolean;
};

export type NoteDataType = {
  id: string;
  text: string;
  kind: 'note';
  user: NoteAuthorType;
  createdAt: Date | string;
  purpose?: NotePurpose;
  showImage?: boolean;
  classNames?: string[];
};

export type NoteDividerType = {
  id: string;
  kind: 'divider';
  value: string;
};

export type NoteType = NoteDataType | NoteDividerType;

export type MultichoiceQuestionOptionType = {
  uuid: string;
  type: 'wysiwyg' | 'get';
  text: string;
  isAnswer: boolean;
};

export type DefaultQuestionType = {
  uuid: string;
  text: string;
};

export type ParagraphQuestionType = DefaultQuestionType & {
  type: 'paragraph';
};

export type MultichoiceQuestionType = DefaultQuestionType & {
  type: 'multichoice';
  options: MultichoiceQuestionOptionType[];
};

export type QuestionType =
  | MultichoiceQuestionType
  | ParagraphQuestionType;

export enum ErrorCodes {
  INVALID_IP = '001',
  USER_EXIST = '002',
  TOKEN_INVALID = '003',
  TOKEN_EXPIRED = '004',
  PROCESS_FAILED = '005',
  POST_BODY_READING_FAILED = '006',
  SAVING_TO_DATABASE_FAILED = '007',
  RESOURCE_NOT_FOUND = '008',
  UNKNOWN_SERVER_ERROR = '009',
  POST_BODY_VALIDATION_FAILED = '010',
  USER_DOES_NOT_EXIST = '011',
  PASSWORD_CHANGE_FAILED = 'O12',
  INVALID_REQUEST_PARAMETERS = 'O13',
  INVALID_BIGINT_STRING_VALUE = '014',
  UNAUHTORIZED_ACCESS = '015',
  USER_PROFILE_EXIST = '016',
  UNAUTHENTICATED_ACCESS = '017',
  PAST_DATE = '018',
  CLOSED_JOB_REQUEST = '019',
  SUBDOMAIN_USER_VERIFICATION_FAILED = '020',
}

export type JobApplicationStageStatusType = {
  id: string;
  name: string;
  status: { id: string; name: string }[];
};

export enum TokenCreationReasons {
  SIGNUP = 'SIGNUP',
  SIGNIN = 'SIGNIN',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export type hasSentTokenResponse = {
  authenticated: 'pending';
  tokenSent: boolean;
  email: string;
};

export type hasLoggedIn = {
  status: 'succesful';
  body: {
    token: string;
  };
};

export type hasData<T> = {
  status: 'succesful';
  body: T;
};

export type hasDataList<T> = {
  status: 'succesful';
  body: T[];
};

export type SuccessResponse = hasSentTokenResponse | hasLoggedIn;

export type ValidationError<T extends string = string> = {
  constraints: Record<T, string>;
  property: T;
};

export type ErrorResponse = {
  status: 'failed';
  error: {
    code: ErrorCodes;
    message: string;
    data?: {
      name: string | ErrorCodes;
    };
  };
};

export type AuthenicationError = {
  authenticated: false;
  tokenSent: false;
  email: string;
};

export type UauthenticatedError = {
  code: ErrorCodes.UNAUTHENTICATED_ACCESS;
  message: string;
};

export type UauthorizedError = {
  code: ErrorCodes.UNAUHTORIZED_ACCESS;
  message: string;
};

export type ValidationErrorResponse<T extends string = string> = {
  status: 'failed';
  error: {
    code: ErrorCodes.POST_BODY_VALIDATION_FAILED;
    message: string;
    data: ValidationError<T>[];
  };
};

export type UserDataType = {
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  isVerified: boolean;
  lastName: string;
  updatedAt: string;
};

export type UserProfileCommonType = {
  id: string;
  encodedId: string;
  hasTalentProfile: boolean;
  hasCompanyProfile: boolean;
  profileCollectionId: string;
  imageUrl?: string;
  isSuperAdmin: boolean;
};

export type CandidateUserProfileType = UserProfileCommonType & {
  profileType: 'talent';
};

export type CompanyUserProfileType = UserProfileCommonType & {
  subDomain: string;
  isAdmin: boolean;
  profileType: 'companyUser';
  companyName: string;
  companyProfileId: string;
  encodedCompanyProfileId: string;
  permissions: string[];
};

export type ReferrerProfileType = UserProfileCommonType & {
  profileType: 'referrer';
};

export type VendorProfileType = UserProfileCommonType & {
  profileType: 'vendor';
};

export type CompanyAdminUserProfileType = CompanyUserProfileType & {
  isAdmin: true;
};

export type UserProfileType =
  | CandidateUserProfileType
  | CompanyUserProfileType
  | ReferrerProfileType
  | VendorProfileType;

export function isUserCandidate(
  data: Record<string, unknown>,
): data is CandidateUserProfileType {
  return (
    isObject(data) &&
    isString(get(data, 'id')) &&
    get(data, 'profileType') === 'talent'
  );
}

export function isUserCompany(
  data: Record<string, unknown>,
): data is CompanyUserProfileType {
  return (
    isObject(data) &&
    isString(get(data, 'id')) &&
    isString(get(data, 'subDomain')) &&
    isBoolean(get(data, 'isAdmin')) &&
    get(data, 'profileType') === 'companyUser'
  );
}

export function isReferral(
  data: Record<string, unknown>,
): data is ReferrerProfileType {
  return get(data, 'profileType') === 'referrer';
}

export function isVendor(
  data: Record<string, unknown>,
): data is VendorProfileType {
  return get(data, 'profileType') === 'vendor';
}

export function isUserCompanyAdmin(
  data: Record<string, unknown>,
): data is CompanyAdminUserProfileType {
  return isUserCompany(data) && data.isAdmin === true;
}

export function isValidUserProfile(
  data: Record<string, unknown>,
): data is UserProfileType {
  return isUserCandidate(data) || isUserCompany(data);
}

export function isValidUserData(
  data: Record<string, unknown>,
): data is UserDataType {
  return (
    isObject(data) &&
    isString(get(data, 'createdAt')) &&
    isString(get(data, 'email')) &&
    isString(get(data, 'firstName')) &&
    isString(get(data, 'lastName')) &&
    isBoolean(get(data, 'isVerified')) &&
    isString(get(data, 'updatedAt'))
  );
}

export function hasSentTokenResponse(
  data: Record<string, unknown>,
): data is hasSentTokenResponse {
  return (
    isObject(data) &&
    get(data, 'authenticated') === false &&
    get(data, 'tokenSent') === true
  );
}

export function hasDataResponse<T>(
  data: Record<string, unknown>,
): data is hasData<T> {
  return isObject(data) && has(data, 'status') && has(data, 'body');
}

export function hasDataResponseList<T>(
  data: Record<string, unknown>,
): data is hasDataList<T> {
  return (
    isObject(data) &&
    get(data, 'status') === 'successful' &&
    isArray(get(data, 'body'))
  );
}

export function hasLoggedInResponse(
  data: Record<string, unknown>,
): data is hasLoggedIn {
  return (
    isObject(data) &&
    get(data, 'status') === 'successful' &&
    has(data, 'body')
  );
}

export function isErrorResponse(
  data: Record<string, unknown>,
): data is ErrorResponse {
  return (
    isObject(data) &&
    get(data, 'status') === 'failed' &&
    has(data, 'error.code')
  );
}

export function isUauthenticatedErrorResponse(
  data: Record<string, unknown>,
): data is UauthenticatedError {
  return (
    isObject(data) &&
    get(data, 'code') === ErrorCodes.UNAUTHENTICATED_ACCESS &&
    isString(get(data, 'message'))
  );
}

export function isUnauthorizedErrorResponse(
  data: Record<string, unknown>,
): data is UauthorizedError {
  return (
    isObject(data) &&
    get(data, 'code') === ErrorCodes.UNAUHTORIZED_ACCESS
  );
}

export function isValidationErrorResponse(
  data: Record<string, unknown>,
): data is ValidationErrorResponse {
  return (
    isObject(data) &&
    isErrorResponse(data) &&
    data.error.code === ErrorCodes.POST_BODY_VALIDATION_FAILED
  );
}

export function isAuthenticationError(
  data: Record<string, unknown>,
): data is AuthenicationError {
  return (
    isObject(data) &&
    get(data, 'authenticated') === false &&
    get(data, 'tokenSent') === false
  );
}

export const objectSkillsFilter = (
  skill: SelectListItemType,
): boolean =>
  !!skill &&
  (typeof skill === 'string' || !!(skill.id && skill.name));

export const stringSkillsFilter = (skill: string) => {
  const skillData = skill as any;
  return (
    !!skillData &&
    (typeof skillData === 'string' ||
      (typeof skillData === 'object' &&
        skillData.id &&
        skillData.name))
  );
};
