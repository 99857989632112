import { defineStore } from 'pinia';
import {
  get,
  set,
  has,
  each,
  cloneDeep,
  forEach,
  uniqueId,
  isNull,
} from 'lodash-es';
import { SelectListRecordType } from '~~/types/logged';
import {
  AssessmentQuestion,
  AssessmentQuestionDifficultyLevels,
  AssessmentQuestionTypes,
} from '@/types/assessment';

interface State {
  title: string;
  activeDays: number | null;
  job: SelectListRecordType | null;
  jobApplicationStage: SelectListRecordType | null;
  numberOfQuestions: number | null;
  assesmentDurationInMin: number | null;
  description: string | null;
  skills: SelectListRecordType[];
  talents: SelectListRecordType[];
  ideQuestions: {
    id: string;
    skills: SelectListRecordType[];
    difficulty: AssessmentQuestionDifficultyLevels;
    isLoadingQuestion: boolean;
    question?: AssessmentQuestion | null;
  }[];
  mode: 'create' | 'system';
}

export const useCompanyAssessment = defineStore(
  'company_assessment',
  () => {
    const state = reactive<State>({
      title: '',
      activeDays: null,
      job: null,
      jobApplicationStage: null,
      numberOfQuestions: null,
      assesmentDurationInMin: null,
      description: null,
      skills: [],
      talents: [],
      ideQuestions: [],
      mode: 'system',
    });

    const initialState = cloneDeep(state);
    const setState = (data: Partial<State>): void => {
      each(data, (value, key) => {
        if (has(state, key)) {
          set(state, key, value);
        }
      });
    };

    const fetchIdeQuestionFor = async (
      id: string,
      refresh = false,
    ): Promise<void> => {
      const ideAssessmentQuestion = state.ideQuestions.find(
        (ideQuestion) => ideQuestion.id === id,
      );

      if (
        !(
          ideAssessmentQuestion &&
          ideAssessmentQuestion.skills.length > 0 &&
          ideAssessmentQuestion.difficulty
        )
      ) {
        return;
      }

      ideAssessmentQuestion.isLoadingQuestion = true;

      try {
        const resp = await useRequest('assessments/questions', {
          query: {
            questionType: AssessmentQuestionTypes.HANDS_ON_CODING,
            questionCount: 1,
            skills: ideAssessmentQuestion.skills.map(
              (langSkill) => langSkill.value,
            ),
            difficultyLevel: ideAssessmentQuestion.difficulty,
            currentQuestionsIds: state.ideQuestions
              .filter(
                (ideQuestion) =>
                  ideQuestion.question && ideQuestion.question.id,
              )
              .map((ideQuestion) => ideQuestion.question?.id || 0),
          },
        });

        const data = resp._data as {
          body: AssessmentQuestion[];
        };

        const questions = get(data, 'body', undefined) as
          | AssessmentQuestion[]
          | undefined;

        if (questions && questions.length > 0) {
          const question = questions[0];
          if (
            !state.ideQuestions.find(
              (ideQuestion) =>
                ideQuestion &&
                ideQuestion.question &&
                ideQuestion.question.id === question.id,
            )
          ) {
            ideAssessmentQuestion.question = question;
          }
        } else if (!refresh) {
          ideAssessmentQuestion.question = null;
        }
      } catch (e) {
        console.dir(e);
      }

      ideAssessmentQuestion.isLoadingQuestion = false;
    };

    const canAddIdeQuestion = computed(
      () =>
        state.mode === 'create' &&
        state.ideQuestions.length < (state.numberOfQuestions || 0),
    );

    const addNewIdeQuestion = (question: AssessmentQuestion) => {
      if (canAddIdeQuestion.value) {
        if (
          !state.ideQuestions.find(
            (ideQuestion) =>
              ideQuestion.question &&
              ideQuestion.question.id === question.id,
          )
        ) {
          state.ideQuestions.push({
            id: uniqueId('ide_question_'),
            skills: [],
            difficulty: AssessmentQuestionDifficultyLevels.DIFFICULT,
            isLoadingQuestion: false,
            question,
          });
        }
      }
    };

    const setIdeQuestions = (questions: AssessmentQuestion[]) => {
      const ideQuestions: {
        id: string;
        skills: SelectListRecordType[];
        difficulty: AssessmentQuestionDifficultyLevels;
        isLoadingQuestion: boolean;
        question: AssessmentQuestion;
      }[] = [];
      questions.forEach((question) => {
        ideQuestions.push({
          id: uniqueId('ide_question_'),
          skills: [],
          difficulty:
            question.difficultyLevel ||
            AssessmentQuestionDifficultyLevels.DIFFICULT,
          isLoadingQuestion: false,
          question,
        });
      });

      state.ideQuestions = ideQuestions;
    };

    const deleteIdeQuestionFor = (id: string) => {
      if (state.mode === 'create') {
        const index = state.ideQuestions.findIndex(
          (ideQuestion) => ideQuestion.id === id,
        );

        if (index > -1) {
          state.ideQuestions.splice(index, 1);
        }
      }
    };

    const resetIdeQuestionFor = (id: string) => {
      const ideAssessmentQuestion = state.ideQuestions.find(
        (ideQuestion) => ideQuestion.id === id,
      );

      if (ideAssessmentQuestion) {
        ideAssessmentQuestion.skills = [];
        ideAssessmentQuestion.difficulty =
          AssessmentQuestionDifficultyLevels.DIFFICULT;
        ideAssessmentQuestion.isLoadingQuestion = false;
        ideAssessmentQuestion.question = undefined;
      }
    };

    const isModuleReady = async () => {
      const companyAssessment = useCompanyAssessment();

      if (
        companyAssessment.$persistedState &&
        companyAssessment.$persistedState.isReady
      ) {
        await companyAssessment.$persistedState.isReady();
      }

      return true;
    };

    const $reset = () => {
      const newState = cloneDeep(initialState) as Record<
        string,
        unknown
      >;

      forEach(newState, (value, key) => {
        set(state, key, value);
      });
    };

    watchEffect(() => {
      const ideQuestionsWithoutQuestions = state.ideQuestions.filter(
        (ideQuestion) =>
          !ideQuestion.isLoadingQuestion &&
          ideQuestion.difficulty &&
          ideQuestion.skills.length > 0 &&
          ideQuestion.question === undefined,
      );

      if (
        ideQuestionsWithoutQuestions.length &&
        state.mode === 'system'
      ) {
        ideQuestionsWithoutQuestions.forEach((ideQuestion) => {
          fetchIdeQuestionFor(ideQuestion.id);
        });
      }
    });

    watch(
      () => state.numberOfQuestions,
      async () => {
        await isModuleReady();

        const currNo: number = isNull(state.numberOfQuestions)
          ? 0
          : state.numberOfQuestions;
        const prevNo = state.ideQuestions.length;

        if (currNo) {
          state.ideQuestions = state.ideQuestions.slice(0, currNo);
        }

        if (currNo > prevNo) {
          let newIdeQuestionsNo = currNo - prevNo;

          if (
            newIdeQuestionsNo &&
            newIdeQuestionsNo > 0 &&
            state.mode === 'system'
          ) {
            while (newIdeQuestionsNo > 0) {
              state.ideQuestions.push({
                id: uniqueId('ide_question_'),
                skills: [],
                difficulty:
                  AssessmentQuestionDifficultyLevels.DIFFICULT,
                isLoadingQuestion: false,
                question: undefined,
              });

              newIdeQuestionsNo--;
            }
          }
        }
      },
      {
        immediate: true,
      },
    );

    return {
      ...toRefs(state),
      isModuleReady,
      $reset,
      setState,
      fetchIdeQuestionFor,
      resetIdeQuestionFor,
      deleteIdeQuestionFor,
      addNewIdeQuestion,
      setIdeQuestions,
    };
  },
  {
    persistedState: {
      persist: true,
      excludePaths: [],
    },
  },
);
