export const appBaseURL = "/"

export const appBuildAssetsDir = "assets/legacy"

export const appCdnURL = "https://legacy.goodtalent.dev/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","content":"Crowdsource, screen, hire and run compliant payroll for diverse and remote software engineers globally. Vet the coding skills of your software engineers to reduce your cost-per-hire and time-to-fill."}],"link":[{"rel":"stylesheet","href":"/css/campton.css"},{"rel":"stylesheet","href":"/css/reset.css"},{"rel":"shortcut icon","href":"/favicon.png"},{"rel":"apple-touch-icon","href":"/favicon.png"}],"style":[],"script":[{"src":"https://www.WebRTC-Experiment.com/RecordRTC.js"},{"src":"https://webrtc.github.io/adapter/adapter-latest.js"},{"src":"https://www.webrtc-experiment.com/screenshot.js"}],"noscript":[],"title":"GoodTalent | Marketplace for Software Engineering Talents","htmlAttrs":{"lang":"en"}}

export const appPageTransition = false

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":false}

export const fetchDefaults = {"retry":2,"retryDelay":100,"retryStatusCodes":[500],"timeout":30000}

export const vueAppRootContainer = '#__nuxt'