/* eslint-disable import/named */
import { useJwt } from '@vueuse/integrations/useJwt';
import { defineStore } from 'pinia';
import {
  get,
  set,
  has,
  each,
  values,
  keys,
  includes,
  omit,
  isObject,
  isArray,
  isString,
  isBoolean,
  isEmpty,
  isEqual,
  cloneDeep,
  forEach,
  upperFirst,
} from 'lodash-es';
import { RouteLocation, RouteLocationRaw } from 'vue-router';
import { useScriptTag } from '@vueuse/core';
import { fromUrl, parseDomain, ParseResultType } from 'parse-domain';
import {
  number,
  round as mathJsRound,
  string as mathJsString,
} from 'mathjs';
import {
  isValid as isDateValid,
  isFuture as isDateInFuture,
} from 'date-fns';
import isNumeric from 'fast-isnumeric';
import { FetchError } from 'ofetch';
import slugify from 'slugify';
import { getCookie } from '@/utils/helpers';
import {
  isUserCandidate,
  isUserCompany,
  isReferral,
  TokenCreationReasons,
  UserProfileType,
  isVendor,
  UserDataType,
  isValidUserData,
  CompanyUserProfileType,
  isValidationErrorResponse,
} from '@/types/logged';
import {
  UserWalletDataType,
  WalletBalanceTypes,
} from '@/types/finance';
import { CommunityProfile } from '@/types/community';
import {
  SubscriptionPlan,
  SubscriptionPlanName,
} from '@/types/subscription';
import { PermissionVerificationChecks } from '@/utils/permission';
import { FileType } from '@/types/attachment';
import {
  CompanyProfilePermissionPageNameType,
  CompanyProfilePermissionType,
} from '@/types/permission';
import { FilePurpose } from '@/support/uploader';

interface State {
  hasTokenExpired: boolean;
  tokenExpiryInterval: number;
  communityMessageState: 'maximized' | 'minimized';
  mode: 'token-sent' | 'logged' | 'unlogged';
  email: string | undefined | '';
  token: string;
  isReferal: boolean;
  isVendorProfile: boolean;
  tokenCreationReason?: TokenCreationReasons | null;
  showSideNav: boolean;
  isLoadingCommunityProfile: boolean;
  wallet: UserWalletDataType | null;
  isLoadingWalletData: boolean;
  communityProfile: CommunityProfile | null;
  breakpoints: Record<string, number>;
  continue?: Record<string, unknown> | null;
  subscriptionProcessingList: string[];
  totalBounties: string | null | undefined;
  isLoadingTotalBounties: boolean;
}

let currentDateTimer: ReturnType<typeof setInterval> | undefined;
const stripeScriptRef = ref<
  ReturnType<typeof useScriptTag> | undefined
>();

export const useUser = defineStore(
  'user',
  () => {
    const cookie = getCookie();
    const state = reactive<State>({
      hasTokenExpired: true,
      tokenExpiryInterval: 1000,
      communityMessageState: 'minimized',
      mode: 'unlogged',
      email: '',
      token: '',
      isReferal: false,
      isVendorProfile: false,
      tokenCreationReason: null,
      showSideNav: false,
      isLoadingCommunityProfile: false,
      wallet: null,
      isLoadingWalletData: false,
      communityProfile: null,
      breakpoints: {
        xxs: 0,
        xs: 350,
        xi: 380,
        sl: 477,
        sm: 576,
        md: 768,
        msl: 868,
        lg: 992,
        xx: 1000,
        xl: 1200,
        xxl: 1400,
      },
      continue: null,
      subscriptionProcessingList: [],
      totalBounties: null,
      isLoadingTotalBounties: false,
    });

    const initialState = cloneDeep(state);

    const _currentDate = ref(new Date());
    const isLoggingOut = ref(false);

    const parseToken = computed<Record<string, unknown> | null>(
      () => {
        const parsedToken = useJwt(state.token, {
          fallbackValue: null,
        });

        return get(parsedToken, 'payload.value', null) as Record<
          string,
          unknown
        > | null;
      },
    );

    const profileData = computed<UserProfileType | null>(() => {
      const parsedToken = parseToken.value;
      if (parsedToken) {
        const profile = parsedToken.profile as Record<
          string,
          unknown
        >;

        if (isUserCompany(profile)) return profile;
        else if (isUserCandidate(profile)) return profile;
        else if (isReferral(profile)) return profile;
        else if (isVendor(profile)) return profile;
      }

      return null;
    });

    const isLogged = computed<boolean>(() => {
      return !!parseToken.value && !hasTokenExpired.value;
    });

    const hasCompanyProfile = computed<boolean>(() => {
      const pData = profileData.value;
      if (pData) {
        return pData.hasCompanyProfile;
      }

      return false;
    });

    const profileSubdomain = computed<string | undefined>(() => {
      const pData = profileData.value || {};
      if (profileData && isUserCompany(pData)) {
        return pData.subDomain.toLowerCase();
      }

      return undefined;
    });

    const profileType = computed<
      'company' | 'talent' | 'referrer' | ''
    >(() => {
      const pData = profileData.value;
      if (pData) {
        if (isUserCompany(pData)) return 'company';
        else if (isUserCandidate(pData)) return 'talent';
        else if (isReferral(pData)) return 'referrer';
      }

      return '';
    });

    const profileId = computed<string>(() => {
      if (hasProfile.value) {
        const pData = profileData.value;

        if (pData && isUserCompany(pData)) {
          return get(pData, 'encodedCompanyProfileId', '');
        }

        return get(pData, 'encodedId', '');
      }

      return '';
    });

    const hasTalentProfile = computed<boolean>(() => {
      const pData = profileData.value;
      if (pData) {
        return pData.hasTalentProfile;
      }

      return false;
    });

    const hasProfile = computed<boolean>(() => {
      const pData = profileData.value;
      if (isLogged.value && pData) {
        return true;
      }

      return false;
    });

    const userType = computed<
      'candidate' | 'company-user' | 'referrer' | 'vendor' | null
    >(() => {
      const pData = profileData.value;
      if (pData) {
        if (isUserCompany(pData)) return 'company-user';
        else if (isUserCandidate(pData)) return 'candidate';
        else if (isReferral(pData)) return 'referrer';
        else if (isVendor(pData)) return 'vendor';
      }

      return null;
    });

    const userData = computed<UserDataType | null>(() => {
      const pToken = parseToken.value;
      if (pToken) {
        const userData = pToken.userData as Record<string, unknown>;

        if (isValidUserData(userData)) {
          return userData;
        }
      }

      return null;
    });

    const walletId = computed<UserWalletDataType['id'] | null>(() => {
      return get(state.wallet, 'id', null) || null;
    });

    const canChangeWallet = computed<
      (
        id: string | number,
        type: 'talent' | 'company' | 'referrer',
      ) => boolean
    >(() => {
      return (id, type): boolean => {
        if (!isSuperAdmin.value) {
          return false;
        }

        if (profileType.value === type) {
          id = String(id);
          let currentId = profileData.value?.id || '';

          if (profileData.value?.profileType === 'companyUser') {
            currentId = profileData.value.companyProfileId;
          }

          if (id === String(currentId)) {
            return false;
          }
        }

        return true;
      };
    });

    const walletBalance = computed<
      (
        name: WalletBalanceTypes,
        raw?: boolean,
        asString?: boolean,
      ) => number | string
    >(() => {
      return (name, raw = false, asString = false) => {
        const amount = number(
          get(state.wallet, `balances.${name}`, '0'),
        );

        let amountStr = String(amount);

        if (!raw) {
          amountStr = mathJsString(mathJsRound(amount, 2));
        }

        if (asString) {
          return amountStr;
        }

        return parseFloat(amountStr) || 0;
      };
    });

    const isSuperAdmin = computed<boolean>(() => {
      return (
        userType.value === 'company-user' &&
        !!get(profileData.value, 'isSuperAdmin', false)
      );
    });

    const hasPermissionTo = computed(() => {
      return (checks: PermissionVerificationChecks): boolean => {
        const pData = profileData.value as
          | CompanyUserProfileType
          | undefined;

        if (
          userType.value !== 'company-user' ||
          !hasProfile.value ||
          !isObject(pData)
        ) {
          return false;
        }

        if (pData.isAdmin) {
          return true;
        }

        if (pData.permissions.length > 0) {
          if (isArray(checks.canCreate)) {
            // Check if the user permissions does not contain a permission for create
            if (
              checks.canCreate.find(
                (str) =>
                  !pData.permissions.includes(
                    'create-' + String(str).toLowerCase(),
                  ),
              )
            ) {
              return false;
            }
          }

          if (isArray(checks.canView)) {
            // Check if the user permissions does not contain a permission for view
            if (
              checks.canView.find(
                (str) =>
                  !pData.permissions.includes(
                    'view-' + String(str).toLowerCase(),
                  ),
              )
            ) {
              return false;
            }
          }

          if (isArray(checks.canEdit)) {
            // Check if the user permissions does not contain a permission for edit
            if (
              checks.canEdit.find(
                (str) =>
                  !pData.permissions.includes(
                    'edit-' + String(str).toLowerCase(),
                  ),
              )
            ) {
              return false;
            }
          }

          if (isArray(checks.canDelete)) {
            // Check if the user permissions does not contain a permission for delete
            if (
              checks.canDelete.find(
                (str) =>
                  !pData.permissions.includes(
                    'delete-' + String(str).toLowerCase(),
                  ),
              )
            ) {
              return false;
            }
          }

          if (isArray(checks.canSee)) {
            // Check if the user permissions does not contain a permission for delete
            if (
              checks.canSee.find(
                (str) =>
                  !pData.permissions.includes(
                    'see-' + String(str).toLowerCase(),
                  ),
              )
            ) {
              return false;
            }
          }

          return true;
        }

        return false;
      };
    });

    const communityProfileFiles = computed<FileType[]>(() => {
      return state.communityProfile
        ? state.communityProfile.files
        : [];
    });

    const hasLoadedCommunityProfile = computed<boolean>(() => {
      return !!state.communityProfile;
    });

    const profileImageUrl = computed<string>(() => {
      const files = communityProfileFiles.value;
      if (state.communityProfile && files.length > 0) {
        if (state.communityProfile.isCompany) {
          const file = files.find(
            (file) =>
              file.purpose === FilePurpose.COMPANY_PROFILE_LOGO,
          );
          return file ? file.url : '';
        } else {
          const file = files.find(
            (file) =>
              file.purpose === FilePurpose.TALENT_PROFILE_PICTURE,
          );
          return file ? file.url : '';
        }
      }

      return '';
    });

    const defaultProfileBackgroundImage = computedAsync(async () => {
      const imgUrl = await import(
        '@/assets/images/pngs/d-profile-bg-image.png?url'
      );
      return (get(imgUrl, 'default', imgUrl) as string) || '';
    });

    const profileBackgroundImageUrl = computed<string>(() => {
      const files = communityProfileFiles.value;
      let imageUrl = '';

      if (state.communityProfile && files.length > 0) {
        if (state.communityProfile.isCompany) {
          const file = files.find(
            (file) =>
              file.purpose ===
              FilePurpose.COMPANY_PROFILE_BACKGROUND_IMAGE,
          );
          imageUrl = file ? file.url : '';
        } else {
          const file = files.find(
            (file) =>
              file.purpose ===
              FilePurpose.TALENT_PROFILE_BACKGROUND_IMAGE,
          );
          imageUrl = file ? file.url : '';
        }
      }

      if (imageUrl) return imageUrl;
      return defaultProfileBackgroundImage.value;
    });

    const profileName = computed<string>(() => {
      if (state.communityProfile) {
        return upperFirst(state.communityProfile.name);
      }

      return '';
    });

    const profileRole = computed<string>(() => {
      if (state.communityProfile) {
        if (
          state.communityProfile.isCompany &&
          state.communityProfile.industry
        ) {
          return upperFirst(state.communityProfile.industry);
        }

        if (
          !state.communityProfile.isCompany &&
          state.communityProfile.role
        ) {
          return upperFirst(state.communityProfile.role);
        }
      }

      return '';
    });

    const generalUrlFor = computed(() => {
      return (route: RouteLocationRaw): string => {
        const config = useRuntimeConfig();
        const router = useRouter();
        const fullPath = router.resolve(route).fullPath;
        if (subdomain.value) {
          return `https://${config.public.uiDomain}.${config.public.uiTopleveldomain}${fullPath}`;
        }
        return fullPath;
      };
    });

    const getFirstPermittedPageForMenu = computed(() => {
      return (menuName: string): string | undefined => {
        if (userType.value === 'company-user') {
          menuName = menuName.toLowerCase();
          let permissionKey: string | number | undefined;

          const validKeyListIndices: number[] = [];
          const permissionList = values(
            CompanyProfilePermissionType,
          ).filter((permission, key) => {
            const isValid = permission.startsWith(`${menuName}-`);

            if (isValid) {
              validKeyListIndices.push(key);
            }

            return isValid;
          });

          const permissionKeyList = keys(
            CompanyProfilePermissionType,
          ).filter((_, key) => validKeyListIndices.includes(key));

          const permittedPage = permissionList.find(
            (permission, key) => {
              if (
                (permission ===
                  CompanyProfilePermissionType.DASHBOARD_GENERAL &&
                  hasPermissionTo.value({
                    canSee: [permission],
                  })) ||
                hasPermissionTo.value({
                  canView: [permission],
                })
              ) {
                permissionKey = get(permissionKeyList, key);
                return true;
              }

              return false;
            },
          );

          if (permittedPage && permissionKey !== undefined) {
            return String(
              get(
                CompanyProfilePermissionPageNameType,
                permissionKey,
              ) || '',
            );
          }
        }

        return undefined;
      };
    });

    const getFirstPermittedPage = computed<string | undefined>(() => {
      if (userType.value === 'company-user') {
        let permissionKey: string | number | undefined;
        const permissionList = values(CompanyProfilePermissionType);
        const permissionKeyList = keys(CompanyProfilePermissionType);

        const permittedPage = permissionList.find(
          (permission, key) => {
            if (
              (permission ===
                CompanyProfilePermissionType.DASHBOARD_GENERAL &&
                hasPermissionTo.value({
                  canSee: [permission],
                })) ||
              hasPermissionTo.value({
                canView: [permission],
              })
            ) {
              permissionKey = get(permissionKeyList, key);
              return true;
            }

            return false;
          },
        );

        if (permittedPage && permissionKey !== undefined) {
          return String(
            get(
              CompanyProfilePermissionPageNameType,
              permissionKey,
            ) || '',
          );
        } else {
          return 'view.profile';
        }
      } else if (userType.value === 'candidate') {
        return 'dashboard';
      }

      return undefined;
    });

    const isSubscribedTo = computed<
      (plan: SubscriptionPlanName) => boolean
    >(() => {
      return (plan) => {
        return subscriptions.value.some(
          (subscription) => subscription.name === plan,
        );
      };
    });

    const subdomain = computed<string | undefined>(() => {
      const config = useRuntimeConfig();
      const location = useBrowserLocation();
      const domainUrl = fromUrl(location.value.href || '') as string;

      if (domainUrl) {
        if (
          [
            `www.${config.public.uiUrl}`,
            config.public.uiUrl,
          ].includes(domainUrl)
        ) {
          return undefined;
        }

        const parseResult = parseDomain(domainUrl);
        if (parseResult.type === ParseResultType.Listed) {
          const subdomain = String(
            parseResult.subDomains[0] || '',
          ).toLowerCase();

          if (
            subdomain === 'www' &&
            parseResult.subDomains.length > 1
          ) {
            return String(
              parseResult.subDomains[1] || '',
            ).toLowerCase();
          }

          return subdomain;
        }
      }

      return undefined;
    });

    const isInValidDomain = computed<boolean>(() => {
      const typeOfUser = userType.value;

      if (isProduction.value) {
        if (typeOfUser === 'candidate') {
          return !subdomain.value;
        } else if (typeOfUser === 'company-user') {
          // if (
          //   !!subdomain.value &&
          //   !!profileSubdomain.value &&
          //   subdomain.value === profileSubdomain.value
          // ) {
          //   return true;
          // } else {
          //   return false;
          // }

          return true;
        }
      }

      return true;
    });

    const currentDate = computed(() => {
      return _currentDate.value;
    });

    const currentDateMilli = computed(() => {
      return currentDate.value.getTime();
    });

    const tokenExpiryDate = computed(() => {
      const parsedToken = parseToken.value;
      if (parsedToken) {
        const expireDate =
          (parseInt((parsedToken.exp as string) || '0', 10) || 0) *
          1000;

        return new Date(expireDate);
      }

      return undefined;
    });

    const hasTokenExpired = ref(false);
    const hasTokenExpiredLocal = computed(() => {
      const tokenExpiryDateValue = tokenExpiryDate.value;

      if (tokenExpiryDateValue) {
        return (
          currentDateMilli.value > tokenExpiryDateValue.getTime()
        );
      }

      return false;
    });

    syncRef(hasTokenExpiredLocal, hasTokenExpired, {
      direction: 'ltr',
    });

    const setState = (data: Partial<State>): void => {
      each(data, (value, key) => {
        if (has(state, key)) {
          set(state, key, value);
        }
      });
    };

    const checkTokenExpiry = (
      parseToken: Record<string, unknown>,
    ): boolean => {
      if (parseToken) {
        const expireDate =
          (parseInt((parseToken.exp as string) || '0', 10) || 0) *
          1000;

        return Date.now() >= expireDate;
      }

      return true;
    };

    const removeAuthCookies = () => {
      cookie.remove('token', {
        path: '/',
      });
      cookie.remove('token', {
        domain: undefined,
        path: '/',
      });
      cookie.remove('central-goodtalent-action', {
        path: '/',
      });
      cookie.remove('central-goodtalent-action', {
        domain: undefined,
        path: '/',
      });
    };

    const removePartnerCookies = () => {
      cookie.remove('partner-subdomain', {
        path: '/',
      });
      cookie.remove('partner-subdomain', {
        domain: undefined,
        path: '/',
      });
    };

    const setPartnerCookie = (subdomain: string) => {
      const config = useRuntimeConfig();
      const domain = config.public.uiUrl;

      cookie.set('partner-subdomain', subdomain, {
        domain,
        expires: 365 * 5, // 5 years
      });
    };

    const getPartnerSubdomain = () => {
      return cookie.get('partner-subdomain') || '';
    };

    const removeAllCookies = () => {
      removeAuthCookies();
      removePartnerCookies();
    };

    const logout = async (addCentralLogoutAction = true) => {
      //Clear any previous central logout state
      if (addCentralLogoutAction && !subdomain.value) {
        addCentralLogoutAction = false;
        cookie.remove('central-gtcosearch-action');
        cookie.remove('central-gtcosearch-action', {
          domain: undefined,
        });
      }

      if (state.token) {
        // Clear continue when logging out
        state.continue = null;
      }

      if (isLoggingOut.value) {
        return;
      }

      isLoggingOut.value = true;
      await new Promise((resolve) => {
        setTimeout(resolve, 2000);
      });

      await nextTick();

      cookie.remove('token');
      cookie.remove('token', {
        domain: undefined,
      });

      if (addCentralLogoutAction) {
        cookie.set('central-gtcosearch-action', 'logout');
        cookie.set('central-gtcosearch-action', 'logout', {
          domain: undefined,
        });
      }

      state.mode = 'unlogged';
      state.email = '';
      state.token = '';
      state.tokenCreationReason = null;

      $reset();
      await nextTick();

      try {
        await goToGeneralUrlFor({ name: 'login' });
        await new Promise((resolve) => {
          setTimeout(resolve, 2000);
        });
        isLoggingOut.value = false;
      } catch (e) {
        if (isDevelopment.value || inStaging.value) {
          console.log('Error while logging out ===> ');
          console.dir(e);
        }

        console.log('error in default frame logout ====> ', e);
        window.location.href = '/login';
      }
    };

    const setToken = async (token: string) => {
      // Clear continue when switching profile
      // if (
      //   !!state.token &&
      //   !!token &&
      //   token !== state.token &&
      //   hasProfile.value
      // ) {
      //   state.continue = null;
      // }

      if (!token) {
        await logout();
        await goToGeneralUrlFor({ name: 'login' });
        isLoggingOut.value = false;
        return;
      }

      setState({
        mode: 'logged',
        email: '',
        token,
        tokenCreationReason: null,
      });

      await nextTick();

      cookie.set('token', token, {
        expires: tokenExpiryDate.value,
      });
    };

    const currentReferralStatus = (status: boolean): void => {
      if (isBoolean(status)) {
        state.isReferal = status;
      }
    };

    const syncToken = async () => {
      const token = cookie.get('token') || '';

      if (token) {
        await setToken(token);
      } else if (isLogged.value) {
        // Force logout
        const storage = getStoreInstance(
          'state',
          'pinia',
          'This is the local forage state representation of pinia in memory state',
        );

        await storage.clear();
        await $reset();

        cookie.remove('central-goodtalent-action');
        cookie.remove('central-goodtalent-action', {
          domain: undefined,
        });
      }
    };

    const goToGeneralUrlFor = async (
      route: RouteLocationRaw,
    ): Promise<void> => {
      const currentRoute = useRoute();
      const router = useRouter();
      if (
        !subdomain.value &&
        currentRoute.fullPath !== router.resolve(route).fullPath
      ) {
        const errors = await navigateTo(route);

        if (errors) {
          if (isDevelopment.value || inStaging.value) {
            console.log('route navigation errors is ===> ', errors);
          }
        } else {
          return;
        }
      }

      window.location.href = generalUrlFor.value(route);
    };

    const rewriteRouteToValidDomain = async (
      route: RouteLocation,
    ): Promise<void> => {
      const config = useRuntimeConfig();

      console.log(
        'is in invalid domain is ====> ',
        isInValidDomain.value,
        isProduction.value,
      );

      if (!isInValidDomain.value) {
        const typeOfUser = userType.value;
        const fullPath = String(route.fullPath);
        let url = '';

        if (fullPath) {
          if (
            [
              'job-jobTitle--jobUniqueId',
              'job-jobTitle--jobUniqueId-index',
              'jobs',
            ].includes(String(route.name))
          ) {
            switch (typeOfUser) {
              case 'company-user':
                url = `https://${profileSubdomain.value}.${config.public.uiUrl}${fullPath}`;
                break;
              default:
                url = `https://${config.public.uiUrl}${fullPath}`;
                break;
            }
          }
        }

        if (url) {
          window.location.href = url;
          return;
        }
      }

      if (!state.continue) return await goToDashboard();
    };

    const goToDashboard = async (): Promise<void> => {
      const config = useRuntimeConfig();

      if (isLogged.value) {
        if (isProduction.value && !isInValidDomain.value) {
          const typeOfUser = userType.value;

          if (typeOfUser === 'candidate') {
            window.location.href = `https://${config.public.uiUrl}/dashboard`;
          } else if (
            typeOfUser === 'company-user' &&
            profileSubdomain.value
          ) {
            window.location.href = `https://${profileSubdomain.value}.${config.public.uiUrl}/dashboard`;
          } else if (typeOfUser === 'referrer') {
            window.location.href = `https://${config.public.uiUrl}/referral/dashboard`;
          } else if (typeOfUser === 'vendor') {
            window.location.href = `https://${config.public.uiUrl}/marketplace/dashboard`;
          } else {
            window.location.href = `https://${config.public.uiUrl}`;
          }

          return;
        }

        await navigateTo('/dashboard');
        return;
      }

      return await goToHome();
    };

    const goToHome = async (): Promise<void> => {
      const config = useRuntimeConfig();

      if (isProduction.value && !isInValidDomain.value) {
        const uType = userType.value;

        if (uType === 'candidate') {
          window.location.href = `https://${config.public.uiUrl}`;
        } else if (
          uType === 'company-user' &&
          profileSubdomain.value
        ) {
          await goToDashboard();
        } else if (subdomain.value) {
          window.location.href = `https://${subdomain.value}.${config.public.uiUrl}/login`;
        } else {
          window.location.href = `https://${config.public.uiUrl}`;
        }

        return;
      }

      await navigateTo('/');
    };

    const setSubscribingTo = (data: {
      isSubscribing: boolean;
      plan: SubscriptionPlanName;
    }) => {
      if (includes(state.subscriptionProcessingList, data.plan)) {
        if (!data.isSubscribing) {
          state.subscriptionProcessingList = omit(
            state.subscriptionProcessingList,
            [data.plan],
          ) as string[];

          if (!isArray(state.subscriptionProcessingList)) {
            state.subscriptionProcessingList = [];
          }
        }
      } else if (data.isSubscribing) {
        state.subscriptionProcessingList.push(data.plan);
      }
    };

    const setVendorActiveProfile = () => {
      state.isVendorProfile = true;
    };

    const clearActiveVendorProfile = () => {
      state.isVendorProfile = false;
    };

    const clearContinueAfterAuth = () => {
      state.continue = null;
    };

    const setReferralStatus = (status: boolean): void => {
      if (isBoolean(status)) {
        state.isReferal = status;
      }
    };

    const setTotalBounties = (totalBounties: string) => {
      state.totalBounties = totalBounties;
    };

    const setIsLoadingTotalBounties = (
      isLoadingTotalBounties: boolean,
    ) => {
      state.isLoadingTotalBounties = isLoadingTotalBounties;
    };

    const loadCommunityProfile = async (): Promise<boolean> => {
      setState({
        isLoadingCommunityProfile: true,
      });

      try {
        const resp = await useRequest('me');
        const data = resp._data;
        const profile = get(data, 'body', undefined) as
          | {
              id: string;
              name: string;
              industry?: string;
              role?: string;
              isCompany: boolean;
              files: FileType[];
              followerCount: string;
              followedCount: string;
              viewCount: string;
              postViewCount: string;
              talentConnectionCount: string;
              companyConnectionCount: string;
              pendingConnectionCount: string;
            }
          | undefined;

        if (profile) {
          set(profile, 'viewCount', '0');
          set(profile, 'postViewCount', '0');
        }

        setState({
          communityProfile: profile,
          isLoadingCommunityProfile: false,
        });

        return true;
      } catch (e) {
        if (isDevelopment.value || inStaging.value) {
          console.log('Error while loading community profile');
          console.error(e);
        }

        setState({
          isLoadingCommunityProfile: false,
        });
        return false;
      }
    };

    const loadWalletData = async (): Promise<boolean> => {
      if (!isLogged.value) {
        return false;
      }

      setState({
        isLoadingWalletData: true,
      });

      try {
        const resp = await useRequest('finance');
        const data = resp._data;
        const wallet = get(data, 'body', undefined) as
          | UserWalletDataType
          | undefined;

        if (wallet) {
          set(wallet, 'lastUpdateDate', new Date());
        }

        setState({
          wallet,
          isLoadingWalletData: false,
        });

        return true;
      } catch (e) {
        if (isDevelopment.value || inStaging.value) {
          console.log('error in load wallet data ====> ');
          console.dir(e);
        }

        setState({
          isLoadingWalletData: false,
        });
        return false;
      }
    };

    const subscriptions = computed(() => {
      let profileType = String(userType.value || '').toLowerCase();

      if (profileType === 'company-user') {
        profileType = 'company';
      }

      if (profileType) {
        let subscriptions = get(
          profileData.value,
          'subscriptions',
          [],
        ) as SubscriptionPlan[];

        if (!isArray(subscriptions)) {
          subscriptions = [];
        }

        return subscriptions.filter(
          (subscription) =>
            includes(
              [profileType, 'all'],
              String(subscription.for).toLowerCase(),
            ) &&
            subscription.expireOn &&
            isDateValid(new Date(subscription.expireOn)) &&
            isDateInFuture(new Date(subscription.expireOn)),
        );
      }

      return [];
    });

    const redirectAfterAuthData = computed<
      Record<string, unknown> | State['continue']
    >(() => {
      const action = get(state.continue, 'action');

      if (isObject(state.continue) && isString(action)) {
        switch (true) {
          // Apply or favourite job
          case includes(['apply-to-job', 'favourite-job'], action): {
            const title = get(state.continue, 'title', '') as string;
            const id = get(state.continue, 'id') as string | number;

            if (isString(title) && (isString(id) || isNumeric(id))) {
              return {
                ...state.continue,
                route: `/job/${slugify(title)}--${id}`,
              };
            }

            break;
          }

          // Refer a talent for job
          case includes(
            ['join-as-talent', 'join-as-company'],
            action,
          ): {
            return state.continue;
          }

          // Refer a talent for job
          case includes(['refer-a-talent'], action): {
            const title = get(state.continue, 'title');
            const id = get(state.continue, 'id') as string | number;

            if (isString(title) && (isString(id) || isNumeric(id))) {
              return state.continue;
            }
            return state.continue;
          }

          case includes(['create-referrer-profile'], action): {
            return state.continue;
          }

          // Subscribe to payment
          case action === 'subscribed': {
            const plan = get(state.continue, 'plan') as string;
            if (
              plan &&
              includes(values(SubscriptionPlanName), action)
            ) {
              return state.continue;
            }
            break;
          }

          // Redirect to single course
          case action === 'marketplace-product': {
            const id = get(state.continue, 'id') as string;

            if (isString(id) && (isString(id) || isNumeric(id))) {
              return state.continue;
            }
            break;
          }

          case action === 'redirect-to-path':
          case action === 'redirect-to-url': {
            const path = get(
              state.continue,
              'path',
              get(state.continue, 'url', ''),
            ) as string;

            if (isString(path) && path) {
              return {
                ...state.continue,
                path,
              };
            }
            break;
          }
        }
      }

      return undefined;
    });

    const redirectAfterAuthFor = computed<
      | ''
      | 'job'
      | 'subscribed'
      | 'refer'
      | 'marketplace'
      | 'talent-registration'
      | 'company-registration'
      | 'redirect-to-path'
    >(() => {
      const redirectData = redirectAfterAuthData.value;

      switch (true) {
        case includes(
          ['apply-to-job', 'favourite-job'],
          get(redirectData, 'action', ''),
        ): {
          return 'job';
        }

        case includes(
          ['refer-a-talent', 'create-referrer-profile'],
          get(redirectData, 'action', ''),
        ): {
          return 'refer';
        }

        case includes(
          ['subscribed'],
          get(redirectData, 'action', ''),
        ): {
          return 'subscribed';
        }

        case includes(
          ['marketplace-product'],
          get(redirectData, 'action'),
        ): {
          return 'marketplace';
        }

        case includes(
          ['join-as-talent'],
          get(redirectData, 'action', ''),
        ): {
          return 'talent-registration';
        }

        case includes(
          ['join-as-company'],
          get(redirectData, 'action', ''),
        ): {
          return 'company-registration';
        }

        case includes(
          ['redirect-to-path', 'redirect-to-url'],
          get(redirectData, 'action', ''),
        ): {
          return 'redirect-to-path';
        }
      }

      return '';
    });

    const isSubscribedToPaidPlan = computed<boolean>(() => {
      return (
        hasUnlimitedAccess.value ||
        isSubscribedTo.value(SubscriptionPlanName.LITE) ||
        isSubscribedTo.value(SubscriptionPlanName.PRO) ||
        isSubscribedTo.value(SubscriptionPlanName.ENTERPRISE)
      );
    });

    const hasUnlimitedAccess = computed<boolean>(() => {
      return (
        userType.value === 'company-user' &&
        !!get(profileData.value, 'hasUnlimitedAccess', false)
      );
    });

    const isSubscribingTo = computed<
      (plan: SubscriptionPlanName) => boolean
    >(() => {
      return (plan) => {
        return (state.subscriptionProcessingList || []).includes(
          plan,
        );
      };
    });

    const subscribeTo = async (data: {
      plan: SubscriptionPlanName;
      isYearly: boolean;
    }): Promise<void> => {
      const toast = useToast();

      if (!isLogged.value) {
        toast.info('Login to continiue subscription process... ', {
          timeout: 4000,
        });

        await navigateTo('/login');
        return;
      }

      if (!hasProfile.value) {
        toast.info(
          'Please create a profile to continue subscription process... ',
          {
            timeout: 4000,
          },
        );

        // Go to create profile
        await navigateTo({
          name: 'create.profile',
        });
        return;
      }

      if (isSubscribedTo.value(data.plan)) {
        toast.info(
          'Ooops... you already have an active subscription for plan..',
          {
            timeout: 4000,
          },
        );

        return;
      }

      setSubscribingTo({
        isSubscribing: true,
        plan: data.plan,
      });

      try {
        const response = await useRequest('subscribe', {
          method: 'post',
          body: {
            plan: data.plan,
            isYearly: data.isYearly,
          },
        });

        if (response.status === 204) {
          await loadWalletData();
          toast.success('Subscription was successful...', {
            timeout: 4000,
          });

          setSubscribingTo({
            isSubscribing: false,
            plan: data.plan,
          });

          // this is the final action, will lead to app.vue watcher clearing the continue state on next launch
          setState({
            continue: {
              action: 'subscribed',
              plan: data.plan,
              isYearly: data.isYearly,
            },
          });
        }
      } catch (e) {
        if (!e) window.location.reload();

        (() => {
          if (e instanceof FetchError) {
            const status = parseInt(
              String(get(e, 'response.status', '0')),
              10,
            );
            if (status < 500) {
              const errResp = e.data;

              if (isValidationErrorResponse(errResp)) {
                const errorsObject: Record<string, string> = {};
                const maps: Record<string, string> = {};

                if (isArray(get(errResp, 'error.data'))) {
                  errResp.error.data.forEach((error) => {
                    const property = get(
                      maps,
                      error.property,
                      error.property,
                    );
                    const errMsg = get(values(error.constraints), 0);

                    errorsObject[property] = errMsg;
                  });

                  if (!isEmpty(errorsObject)) {
                    const firstErrorProperty = keys(errorsObject)[0];
                    const firstErrorMessage =
                      errorsObject[firstErrorProperty];

                    toast.error(
                      `Ooops... ${firstErrorProperty} field is invalid. ${firstErrorMessage}`,
                      {
                        timeout: 4000,
                      },
                    );

                    return;
                  }
                }
              }

              toast.error(
                errResp.error.message ||
                  'An error occurred while subscribing to plan',
                {
                  timeout: 4000,
                },
              );

              return;
            }
          } else if (!isOnline.value) {
            toast.error(
              'Ooops.. An error occured while subscribing to plan.. PLease check your internet connection',
              {
                timeout: 4000,
              },
            );

            return;
          }

          toast.error(
            'A fatal server error occurred while subscribing to plan',
            {
              timeout: 4000,
            },
          );

          console.dir(e);
        })();
      }

      setSubscribingTo({
        isSubscribing: false,
        plan: data.plan,
      });
    };

    const loadTotalBounties = async () => {
      setIsLoadingTotalBounties(true);

      try {
        const resp = await useRequest('ats/total-bounties');
        const data = resp._data;
        const body = get(data, 'body', undefined);
        const totalBounties = get(
          body,
          'totalJobsReferralBounty',
          '40000',
        );

        setTotalBounties(totalBounties);
        setIsLoadingTotalBounties(false);
      } catch (e: unknown) {
        if (isDevelopment.value || inStaging.value) {
          console.log('Error while loading total bounties');
          console.error(e);
        }

        setIsLoadingTotalBounties(false);
      }
    };

    // Init current date updater
    if (currentDateTimer) {
      clearInterval(currentDateTimer);
    }

    currentDateTimer = setInterval(() => {
      _currentDate.value = new Date();
    }, state.tokenExpiryInterval);

    watch(
      profileData,
      async (profile, prev) => {
        if (isEqual(profile, prev)) {
          return;
        }

        const webSocketStore = useWebSocket();
        const communityMessage = useCommunityMessage();

        const connectionStr = String(
          useRuntimeConfig().public.websocketUrl || '####',
        );

        const isWsConnected =
          webSocketStore.isConnected(connectionStr);
        if (isWsConnected) {
          const ws = toRaw(webSocketStore.connection(connectionStr));
          ws.close();
        }

        webSocketStore.setState({
          connections: {},
          handlers: {},
        });

        communityMessage.setState({
          conversationWindows: [],
          floatingConversations: [],
          pushEventHandlers: {},
        });

        if (profile) {
          if (!stripeScriptRef.value) {
            stripeScriptRef.value = useScriptTag(
              'https://js.stripe.com/v3/',
            );
          }

          loadWalletData();
          loadCommunityProfile();

          if (
            userType.value &&
            ['company-user', 'candidate'].includes(userType.value)
            // isSubscribedToPaidPlan.value
          ) {
            await communityMessage.initWs({
              connectionStr,
            });
          }
        }
      },
      {
        deep: true,
        immediate: true,
      },
    );

    const isModuleReady = async () => {
      const user = useUser();

      if (user.$persistedState && user.$persistedState.isReady) {
        await user.$persistedState.isReady();
      }

      return true;
    };

    const $reset = () => {
      const newState = cloneDeep(initialState) as Record<
        string,
        unknown
      >;

      forEach(newState, (value, key) => {
        set(state, key, value);
      });
    };

    return {
      ...toRefs(state),
      isModuleReady,
      $reset,
      parseToken,
      profileData,
      isLogged,
      hasCompanyProfile,
      profileSubdomain,
      profileType,
      profileId,
      hasTalentProfile,
      hasProfile,
      userType,
      tokenExpiryDate,
      userData,
      walletId,
      canChangeWallet,
      walletBalance,
      isSuperAdmin,
      hasPermissionTo,
      communityProfileFiles,
      hasLoadedCommunityProfile,
      profileImageUrl,
      profileBackgroundImageUrl,
      profileName,
      profileRole,
      generalUrlFor,
      getFirstPermittedPageForMenu,
      getFirstPermittedPage,
      isSubscribedTo,
      subscriptions,
      redirectAfterAuthData,
      redirectAfterAuthFor,
      isSubscribedToPaidPlan,
      hasUnlimitedAccess,
      isSubscribingTo,
      subdomain,
      isInValidDomain,
      setState,
      checkTokenExpiry,
      logout,
      setToken,
      currentReferralStatus,
      syncToken,
      goToGeneralUrlFor,
      rewriteRouteToValidDomain,
      goToDashboard,
      goToHome,
      setSubscribingTo,
      setVendorActiveProfile,
      clearActiveVendorProfile,
      clearContinueAfterAuth,
      setReferralStatus,
      setTotalBounties,
      setIsLoadingTotalBounties,
      loadCommunityProfile,
      loadWalletData,
      subscribeTo,
      loadTotalBounties,
      isLoggingOut,
      removeAuthCookies,
      removePartnerCookies,
      removeAllCookies,
      setPartnerCookie,
      getPartnerSubdomain,
    };
  },
  {
    persistedState: {
      persist: true,
      excludePaths: [
        'isLoggingOut',
        'isLoadingWalletData',
        'isLoadingTotalBounties',
        'isLoadingCommunityProfile',
        'subscriptionProcessingList',
        'hasTokenExpired',
      ],
    },
  },
);
