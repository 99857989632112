import { isString } from 'lodash-es';
import { useUser } from '~~/store/user';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const { $pinia } = useNuxtApp();
    const user = useUser($pinia);
    await user.isModuleReady();
    await user.syncToken();
    await nextTick();

    const subdomain =
      user.subdomain || to.query.partner || from.query.partner;
    if (!user.isLogged && subdomain && isString(subdomain)) {
      await user.setPartnerCookie(subdomain);
    } else if (user.isLogged && user.hasProfile) {
      await user.removePartnerCookies();
    }
  }
});
