import {
  capitalize,
  each,
  has,
  set,
  get,
  isObject,
  isArray,
} from 'lodash-es';
import { defineStore } from 'pinia';
import * as svgIcon from '@yzfe/svgicon';
import type { JobStatusType } from '@/types/job';
import type { SelectListItemType } from '@/types/logged';
import checkedIcon from '@/assets/icons/selected-rectangle.svg?icon';
import unCheckedIcon from '@/assets/icons/checkbox.svg?icon';

type FilterItemType = {
  isActive: boolean;
};

type TitleFilterType = FilterItemType & {
  value: string;
};

type LocationFilterType = FilterItemType & {
  city: string;
  country: string;
};

type DepartmentFilterType = FilterItemType & {
  ids: string[];
};
type StatusType = JobStatusType | 'all';

type StatusListType = {
  value: StatusType;
  label: StatusType;
}[];

export type PastDateValuesType =
  | 'l3d'
  | 'l7d'
  | 'l14d'
  | 'l30d'
  | 'all';

export type FutureDateValuesType =
  | 'n3d'
  | 'n7d'
  | 'n14d'
  | 'n30d'
  | 'all';

type DateCreatedFilterType = FilterItemType & {
  value: PastDateValuesType;
};

type ClosedDateFilterType = FilterItemType & {
  value: FutureDateValuesType;
};

type StatusFilter = FilterItemType & {
  value: StatusType;
};

type FilterType = {
  title?: TitleFilterType;
  location?: LocationFilterType;
  department?: DepartmentFilterType;
  created_date?: DateCreatedFilterType;
  closed_date?: ClosedDateFilterType;
  status?: StatusFilter;
};

export type JobUpdateFieldsType =
  | 'city'
  | 'country'
  | 'isRemote'
  | 'manage-tags'
  | 'status';

interface State {
  selectedIds: string[];
  statusArray: StatusType[];
  filter: FilterType;
  filterStore: FilterType;
  showSelectionActions: boolean;
  selectionAction: 'update' | 'delete' | null;
  fieldToUpdate: JobUpdateFieldsType | null;
  fieldToUpdateValue: unknown;
}
export const useJobList = defineStore(
  'job_list',
  () => {
    const state = reactive<State>({
      selectedIds: [],
      statusArray: [
        'open',
        'filled',
        'on-hold',
        'closed',
        'draft',
        'all',
      ],
      filter: {
        title: {
          isActive: true,
          value: '',
        },
        closed_date: {
          isActive: false,
          value: 'all',
        },
        created_date: {
          isActive: false,
          value: 'all',
        },
        status: {
          isActive: true,
          value: 'all',
        },
      },
      filterStore: {},
      showSelectionActions: false,
      selectionAction: null,
      fieldToUpdate: null,
      fieldToUpdateValue: null,
    });

    const statusList = computed<StatusListType>(() => {
      return state.statusArray.map((status) => ({
        value: status,
        label: capitalize(status) as StatusType,
      }));
    });

    const isItemActive = computed(() => {
      return (item: keyof FilterType): boolean => {
        return state.filterStore[item]?.isActive || false;
      };
    });

    const isItemActiveIcon = computed(() => {
      return (item: keyof FilterType): svgIcon.Icon => {
        const isActive = isItemActive.value(item);
        return (
          isActive ? checkedIcon : unCheckedIcon
        ) as svgIcon.Icon;
      };
    });

    const isDepartmentInFilter = computed(() => {
      return (department: SelectListItemType): boolean => {
        const selectedIds = state.filterStore?.department?.ids || [];
        return selectedIds.includes(department.id);
      };
    });

    const isDepartmentInFilterIcon = computed(() => {
      return (department: SelectListItemType): svgIcon.Icon => {
        const isActive = isDepartmentInFilter.value(department);
        return (
          isActive ? checkedIcon : unCheckedIcon
        ) as svgIcon.Icon;
      };
    });

    const setState = (data: Partial<State>): void => {
      each(data, (value, key) => {
        if (has(state, key)) {
          set(state, key, value);
        }
      });
    };

    const syncFilter = (forward = true): void => {
      if (forward) state.filterStore = state.filter;
      else state.filter = state.filterStore;
    };

    const closeSelectionActions = (): void => {
      state.selectionAction = null;
      state.showSelectionActions = false;
      state.fieldToUpdate = null;
      state.fieldToUpdateValue = null;
    };

    const clearAll = (): void => {
      state.selectedIds = [];
      state.selectionAction = null;
      state.showSelectionActions = false;
      state.fieldToUpdate = null;
      state.fieldToUpdateValue = null;
    };

    const clearSelectedJobs = (): void => {
      state.selectedIds = [];
    };
    const resetFilter = (): void => {
      state.filter = {
        title: {
          isActive: true,
          value: '',
        },
        closed_date: {
          isActive: false,
          value: 'all',
        },
        created_date: {
          isActive: false,
          value: 'all',
        },
      };
    };

    const toggleDepartmentInFilter = (
      department: SelectListItemType,
    ): void => {
      const savedDepartment = state.filterStore.department;
      if (!isObject(savedDepartment)) {
        set(state.filterStore, 'department', {});
      }

      if (!isArray(get(savedDepartment, 'ids'))) {
        set(state.filterStore, 'department.ids', []);
      }

      const index = state.filterStore.department?.ids.findIndex(
        (id) => id === department.id,
      );

      if (index !== undefined && index > -1) {
        if (
          state.filterStore.department &&
          state.filterStore.department.ids
        )
          state.filterStore.department.ids.splice(index, 1);
      } else if (!state.filterStore.department) {
        state.filterStore.department = {
          isActive: true,
          ids: [department.id],
        };
      } else {
        state.filterStore.department.ids.push(department.id);
      }
    };
    const setSelectionAction = (
      selectionAction: 'update' | 'delete' | null,
    ): void => {
      state.selectionAction = selectionAction;
    };

    const setSelectedIds = (ids: string[]): void => {
      state.selectedIds = ids;
    };

    const setFilter = (filter: FilterType): void => {
      state.filterStore = filter;
    };

    const setMainFilter = (filter: FilterType): void => {
      state.filter = filter;
    };

    return {
      ...toRefs(state),
      setMainFilter,
      setFilter,
      setSelectedIds,
      setSelectionAction,
      toggleDepartmentInFilter,
      resetFilter,
      clearSelectedJobs,
      clearAll,
      closeSelectionActions,
      syncFilter,
      setState,
      isDepartmentInFilter,
      isDepartmentInFilterIcon,
      isItemActiveIcon,
      statusList,
      isItemActive,
    };
  },
  {
    persistedState: {
      persist: true,
    },
  },
);
