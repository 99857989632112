import revive_payload_client_4sVQNw7RlN from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import GoogleRecaptcha_fYbRmSenu5 from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/GoogleRecaptcha.ts";
import ResizeObserver_he3wPOtrwD from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/ResizeObserver.ts";
import app_VhrJinsH8K from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/app.ts";
import buffer_aHHLuY3sF2 from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/buffer.ts";
import onClickOutside_lwgIpT0VWz from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/onClickOutside.ts";
import piniaPersistedState_ZQeKFmQNWU from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/piniaPersistedState.ts";
import stripe_6FZUdDHpIZ from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/stripe.ts";
import vLazyImage_KfLpQKYkDm from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vLazyImage.ts";
import vue3AutoCounter_OsnY8Y7nhJ from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vue3AutoCounter.ts";
import vueAutoCounter_0yx9fBZVeU from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueAutoCounter.ts";
import vueIntersectionObserver_dSmmZGCQAP from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueIntersectionObserver.ts";
import vueLoader_MBGMD5hBuw from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueLoader.ts";
import vueMultiSelect_FDuXXOWIkv from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueMultiSelect.ts";
import vueOnClickOutside_gHGJTFqfXO from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueOnClickOutside.ts";
import vuePaginate_1kPZA3g0MT from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vuePaginate.ts";
import vuePopper_NXJb5irgrr from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vuePopper.ts";
import vueScreen_GScAsp9Eet from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueScreen.ts";
import vueSelect_oNTp1xdNRi from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueSelect.ts";
import vueSnip_lrdDnn9I2N from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueSnip.ts";
import vueStarRating_O5fI5VnXAA from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueStarRating.ts";
import vueSvgIcon_OR6nMVZC0l from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueSvgIcon.ts";
import vueToast_hg8YZ23aPu from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueToast.ts";
import vueTooltip_7qaORb5I8e from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueTooltip.ts";
import vueUseMotion_05mcS8OOPd from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/plugins/vueUseMotion.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  GoogleRecaptcha_fYbRmSenu5,
  ResizeObserver_he3wPOtrwD,
  app_VhrJinsH8K,
  buffer_aHHLuY3sF2,
  onClickOutside_lwgIpT0VWz,
  piniaPersistedState_ZQeKFmQNWU,
  stripe_6FZUdDHpIZ,
  vLazyImage_KfLpQKYkDm,
  vue3AutoCounter_OsnY8Y7nhJ,
  vueAutoCounter_0yx9fBZVeU,
  vueIntersectionObserver_dSmmZGCQAP,
  vueLoader_MBGMD5hBuw,
  vueMultiSelect_FDuXXOWIkv,
  vueOnClickOutside_gHGJTFqfXO,
  vuePaginate_1kPZA3g0MT,
  vuePopper_NXJb5irgrr,
  vueScreen_GScAsp9Eet,
  vueSelect_oNTp1xdNRi,
  vueSnip_lrdDnn9I2N,
  vueStarRating_O5fI5VnXAA,
  vueSvgIcon_OR6nMVZC0l,
  vueToast_hg8YZ23aPu,
  vueTooltip_7qaORb5I8e,
  vueUseMotion_05mcS8OOPd
]