const runtimeConfig = computed(() => markRaw(useRuntimeConfig()));
export const isDevelopment = computed(
  () => runtimeConfig.value.public.node_env === 'development',
);

export const isProduction = computed(
  () => runtimeConfig.value.public.node_env === 'production',
);

export const inStaging = computed(
  () =>
    (isProduction.value && runtimeConfig.value.public.inStaging) ||
    isDevelopment.value,
);

export const inProduction = computed(
  () => isProduction.value && !inStaging.value,
);
