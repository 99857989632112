<template>
  <slot />
</template>

<script lang="ts" setup>
import { last, find, omit } from 'lodash-es';
import {
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  toRaw,
} from 'vue';

const props = defineProps({
  target: {
    type: Object as PropType<Element>,
    required: true,
  },
  root: {
    type: Object as PropType<Element | Document>,
    required: false,
    default: undefined,
  },
  rootMargin: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
  threshold: {
    type: [Number, Array] as PropType<number | number[]>,
    required: false,
    default: ([] as number[])
      .fill(0, 0, 1 / 0.1)
      .reduce<number[]>((prev) => {
        const lastNum = last(prev) || 0;
        const currNum = lastNum + 0.1;
        prev.push(currNum);
        return prev;
      }, []),
  },
});

const emit = defineEmits<{
  (
    event: 'change',
    entry: IntersectionObserverEntry,
    unobserve: () => void,
  ): unknown;
}>();

const ioRef = ref<IntersectionObserver | undefined>();

const addObserver = () => {
  const target = toRaw(props.target);

  ioRef.value = new window.IntersectionObserver(
    (entries, observer) => {
      const entry = find(entries, (entry) => entry.target === target);

      if (entry) {
        const unobserve = () => {
          observer.disconnect();
        };

        emit('change', entry, unobserve);
      }
    },
    toRaw(omit(props, ['target'])),
  );

  ioRef.value.observe(target);
};

const removeObserver = () => {
  if (ioRef.value) {
    ioRef.value.disconnect();
    ioRef.value = undefined;
  }
};

onMounted(() => {
  if (props.target && !ioRef.value) {
    addObserver();
  }
});

onBeforeUnmount(() => {
  if (ioRef.value) {
    removeObserver();
  }
});
</script>
