/* eslint-disable import/named */
import {
  size,
  get,
  each,
  has,
  set,
  cloneDeep,
  isArray,
  isString,
  isFunction,
  merge,
  orderBy,
  pick,
  isBoolean,
  differenceBy,
  omit,
  isNull,
} from 'lodash-es';
import { WebsocketEvent } from 'websocket-ts';
import { bignumber, largerEq } from 'mathjs';
import { defineStore, Pinia } from 'pinia';
import uniqid from 'locutus/php/misc/uniqid';
import { addHours, isAfter } from 'date-fns';
import * as app from '#app';
import { FileType } from '@/types/attachment';
import { ChatStatus } from '@/types/community';
import { getSoundFor } from '@/utils/sound';
import type {
  ChatProfileType,
  ChatType,
  ConversationType,
  ConversationWindowType,
  FloatingConversationType,
  GroupConversationType,
  GroupConversationWindowType,
  SingleConversationType,
  SingleConversationWindowType,
} from '@/types/community';

// const websocket = useWebSocket();
type Callback = (
  data?: Record<string, unknown> | undefined | unknown,
) => Promise<void> | void | unknown;

interface State {
  timeoutBeforeSwitchToRest: number;
  isRetryingWsConnection: boolean;
  isFloatingMessageHidden: boolean;
  maxWidth: number;
  conversationWindows: ConversationWindowType[];
  floatingConversations: FloatingConversationType[];
  pushEventHandlers: Record<
    string,
    {
      eventId: string;
      handler: Callback;
    }[]
  >;
  profile: ChatProfileType | null;
  pendingFloatingConversationChatsUpdates: {
    floatingConversationId: string;
    chatId: string;
    value: Partial<ChatType>;
  }[];
  isConversationManagerMinimizedForSpace: boolean;
  lastOpenedConversationId: string;
  isLoadingConversations: boolean;
  users: (ChatProfileType & {
    lastModified: Date;
  })[];
}

// const user = useUser();
// const WebSocketStore = useWebSocket();
export const useCommunityMessage = defineStore(
  'community_message',
  () => {
    const state = reactive<State>({
      timeoutBeforeSwitchToRest: 10000,
      isRetryingWsConnection: false,
      isFloatingMessageHidden: false,
      maxWidth: 0,
      conversationWindows: [],
      floatingConversations: [],
      pushEventHandlers: {},
      profile: null,
      pendingFloatingConversationChatsUpdates: [],
      isConversationManagerMinimizedForSpace: false,
      lastOpenedConversationId: '',
      isLoadingConversations: true,
      users: [],
    });

    const lastOpenedConversation = computed(() => {
      if (state.lastOpenedConversationId) {
        const conversation = state.floatingConversations.find(
          (savedConversation) => {
            return (
              savedConversation.id === state.lastOpenedConversationId
            );
          },
        );

        if (conversation) {
          return conversation;
        }
      }

      if (state.floatingConversations.length > 0) {
        const conversation = state.floatingConversations.find(
          (savedConversation) =>
            savedConversation.id !== 'new_conversation',
        );

        if (conversation) {
          return conversation;
        }
      }

      return undefined;
    });

    const pendingFloatingConversationChatUpdates = computed(() => {
      return (
        floatingConversationId: string,
        chatId: string,
      ): {
        index: number;
        value: Partial<ChatType>;
      }[] => {
        return state.pendingFloatingConversationChatsUpdates
          .map((update, index) => {
            if (
              !(
                update.floatingConversationId ===
                  floatingConversationId && update.chatId === chatId
              )
            ) {
              return undefined;
            }

            return {
              index,
              value: update.value,
            };
          })
          .filter((update) => !!update) as {
          index: number;
          value: Partial<ChatType>;
        }[];
      };
    });

    const user = computed(() => {
      const user = useUser();
      return user;
    });

    const ws = computed(() => {
      return useWebSocket();
    });

    const isWebsocketConnected = computed(() => {
      const config = useRuntimeConfig();
      return ws.value.isConnected(config.public.websocketUrl || '');
    });

    const wsConnection = computed(() => {
      const config = useRuntimeConfig();
      return config.public.websocketUrl
        ? ws.value.connections[config.public.websocketUrl] ||
            undefined
        : undefined;
    });

    const generateConversationId = computed(() => {
      return (
        userType: 'talent' | 'company',
        userId: string,
      ): string => {
        return `${user.value.profileType}-${user.value.profileId}-chat-${userType}-${userId}`;
      };
    });

    const showMessenger = computed(() => {
      return (
        isWebsocketConnected.value &&
        user.value.hasLoadedCommunityProfile &&
        user.value.hasProfile &&
        !!user.value.userType
      );
    });

    const floatingConversationWidth = computed(() => {
      const config = useRuntimeConfig();
      return (
        parseInt(
          config.public.floatingConversationWidth || '0',
          10,
        ) || 0
      );
    });

    const expandedFloatingConversationWidth = computed(() => {
      const config = useRuntimeConfig();
      return (
        parseInt(
          config.public.floatingConversationExpandedWidth || '0',
          10,
        ) || 0
      );
    });

    const floatingConversationHeight = computed(() => {
      const config = useRuntimeConfig();
      return (
        parseInt(
          config.public.floatingConversationHeight || '0',
          10,
        ) || 0
      );
    });

    const expandedFloatingConversationHeight = computed(() => {
      const config = useRuntimeConfig();
      return (
        parseInt(
          config.public.floatingConversationExpandedHeight || '0',
          10,
        ) || 0
      );
    });

    const floatingConversationMargin = computed(() => {
      const config = useRuntimeConfig();
      return (
        parseInt(
          config.public.floatingConversationMargin || '0',
          10,
        ) || 0
      );
    });

    const remainingWidth = computed(() => {
      const floatingWidth = floatingConversationWidth.value;
      const maxFloatingWidth =
        expandedFloatingConversationWidth.value;
      const totalFloatingConversations = size(
        state.floatingConversations,
      );
      const _floatingConversationMargin =
        floatingConversationMargin.value;

      let used = maxFloatingWidth;
      if (totalFloatingConversations > 0) {
        used +=
          totalFloatingConversations *
          (floatingWidth + _floatingConversationMargin);
      }

      return Math.max(state.maxWidth - used, 0);
    });

    const allowedFloatingConversationCount = computed(() => {
      const max = Math.floor(
        (state.maxWidth -
          (expandedFloatingConversationWidth.value +
            +floatingConversationMargin.value)) /
          (floatingConversationMargin.value +
            floatingConversationWidth.value),
      );

      if (max < 0) {
        return 0;
      }

      return max;
    });

    const hasConversationWindows = computed(() => {
      return state.conversationWindows.length > 0;
    });

    const hasFloatingConversations = computed(() => {
      return size(state.floatingConversations) > 0;
    });

    const currentUserProfile = computed<ChatProfileType | undefined>(
      () => {
        if (state.profile) {
          return state.profile;
        } else if (user.value.isLogged) {
          return {
            imageUrl: user.value.profileImageUrl,
            name: user.value.profileName,
            isOwner: true,
            isAdmin: true,
            canView: true,
            type:
              user.value.userType === 'candidate'
                ? 'talent'
                : user.value.userType === 'company-user'
                ? 'company'
                : 'company',
            lastSeen: 'online',
            isOnline: true,
            id: user.value.profileId,
          };
        }

        return undefined;
      },
    );

    const handlersForPushEvent = computed(() => {
      return (eventName: string): Callback[] => {
        if (isArray(state.pushEventHandlers[eventName])) {
          return state.pushEventHandlers[eventName].map(
            (data) => data.handler,
          );
        }

        return [];
      };
    });

    const floatingConversationHiddenState = computed(() => {
      return (id: string): boolean => {
        const index = state.floatingConversations.findIndex(
          (savedConversation) => savedConversation.id === id,
        );

        if (index > -1) {
          return get(
            state.floatingConversations,
            `${index}.isHidden`,
            false,
          );
        }

        return false;
      };
    });

    const floatingConversationExpandedState = computed(() => {
      return (id: string): boolean => {
        const index = state.floatingConversations.findIndex(
          (savedConversation) => savedConversation.id === id,
        );

        if (index > -1) {
          return get(
            state.floatingConversations,
            `${index}.isExpanded`,
            false,
          );
        }

        return false;
      };
    });

    const floatingConversationChats = computed(() => {
      return (id: string): FloatingConversationType['chats'] => {
        const index = state.floatingConversations.findIndex(
          (floatingConversation) => floatingConversation.id === id,
        );

        if (index > -1) {
          return state.floatingConversations[index].chats || [];
        }

        return [];
      };
    });

    const createNewConversation = async (): Promise<void> => {
      const id = 'new_conversation';
      const newConversation: FloatingConversationType = {
        id,
        chats: [],
        type: 'single',
        users: [],
        isExpanded: false,
        isHidden: false,
        isFocused: true,
      };

      await openFloatingConversation({
        conversation: newConversation,
      });
    };

    const removeFloatingConversationChatUpdates = (data: {
      id: string;
    }): void => {
      state.pendingFloatingConversationChatsUpdates.filter(
        (update) => update.floatingConversationId !== data.id,
      );
    };

    const setState = (data: Partial<State>): void => {
      each(data, (value, key) => {
        if (has(state, key)) {
          set(state, key, value);
        }
      });
    };

    const computeMaxWidth = (): void => {
      state.maxWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      );
    };

    const addConversationWindow = (
      window: ConversationWindowType,
    ): void => {
      const index = state.conversationWindows.findIndex(
        (savedConersationWindow) =>
          window.id === savedConersationWindow.id,
      );

      if (index > -1) {
        state.conversationWindows[index] = window;
      } else {
        state.conversationWindows.push(window);
      }
    };

    const removeConversationWindow = (
      window: ConversationWindowType,
    ): void => {
      const index = state.conversationWindows.findIndex(
        (savedConersationWindow) =>
          window.id === savedConersationWindow.id,
      );

      if (index > -1) {
        state.conversationWindows.splice(index, 1);
      }
    };

    const addFloatingConversation = (data: {
      conversation: FloatingConversationType;
    }): void => {
      const index = state.floatingConversations.findIndex(
        (saved) => data.conversation.id === saved.id,
      );

      if (index > -1) {
        state.floatingConversations[index] = data.conversation;
      } else {
        state.floatingConversations.push(data.conversation);
      }
    };

    const removeFloatingConversation = (data: {
      id: string;
    }): void => {
      const index = state.floatingConversations.findIndex(
        (saved) => data.id === saved.id,
      );

      if (index > -1) {
        state.floatingConversations.splice(index, 1);
      }
    };

    const setFloatingConversationChats = (data: {
      store: ChatType[];
      id: string;
    }): void => {
      const index = state.floatingConversations.findIndex(
        ({ id }) => id === data.id,
      );

      if (index > -1) {
        set(
          state.floatingConversations,
          `${index}.chats`,
          data.store,
        );
      }
    };

    const addFloatingConversationChats = (data: {
      chats: ChatType[];
      id: string;
    }): void => {
      const index = state.floatingConversations.findIndex(
        ({ id }) => id === data.id,
      );

      if (index > -1) {
        let chats = state.floatingConversations[index].chats;
        if (!isArray(chats)) {
          state.floatingConversations[index].chats = [];
          chats = state.floatingConversations[index].chats;
        }

        const newChats = differenceBy(data.chats, chats, 'id');
        const existingChats = differenceBy(
          data.chats,
          newChats,
          'id',
        );

        existingChats.forEach((chat) => {
          const chatIndex = chats.findIndex(
            ({ id }) => id === chat.id,
          );

          if (chatIndex > -1) {
            chats[chatIndex] = chat;
          }
        });

        if (newChats.length > 0) {
          state.floatingConversations[index].chats = orderBy(
            chats.concat(newChats),
            [
              (chat) =>
                new Date(
                  chat.kind === 'chat' ? chat.date : new Date(),
                ).getTime(),
            ],
            ['asc'],
          );
        }
      }
    };

    const removeFloatingConversationChat = (data: {
      chatId: string;
      id: string;
    }): void => {
      const index = state.floatingConversations.findIndex(
        ({ id }) => id === data.id,
      );

      if (index > -1) {
        const chats = state.floatingConversations[index].chats || [];
        const chatIndex = chats.findIndex(
          (saved) => data.chatId === saved.id,
        );

        if (chatIndex > -1) {
          chats.splice(chatIndex, 1);
        }
      }
    };

    const addPushEventHandler = (data: {
      pushEventName: string;
      eventId: string;
      handler: Callback;
      replace: boolean;
    }): void => {
      let handlers = state.pushEventHandlers[data.pushEventName];
      if (!isArray(handlers)) {
        handlers = [];
      }

      const handler = {
        eventId: data.eventId,
        handler: data.handler,
      };

      const index = handlers.findIndex(
        ({ eventId }) => eventId === data.eventId,
      );

      const replace = get(data, 'replace', true);
      if (index > -1 && replace) {
        handlers[index] = handler;
      } else {
        handlers.push(handler);
      }

      state.pushEventHandlers[data.pushEventName] = handlers;
    };

    const removePushEventHandler = (data: {
      pushEventName: string;
      eventId: string;
    }): void => {
      const handlers = state.pushEventHandlers[data.pushEventName];
      if (!isArray(handlers)) {
        return;
      }

      const index = handlers.findIndex(
        ({ eventId }) => eventId === data.eventId,
      );

      if (index > -1) {
        handlers.splice(index, 1);
        state.pushEventHandlers[data.pushEventName] = handlers;
      }
    };

    const refreshFloatingConversations = async (): Promise<void> => {
      // Disable processing floating conversations
      return;

      let floatingConversationsCount = size(
        state.floatingConversations,
      );

      if (hasFloatingConversations.value) {
        if (
          floatingConversationsCount >
          allowedFloatingConversationCount.value
        ) {
          if (floatingConversationsCount > 1) {
            const floatingConversations = cloneDeep(
              state.floatingConversations,
            );

            floatingConversations.splice(
              0,
              floatingConversationsCount -
                allowedFloatingConversationCount.value,
            );

            setState({
              floatingConversations,
            });
          } else {
            user.value.setState({
              communityMessageState: 'minimized',
            });

            setState({
              isConversationManagerMinimizedForSpace: true,
            });
          }
        }
      }

      await nextTick();

      floatingConversationsCount = size(state.floatingConversations);

      if (
        floatingConversationsCount <=
        allowedFloatingConversationCount.value
      ) {
        if (
          user.value.communityMessageState === 'minimized' &&
          state.isConversationManagerMinimizedForSpace
        ) {
          user.value.setState({
            communityMessageState: 'maximized',
          });

          setState({
            isConversationManagerMinimizedForSpace: false,
          });
        }
      }
    };

    const handleWidthChange = () => {
      computeMaxWidth();
      refreshFloatingConversations();
    };

    const loadConversations = async (
      data: {
        more: boolean;
        refresh: boolean;
      } = {
        more: false,
        refresh: true,
      },
    ) => {
      state.isLoadingConversations = true;
      let skip = 0;
      const take = 50;

      if (!data.refresh) {
        if (data.more) {
          skip = state.conversationWindows.length;
        } else if (state.conversationWindows.length >= take) {
          skip = state.conversationWindows.length - take;
        }
      }

      await request({
        method: 'get',
        key: 'community-message-windows',
        query: {
          skip,
          take,
        },
        body: {},
        handler: (res) => {
          state.isLoadingConversations = false;
          type GeneralChatList = {
            id: string;
            files: FileType[];
            text: string;
            user: ChatProfileType;
            isRead: boolean;
            isDelivered: boolean;
            unreadCount?: number;
            date: string | Date;
          };

          type SingleChatList = GeneralChatList & {
            isGroup: false;
            users: ChatProfileType[];
          };

          type GroupChatListType = GeneralChatList & {
            isGroup: true;
            groupId: string;
            title: string;
            users: (ChatProfileType & {
              lastReadId: string;
              lastDeliveredId: string;
            })[];
          };

          const list = get(res, 'value', []) as (
            | SingleChatList
            | GroupChatListType
          )[];

          if (isArray(list)) {
            if (data.refresh) {
              state.conversationWindows = [];
            }

            list.forEach((data) => {
              if (data.isGroup) {
                const conversationWindow: ConversationWindowType = {
                  id: data.groupId,
                  isGroup: data.isGroup,
                  title: data.title,
                  unreadCount: data.unreadCount || 0,
                  chat: {
                    id: data.id,
                    user: data.user,
                    text: data.text,
                    date: data.date,
                    files: data.files,
                    showImage: true,
                    showSubmissionDetails: true,
                    kind: 'chat',
                    isRead: data.isRead,
                    isDelivered: data.isDelivered,
                    status: data.isRead
                      ? ChatStatus.READ
                      : data.isDelivered
                      ? ChatStatus.DELIVERED
                      : ChatStatus.SENT,
                  },
                  users: data.users,
                };

                addConversationWindow(conversationWindow);
              } else {
                const receiver = data.users.find(
                  (user) => !user.isOwner,
                );

                if (receiver) {
                  const conversationWindow: ConversationWindowType = {
                    id: `${receiver.type}_${receiver.id}`,
                    isGroup: data.isGroup,
                    unreadCount: data.unreadCount || 0,
                    chat: {
                      id: data.id,
                      user: data.user,
                      text: data.text,
                      date: data.date,
                      files: data.files,
                      showImage: true,
                      showSubmissionDetails: true,
                      kind: 'chat',
                      isRead: data.isRead,
                      isDelivered: data.isDelivered,
                      status: data.isRead
                        ? ChatStatus.READ
                        : data.isDelivered
                        ? ChatStatus.DELIVERED
                        : ChatStatus.SENT,
                    },
                    users: data.users,
                  };
                  addConversationWindow(conversationWindow);
                }
              }
            });
          }
        },
      });

      computeMaxWidth();
      refreshFloatingConversations();
    };

    const loadSingleConversationChats = async (data: {
      user: {
        id: string;
        type: string;
      };
      more: boolean;
      refresh: boolean;
    }): Promise<void> => {
      let skip = 0;
      const take = 50;

      const floatingConversationId = `${data.user.type}_${data.user.id}`;
      const floatingConversationIndex =
        state.floatingConversations.findIndex(
          (conversation) =>
            conversation.id === floatingConversationId,
        );
      let chats = get(
        state.floatingConversations,
        `${floatingConversationIndex}.chats`,
        [],
      );

      if (!isArray(chats)) {
        chats = [];
      }

      if (!data.refresh) {
        if (data.more) {
          skip = chats.length;
        } else if (chats.length >= take) {
          skip = chats.length - take;
        }
      }

      await new Promise((resolve) => {
        request({
          method: 'get',
          key: 'community-message-chats',
          query: {
            skip,
            take,
            user: data.user,
          },
          body: {},
          handler: (res) => {
            type SingleChatRecordType = {
              id: string;
              files: FileType[];
              text: string;
              sender: ChatProfileType;
              receiver: ChatProfileType;
              isRead: boolean;
              isDelivered: boolean;
              date: string | Date;
            };

            const list = get(
              res,
              'value',
              [],
            ) as SingleChatRecordType[];

            if (isArray(list)) {
              if (data.refresh) {
                setFloatingConversationChats({
                  store: [],
                  id: floatingConversationId,
                });
              }

              const chats = list.map((data) => {
                const chat: ChatType = {
                  id: data.id,
                  user: data.sender,
                  text: data.text,
                  date: data.date,
                  files: data.files,
                  showImage: true,
                  showSubmissionDetails: true,
                  kind: 'chat',
                  isRead: data.isRead,
                  isDelivered: data.isDelivered,
                  status: data.isRead
                    ? ChatStatus.READ
                    : data.isDelivered
                    ? ChatStatus.DELIVERED
                    : ChatStatus.SENT,
                };

                return chat;
              });

              addFloatingConversationChats({
                chats,
                id: floatingConversationId,
              });
            }

            resolve(true);
          },
        });
      });
    };

    const loadGroupConversationChats = async (data: {
      groupId: string;
      more: boolean;
      refresh: boolean;
    }): Promise<void> => {
      let skip = 0;
      const take = 50;

      const floatingConversationIndex =
        state.floatingConversations.findIndex(
          (conversation) => conversation.id === data.groupId,
        );
      let chats = get(
        state.floatingConversations,
        `${floatingConversationIndex}.chats`,
        [],
      );

      if (!isArray(chats)) {
        chats = [];
      }

      if (!data.refresh) {
        if (data.more) {
          skip = chats.length;
        } else if (chats.length >= take) {
          skip = chats.length - take;
        }
      }

      await new Promise((resolve) => {
        request({
          method: 'get',
          key: 'community-message-group-chats',
          query: {
            skip,
            take,
            groupId: data.groupId,
          },
          body: {},
          handler: (res) => {
            type GroupChatRecordType = {
              id: string;
              files: FileType[];
              text: string;
              user: ChatProfileType;
              isRead: boolean;
              isDelivered: boolean;
              date: string | Date;
            };

            const list = get(
              res,
              'value',
              [],
            ) as GroupChatRecordType[];

            if (isArray(list)) {
              if (data.refresh) {
                setFloatingConversationChats({
                  store: [],
                  id: data.groupId,
                });
              }

              const chats = list.map((record) => {
                const chat: ChatType = {
                  id: record.id,
                  user: record.user,
                  text: record.text,
                  date: record.date,
                  files: record.files,
                  showImage: true,
                  showSubmissionDetails: true,
                  kind: 'chat',
                  isRead: record.isRead,
                  isDelivered: record.isDelivered,
                  status: record.isRead
                    ? ChatStatus.READ
                    : record.isDelivered
                    ? ChatStatus.DELIVERED
                    : ChatStatus.SENT,
                };

                return chat;
              });

              addFloatingConversationChats({
                chats,
                id: data.groupId,
              });
            }

            resolve(true);
          },
        });
      });
    };

    const openFloatingConversation = async (data: {
      conversation: ConversationType;
    }): Promise<void> => {
      const index = state.floatingConversations.findIndex(
        (savedConversation) => {
          return savedConversation.id === data.conversation.id;
        },
      );

      let id = '';

      if (index === -1) {
        let floatingConversation:
          | FloatingConversationType
          | undefined = {
          ...data.conversation,
          isExpanded: false,
          isHidden: false,
          isFocused: true,
        };

        if (data.conversation.type === 'single') {
          if (!data.conversation.id) {
            console.log(
              'call open floating conversation in store single without conversation id ',
            );

            const otherUser = data.conversation.users.find(
              (user) => !user.isOwner,
            );

            if (otherUser) {
              const storeId = `${otherUser.type}_${otherUser.id}`;
              floatingConversation.id = storeId;
            }
          }
        } else if (data.conversation.type === 'group') {
          floatingConversation = {
            ...data.conversation,
            isExpanded: false,
            isHidden: false,
            isFocused: true,
          };
        }

        if (floatingConversation) {
          await addFloatingConversation({
            conversation: floatingConversation,
          });

          await refreshFloatingConversations();
          id = floatingConversation.id;
        }
      } else {
        id = get(state.floatingConversations, `${index}.id`, '0');
      }

      console.log('id of open floating conversation is ===> ', id);

      if (id) {
        await setFloatingConversationFocused({
          id,
          isFocused: true,
        });

        const index = state.floatingConversations.findIndex(
          (savedConversation) => savedConversation.id === id,
        );

        if (index > -1) {
          set(
            state.floatingConversations,
            `${index}.isHidden`,
            false,
          );

          set(
            state.floatingConversations,
            `${index}.isExpanded`,
            false,
          );
        }

        state.lastOpenedConversationId = id;
      }
    };

    const loadAndRefreshOpenConversations =
      async (): Promise<void> => {
        // Load and update current user data
        getUser({
          id: user.value.profileId,
          type: user.value.profileType,
          handler: (profile) => {
            setState({
              profile: profile as ChatProfileType,
            });
          },
        });

        // Load conversation windows
        await loadConversations({
          more: false,
          refresh: true,
        });

        // Refresh open conversation windows
        state.floatingConversations.forEach((conversation) => {
          if (conversation.type === 'single') {
            const otherUser = conversation.users.find(
              (user) =>
                !(
                  user.id === currentUserProfile?.value?.id &&
                  user.type === currentUserProfile?.value?.type
                ),
            );

            if (otherUser) {
              loadSingleConversationChats({
                user: pick(otherUser, ['id', 'type']),
                more: false,
                refresh: true,
              });
            }
          } else if (conversation.type === 'group') {
            loadGroupConversationChats({
              groupId: conversation.id,
              more: false,
              refresh: true,
            });
          }
        });

        // Load conversation windows
        await loadConversations({
          more: false,
          refresh: true,
        });

        // Refresh open conversation windows
        state.floatingConversations.forEach((conversation) => {
          if (conversation.type === 'single') {
            const otherUser = conversation.users.find(
              (user) =>
                !(
                  user.id === currentUserProfile?.value?.id &&
                  user.type === currentUserProfile?.value?.type
                ),
            );

            if (otherUser) {
              loadSingleConversationChats({
                user: pick(otherUser, ['id', 'type']),
                more: false,
                refresh: true,
              });
            }
          } else if (conversation.type === 'group') {
            loadGroupConversationChats({
              groupId: conversation.id,
              more: false,
              refresh: true,
            });
          }
        });
      };

    const addFloatingConversationChatUpdate = (data: {
      floatingConversationId: string;
      chatId: string;
      value: Partial<ChatType>;
    }): void => {
      state.pendingFloatingConversationChatsUpdates.push(data);
    };

    const initWs = async (data: {
      connectionStr: string;
    }): Promise<void> => {
      if (
        !ws.value.isConnected(data.connectionStr) &&
        user.value.isLogged &&
        user.value.hasProfile
      ) {
        const _ws = toRaw(
          await ws.value.connectWebSocket(data.connectionStr),
        );

        _ws.addEventListener(WebsocketEvent.message, (_, ev) => {
          const data = JSON.parse(ev.data);

          const method = get(data, 'method', undefined);
          const key = get(data, 'key', '');
          const value = get(data, 'value', {});

          if (method === 'push' && isString(key) && key) {
            const handlers = handlersForPushEvent.value(key);
            if (isArray(handlers) && handlers.length) {
              handlers.forEach(
                (handler) => isFunction(handler) && handler(value),
              );
            }
          }
        });

        _ws.addEventListener(WebsocketEvent.open, (ws) => {
          if (state.isRetryingWsConnection) {
            setState({
              isRetryingWsConnection: false,
            });
            const WebSocketStore = useWebSocket();

            WebSocketStore.sendAuthToken(ws, user.value.token);
            loadAndRefreshOpenConversations();
          }
        });

        _ws.addEventListener(WebsocketEvent.retry, (ws) => {
          if (user.value.hasTokenExpired) {
            ws.close();
          } else {
            setState({
              isRetryingWsConnection: true,
            });
          }
        });
      }

      if (ws.value.isConnected(data.connectionStr)) {
        loadAndRefreshOpenConversations();
      }

      addPushEventHandler({
        pushEventName: 'community-message-group-chat',
        eventId: 'community-message-group-chat',
        handler: (data: unknown) => {
          type DataType = {
            isNew: boolean;
            title?: string;
            groupId: string;
            id: string;
            text: string;
            files: FileType[];
            user: {
              id: string;
              type: string;
            };
            users:
              | {
                  id: string;
                  type: 'talent' | 'company';
                  isOwner: boolean;
                  isAdmin: boolean;
                  lastReadId: string;
                  lastDeliveredId: string;
                }[]
              | undefined;
            date: string | Date;
          };

          const resp = data as DataType;
          getUser({
            id: resp.user.id,
            type: resp.user.type,
            handler: async (data) => {
              let profile = data as ChatProfileType;

              if (!profile) {
                profile = {
                  imageUrl: '',
                  name: 'Anonymous User',
                  isOwner: false,
                  isAdmin: false,
                  type: resp.user.type as 'talent' | 'company',
                  lastSeen: undefined,
                  canView: false,
                  isOnline: true,
                  id: resp.user.id,
                };
              } else {
                profile.canView = true;
              }

              const chat: ChatType = {
                id: resp.id,
                status: ChatStatus.SENT,
                user: profile,
                text: resp.text,
                files: resp.files,
                date: resp.date,
                showImage: true,
                showSubmissionDetails: true,
                isRead: false,
                isDelivered: false,
                kind: 'chat',
              };

              const isChatAuthor =
                chat.user.id === currentUserProfile?.value?.id &&
                chat.user.type === currentUserProfile.value.type;
              chat.user.isOwner = isChatAuthor;

              type GroupChatProfileType = ChatProfileType & {
                lastReadId: string;
                lastDeliveredId: string;
              };

              let users: GroupChatProfileType[] = [];
              if (isArray(resp.users)) {
                const userPromises: Promise<GroupChatProfileType>[] =
                  [];

                resp.users.forEach((user) => {
                  userPromises.push(
                    new Promise((resolve, reject) => {
                      getUser({
                        id: user.id,
                        type: user.type,
                        handler: (data) => {
                          const profile = data as ChatProfileType;

                          if (profile) {
                            resolve(
                              merge(profile, {
                                lastReadId: '0',
                                lastDeliveredId: '0',
                              }),
                            );
                          } else {
                            reject(createError('An Error Occured'));
                          }
                        },
                      });
                      // this.context.dispatch('getUser', {
                      //   id: user.id,
                      //   type: user.type,
                      //   handler: (profile: ChatProfileType) => {
                      //     if (profile) {
                      //       resolve(
                      //         merge(profile, {
                      //           lastReadId: '0',
                      //           lastDeliveredId: '0',
                      //         }),
                      //       );
                      //     } else {
                      //       reject();
                      //     }
                      //   },
                      // });
                    }),
                  );
                });

                users = (await Promise.allSettled(userPromises)).map(
                  (promise, index) => {
                    if (promise.status === 'fulfilled') {
                      return promise.value;
                    }

                    const user =
                      (get(resp.users, index, undefined) as
                        | GroupChatProfileType
                        | undefined) ||
                      ({
                        id: String(index),
                        type: 'talent',
                        isOwner: false,
                        isAdmin: false,
                        lastReadId: '',
                        lastDeliveredId: '',
                        imageUrl: '',
                        name: '',
                        canView: true,
                        lastSeen: undefined,
                        isOnline: true,
                      } as GroupChatProfileType);

                    const canView = get(user, 'canView', null) as
                      | boolean
                      | null;

                    return {
                      ...user,
                      canView: isBoolean(canView) ? canView : false,
                      imageUrl: '',
                      name: '',
                      lastSeen: undefined,
                      isOnline: false,
                    };
                  },
                );
              }

              const floatingConversationIndex =
                state.floatingConversations.findIndex(
                  (conversation) => conversation.id === resp.groupId,
                );

              if (floatingConversationIndex > -1) {
                if (
                  chat.user.id === currentUserProfile?.value?.id &&
                  chat.user.type === currentUserProfile.value.type
                ) {
                  const chats =
                    state.floatingConversations[
                      floatingConversationIndex
                    ].chats || [];

                  const pendingSubmissionChatIndex = orderBy(
                    chats.filter(
                      (chat) =>
                        chat.kind === 'chat' &&
                        chat.hasSubmitted === false,
                    ),
                    [
                      (chat) =>
                        new Date(
                          chat.kind === 'chat'
                            ? chat.date
                            : new Date(),
                        ).getTime(),
                    ],
                    ['asc'],
                  ).findIndex(
                    (pendingChat) =>
                      pendingChat.kind === 'chat' &&
                      pendingChat.text === chat.text,
                  );

                  if (pendingSubmissionChatIndex > -1) {
                    set(
                      state.floatingConversations,
                      `${floatingConversationIndex}.chats.${pendingSubmissionChatIndex}`,
                      {
                        ...chat,
                        hasSubmitted: true,
                      },
                    );
                  } else {
                    addFloatingConversationChats({
                      id: resp.groupId,
                      chats: [chat],
                    });
                  }
                } else {
                  addFloatingConversationChats({
                    id: resp.groupId,
                    chats: [chat],
                  });
                  getSoundFor(
                    'newMessageInOpenedConversation',
                  )?.play();
                }
              } else if (
                resp.user.id === user.value.profileId &&
                resp.user.type === user.value.profileType
              ) {
                const conversation: GroupConversationType = {
                  id: resp.groupId,
                  type: 'group',
                  chats: [chat],
                  users,
                  isFocused: false,
                  groupId: resp.groupId,
                  title: resp.title || '',
                };

                openFloatingConversation({
                  conversation,
                });
              }

              if (
                !(
                  chat.user.id === currentUserProfile?.value?.id &&
                  chat.user.type === currentUserProfile.value.type
                ) &&
                floatingConversationIndex === -1
              ) {
                getSoundFor('newMessage')?.play();
              }

              if (resp.isNew) {
                let unreadCount = 0;

                if (
                  !(
                    chat.user.id === currentUserProfile?.value?.id &&
                    chat.user.type === currentUserProfile.value.type
                  )
                ) {
                  unreadCount = 1;
                }

                const conversationWindow: GroupConversationWindowType =
                  {
                    id: resp.groupId,
                    isGroup: true,
                    title: resp.title || '',
                    chat,
                    users,
                    unreadCount,
                  };
                addConversationWindow(conversationWindow);
              } else {
                const conversationWindowIndex =
                  state.conversationWindows.findIndex(
                    (window) => window.id === resp.groupId,
                  );

                if (conversationWindowIndex > -1) {
                  const window = cloneDeep(
                    state.conversationWindows[
                      conversationWindowIndex
                    ],
                  );

                  if (
                    !(
                      chat.user.id ===
                        currentUserProfile?.value?.id &&
                      chat.user.type === currentUserProfile.value.type
                    )
                  ) {
                    const unreadCount =
                      parseInt(
                        String(window.unreadCount || 0) || '0',
                      ) || 0;
                    window.unreadCount = unreadCount + 1;
                  }

                  window.chat = chat;
                  addConversationWindow(window);
                } else {
                  await loadConversations({
                    more: false,
                    refresh: true,
                  });
                }
              }
            },
          });
        },
        replace: false,
      });

      addPushEventHandler({
        pushEventName: 'community-message-group-chat-action',
        eventId: 'community-message-group-chat-action',
        handler: async (data: unknown) => {
          type DataType = {
            groupId: string;
            lastReadId: string;
            lastDeliveredId: string;
            user: {
              id: string;
              type: string;
            };
          };

          const resp = data as DataType;
          await getUser({
            id: resp.user.id,
            type: resp.user.type,
            handler: () => {
              const floatingConversationIndex =
                state.floatingConversations.findIndex(({ id }) => {
                  return id === resp.groupId;
                });

              if (floatingConversationIndex > -1) {
                const floatingConversation = cloneDeep(
                  state.floatingConversations[
                    floatingConversationIndex
                  ],
                );
                const userIndex =
                  floatingConversation.users.findIndex((user) => {
                    return (
                      user.id === resp.user.id &&
                      user.type === resp.user.type
                    );
                  });

                if (userIndex > -1) {
                  if (
                    largerEq(
                      bignumber(resp.lastReadId || '0'),
                      bignumber(
                        get(
                          floatingConversation.users[userIndex],
                          'lastReadId',
                          '0',
                        ) || '0',
                      ),
                    )
                  ) {
                    set(
                      floatingConversation.users[userIndex],
                      'lastReadId',
                      resp.lastReadId,
                    );
                  }

                  if (
                    largerEq(
                      bignumber(resp.lastDeliveredId || '0'),
                      bignumber(
                        get(
                          floatingConversation.users[userIndex],
                          'lastDeliveredId',
                          '0',
                        ) || '0',
                      ),
                    )
                  ) {
                    set(
                      floatingConversation.users[userIndex],
                      'lastDeliveredId',
                      resp.lastDeliveredId,
                    );
                  }
                  addFloatingConversation({
                    conversation: floatingConversation,
                  });
                }
              }

              const conversationWindowIndex =
                state.conversationWindows.findIndex(({ id }) => {
                  return id === resp.groupId;
                });

              if (conversationWindowIndex > -1) {
                const conversationWindow = cloneDeep(
                  state.conversationWindows[conversationWindowIndex],
                );

                if (floatingConversationIndex > -1) {
                  conversationWindow.unreadCount = 0;
                }

                const userIndex = conversationWindow.users.findIndex(
                  (user) => {
                    return (
                      user.id === resp.user.id &&
                      user.type === resp.user.type
                    );
                  },
                );

                if (userIndex > -1) {
                  set(
                    conversationWindow.users[userIndex],
                    'lastReadId',
                    resp.lastReadId,
                  );

                  set(
                    conversationWindow.users[userIndex],
                    'lastDeliveredId',
                    resp.lastDeliveredId,
                  );
                }

                if (!conversationWindow.chat.user.isOwner) {
                  if (
                    largerEq(
                      bignumber(resp.lastDeliveredId),
                      bignumber(conversationWindow.chat.id),
                    )
                  ) {
                    conversationWindow.chat.isDelivered = true;
                  }

                  if (
                    largerEq(
                      bignumber(resp.lastReadId),
                      bignumber(conversationWindow.chat.id),
                    )
                  ) {
                    conversationWindow.chat.isRead = true;
                  }
                }
                addConversationWindow(conversationWindow);
              }
            },
          });
        },
        replace: false,
      });

      addPushEventHandler({
        pushEventName: 'community-message-chat',
        eventId: 'community-message-chat',
        handler: async (data: unknown) => {
          type DataType = {
            id: string;
            text: string;
            files: FileType[];
            user: {
              id: string;
              type: string;
            };
            receiver: {
              id: string;
              type: string;
            };
            date: string | Date;
          };

          const resp = data as DataType;

          let users: ChatProfileType[] = [];
          const userPromises: Promise<ChatProfileType>[] = [];
          const list = [resp.user];
          if (
            !(
              resp.user.id === resp.receiver.id &&
              resp.user.type === resp.receiver.type
            )
          ) {
            list.push(resp.receiver);
          }

          list.forEach((user) => {
            userPromises.push(
              new Promise((resolve) => {
                getUser({
                  id: user.id,
                  type: user.type,
                  handler: (data) => {
                    const profile = data as ChatProfileType;
                    resolve(profile);
                  },
                });
              }),
            );
          });

          users = (await Promise.allSettled(userPromises)).map(
            (promise, index) => {
              if (promise.status === 'fulfilled') {
                promise.value.canView = true;
                return promise.value;
              }

              return {
                id: String(index),
                type: 'talent',
                isOwner: false,
                isAdmin: false,
                canView: false,
                imageUrl: '',
                name: 'Anonymous User',
                lastSeen: undefined,
                isOnline: false,
              };
            },
          );

          const _user = users.find(
            (user) =>
              user.id === resp.user.id &&
              user.type === resp.user.type,
          ) as ChatProfileType;

          const receiver = users.find(
            (_user) =>
              !(
                _user.id === user.value.profileId &&
                _user.type === user.value.profileType
              ),
          ) as ChatProfileType;

          const id = `${receiver.type}_${receiver.id}`;

          const chat: ChatType = {
            id: resp.id,
            status: ChatStatus.SENT,
            user: _user,
            text: resp.text,
            files: resp.files,
            date: resp.date,
            showImage: true,
            showSubmissionDetails: true,
            isRead: false,
            isDelivered: false,
            kind: 'chat',
          };

          const floatingConversationIndex =
            state.floatingConversations.findIndex(
              (conversation) => conversation.id === id,
            );

          if (floatingConversationIndex > -1) {
            if (
              chat.user.id === currentUserProfile?.value?.id &&
              chat.user.type === currentUserProfile.value.type
            ) {
              const chats =
                state.floatingConversations[floatingConversationIndex]
                  .chats || [];

              const pendingSubmissionChatIndex = chats.findIndex(
                (pendingChat) =>
                  pendingChat.kind === 'chat' &&
                  pendingChat.text === chat.text &&
                  pendingChat.hasSubmitted === false,
              );

              if (pendingSubmissionChatIndex > -1) {
                set(
                  state.floatingConversations,
                  `${floatingConversationIndex}.chats.${pendingSubmissionChatIndex}`,
                  {
                    ...chat,
                    hasSubmitted: true,
                  },
                );
              } else {
                addFloatingConversationChats({
                  id,
                  chats: [chat],
                });
              }
            } else {
              addFloatingConversationChats({
                id,
                chats: [chat],
              });
              getSoundFor('newMessageInOpenedConversation')?.play();
            }
          } else if (
            _user.id === user.value.profileId &&
            _user.type === user.value.profileType
          ) {
            const conversation: SingleConversationType = {
              id,
              type: 'single',
              chats: [chat],
              users,
              isFocused: false,
            };

            openFloatingConversation({
              conversation,
            });
          }

          let unreadCount = 0;

          if (
            !(
              chat.user.id === currentUserProfile?.value?.id &&
              chat.user.type === currentUserProfile.value.type
            )
          ) {
            const conversationWindowIndex =
              state.conversationWindows.findIndex(
                (window) => window.id === id,
              );

            if (conversationWindowIndex > -1) {
              unreadCount =
                parseInt(
                  String(
                    get(
                      state.conversationWindows,
                      `${conversationWindowIndex}.unreadCount`,
                      '0',
                    ) || 0,
                  ),
                ) || 0;
            }

            if (floatingConversationIndex === -1) {
              getSoundFor('newMessage')?.play();
            }

            unreadCount += 1;
          }

          const conversationWindow: SingleConversationWindowType = {
            id,
            isGroup: false,
            chat,
            users,
            unreadCount,
          };
          addConversationWindow(conversationWindow);
        },
        replace: false,
      });

      addPushEventHandler({
        pushEventName: 'community-message-chat-action',
        eventId: 'community-message-chat-action',
        handler: async (data: unknown) => {
          type DataType = {
            id: string;
            isRead: boolean;
            isDelivered: boolean;
            user: {
              id: string;
              type: string;
            };
            users: {
              id: string;
              type: string;
            }[];
          };

          const resp = data as DataType;
          await getUser({
            id: resp.user.id,
            type: resp.user.type,
            handler: (data) => {
              let profile = data as ChatProfileType;

              if (!profile) {
                profile = {
                  imageUrl: '',
                  name: 'Anonymous User',
                  isOwner: false,
                  isAdmin: false,
                  type: resp.user.type as 'talent' | 'company',
                  lastSeen: undefined,
                  canView: false,
                  isOnline: true,
                  id: resp.user.id,
                };
              } else {
                profile.canView = true;
              }

              const otherUser = resp.users.find(
                (user) =>
                  !(
                    user.id === currentUserProfile?.value?.id &&
                    user.type === currentUserProfile.value.type
                  ),
              );
              if (!otherUser) return;

              const id = `${otherUser.type}_${otherUser.id}`;
              const floatingConversationIndex =
                state.floatingConversations.findIndex(
                  ({ id: floatingConversationId }) =>
                    floatingConversationId === id,
                );

              if (floatingConversationIndex === -1) {
                addFloatingConversationChatUpdate({
                  floatingConversationId: id,
                  chatId: resp.id,
                  value: {
                    isRead: resp.isRead,
                    isDelivered: resp.isDelivered,
                    user: profile,
                  },
                });
              }

              if (floatingConversationIndex > -1) {
                const chats =
                  state.floatingConversations[
                    floatingConversationIndex
                  ].chats;

                const chatIndex = chats.findIndex(
                  (chat) => chat.id === resp.id,
                );

                if (chatIndex === -1) {
                  addFloatingConversationChatUpdate({
                    floatingConversationId: id,
                    chatId: resp.id,
                    value: {
                      isRead: resp.isRead,
                      isDelivered: resp.isDelivered,
                      user: profile,
                    },
                  });
                }

                if (chatIndex > -1) {
                  chats.forEach((chat, index) => {
                    if (
                      index <= chatIndex &&
                      chat.kind === 'chat' &&
                      chat.user.id === resp.user.id &&
                      chat.user.type === resp.user.type
                    ) {
                      if (resp.isRead) {
                        set(
                          state,
                          `floatingConversations.${floatingConversationIndex}.chats.${chatIndex}.isRead`,
                          true,
                        );

                        set(
                          state,
                          `floatingConversations.${floatingConversationIndex}.chats.${chatIndex}.isDelivered`,
                          true,
                        );
                      }

                      if (resp.isDelivered) {
                        set(
                          state,
                          `floatingConversations.${floatingConversationIndex}.chats.${chatIndex}.isDelivered`,
                          true,
                        );
                      }
                    }
                  });
                }
              }

              const conversationWindowIndex =
                state.conversationWindows.findIndex(
                  (window) => window.id === id,
                );

              if (conversationWindowIndex > -1) {
                if (resp.isRead) {
                  set(
                    state,
                    `conversationWindows.${conversationWindowIndex}.chat.isRead`,
                    true,
                  );

                  set(
                    state,
                    `conversationWindows.${conversationWindowIndex}.chat.isDelivered`,
                    true,
                  );

                  set(
                    state,
                    `conversationWindows.${conversationWindowIndex}.unreadCount`,
                    0,
                  );
                }

                if (resp.isDelivered) {
                  set(
                    state,
                    `conversationWindows.${conversationWindowIndex}.chat.isDelivered`,
                    true,
                  );
                }
              }
            },
          });
        },
        replace: false,
      });
    };

    const getUser = (data: {
      id: string;
      type: string;
      handler: Callback;
    }) => {
      let hasSentUser = false;
      const user = state.users.find(
        (user) => user.id === data.id && user.type === data.type,
      );

      if (user) {
        data.handler(omit(user, ['lastModified']) as ChatProfileType);
        hasSentUser = true;

        if (isAfter(addHours(user.lastModified, 1), new Date())) {
          return;
        }
      }

      request({
        method: 'get',
        key: 'community-message-profile',
        query: {
          user: data,
        },
        body: {},
        handler: (res) => {
          const profile = get(res, 'value', undefined) as
            | ChatProfileType
            | undefined;

          if (!hasSentUser) {
            data.handler(profile);
          }

          if (profile) {
            const userIndex = state.users.findIndex(
              (user) =>
                user.id === data.id && user.type === data.type,
            );

            const userData = {
              ...profile,
              lastModified: new Date(),
            };

            if (userIndex > -1) {
              state.users[userIndex] = userData;
            } else {
              state.users.push(userData);
            }
          }
        },
      });
    };

    const toggleFloatingConversationHidden = (id: string): void => {
      const index = state.floatingConversations.findIndex(
        (savedConversation) => savedConversation.id === id,
      );

      if (index > -1) {
        const isHidden = !get(
          state.floatingConversations,
          `${index}.isHidden`,
          false,
        );

        set(
          state.floatingConversations,
          `${index}.isHidden`,
          isHidden,
        );

        if (isHidden) {
          set(
            state.floatingConversations,
            `${index}.isExpanded`,
            false,
          );
        }
      }
    };

    const toggleFloatingConversationExpanded = (id: string): void => {
      const index = state.floatingConversations.findIndex(
        (savedConversation) => savedConversation.id === id,
      );

      if (index > -1) {
        const isExpanded = !get(
          state.floatingConversations,
          `${index}.isExpanded`,
          false,
        );

        set(
          state.floatingConversations,
          `${index}.isExpanded`,
          isExpanded,
        );

        if (isExpanded) {
          state.floatingConversations =
            state.floatingConversations.map((conversation) => {
              if (conversation.id !== id) {
                conversation.isExpanded = false;
              } else {
                conversation.isHidden = false;
              }

              return conversation;
            });
        }
      }
    };

    const setFloatingConversationFocused = (data: {
      id: string;
      isFocused: boolean;
    }): void => {
      const index = state.floatingConversations.findIndex(
        (savedConversation) => savedConversation.id === data.id,
      );

      if (index > -1) {
        set(
          state.floatingConversations,
          `${index}.isFocused`,
          data.isFocused,
        );

        if (data.isFocused) {
          state.floatingConversations =
            state.floatingConversations.map((conversation) => {
              if (conversation.id !== data.id) {
                conversation.isFocused = false;
              }

              return conversation;
            });
        }
      }
    };

    const closeFloatingConversation = async (data: {
      id: string;
    }): Promise<void> => {
      await removeFloatingConversation(data);
      await refreshFloatingConversations();
    };

    const wsRequest = async (data: {
      method: string;
      key: string;
      query: Record<string, unknown>;
      body: Record<string, unknown>;
      handler: Callback;
    }): Promise<boolean> => {
      const config = useRuntimeConfig();
      const isConnected = ws.value.isConnected(
        config.public.websocketUrl || '',
      );

      const connectedWs = toRaw(
        ws.value.connection(config.public.websocketUrl || ''),
      );

      if (isConnected && connectedWs) {
        let requestId = uniqid('websocket_request_');
        while (has(ws.value.handlers, requestId)) {
          requestId = uniqid('websocket_request_');
        }

        let timeout: ReturnType<typeof setTimeout> | null =
          setTimeout(async () => {
            if (!isNull(timeout)) {
              clearTimeout(timeout);
              timeout = null;
            }

            await ws.value.removeHandler({
              uniqueStr: requestId,
            });

            await ws.value.reconnectWebSocket(
              config.public.websocketUrl || '',
            );

            await restRequest(data);
          }, state.timeoutBeforeSwitchToRest);

        await ws.value.addHandler({
          uniqueStr: requestId,
          handler: (...args: unknown[]) => {
            if (!isNull(timeout)) {
              clearTimeout(timeout);
              timeout = null;
            }

            data.handler(...args);
          },
        });

        try {
          connectedWs.send(
            JSON.stringify({
              requestId,
              data: {
                method: data.method,
                key: data.key,
                body: {
                  query: data.query,
                  body: data.body,
                },
              },
            }),
          );

          return true;
        } catch (e) {
          console.log('error in sending websocket data ===> ', e);
        }
      }

      return false;
    };

    const restRequest = async (data: {
      method: string | undefined;
      key: string;
      query: Record<string, unknown>;
      body: Record<string, unknown>;
      handler: Callback;
    }): Promise<void> => {
      let hasError = false;
      try {
        const recaptchaLoaded = get(
          app,
          '$recaptchaLoaded',
          undefined,
        ) as undefined | (() => Promise<boolean>);

        const executeRecaptcha = get(
          app,
          '$executeRecaptcha',
          undefined,
        ) as undefined | ((reason: string) => Promise<string>);

        if (
          !(
            isDevelopment.value ||
            (isProduction.value &&
              isFunction(recaptchaLoaded) &&
              (await recaptchaLoaded()))
          )
        ) {
          return;
        }

        const token =
          isProduction.value && isFunction(executeRecaptcha)
            ? await executeRecaptcha('post_community_message')
            : 'token';

        let request: ReturnType<typeof useRequest> | undefined;
        const url = `community/message`;
        const query = {
          ...data.query,
          key: data.key,
        };

        if (data.method === 'get') {
          request = useRequest(url, {
            method: 'get',
            query,
          });
        } else {
          request = useRequest(url, {
            method: data.method as unknown as
              | 'get'
              | 'post'
              | 'delete'
              | 'options'
              | 'connect'
              | 'put'
              | 'patch'
              | 'GET'
              | 'POST'
              | 'DELETE'
              | 'OPTIONS'
              | 'CONNECT'
              | 'PUT'
              | 'PATCH'
              | undefined,
            query,
            body: {
              ...(data.body || {}),
              'g-captcha-key': token,
            },
          });
        }

        const resp = await request;

        if (resp && resp.status === 200) {
          const responseData = resp._data as {
            body: unknown;
          };

          if (isFunction(data.handler)) {
            data.handler({
              key: data.key,
              value: responseData.body,
            });
          }
        } else {
          hasError = true;
        }
      } catch {
        hasError = true;
      }

      if (hasError) {
        if (isFunction(data.handler)) {
          data.handler({
            key: data.key,
            value: undefined,
          });
        }
      }
    };

    const request = async (data: {
      method: string;
      key: string;
      query: Record<string, unknown>;
      body: Record<string, unknown>;
      handler: Callback;
    }): Promise<void> => {
      const ws = await wsRequest(data);
      if (!ws) {
        await restRequest(data);
      }
    };

    const setConversationWindowChat = (data: {
      conversationId: string;
      chat: ChatType;
    }): void => {
      const conversationWindowIndex =
        state.conversationWindows.findIndex(
          (conversationWindow) =>
            conversationWindow.id === data.conversationId,
        );

      if (conversationWindowIndex > -1) {
        set(
          state.conversationWindows,
          `[${conversationWindowIndex}].chat`,
          data.chat,
        );
      }
    };

    const initialState = cloneDeep(state);

    const isModuleReady = async () => {
      const nuxt = useNuxtApp();
      const communityMessage = useCommunityMessage(
        nuxt.$pinia as Pinia,
      );

      if (
        communityMessage.$persistedState &&
        communityMessage.$persistedState.isReady
      ) {
        await communityMessage.$persistedState.isReady();
      }

      return true;
    };

    const $reset = async () => {
      const nuxt = useNuxtApp();
      const communityMessage = useCommunityMessage(
        nuxt.$pinia as Pinia,
      );
      await communityMessage.isModuleReady();

      communityMessage.$patch(
        cloneDeep(initialState) as Record<string, unknown>,
      );
    };

    return {
      isModuleReady,
      $reset,
      ...toRefs(state),
      createNewConversation,
      removeFloatingConversationChatUpdates,
      pendingFloatingConversationChatUpdates,
      isWebsocketConnected,
      ws,
      user,
      wsConnection,
      generateConversationId,
      showMessenger,
      addFloatingConversationChats,
      floatingConversationWidth,
      expandedFloatingConversationWidth,
      floatingConversationHeight,
      expandedFloatingConversationHeight,
      floatingConversationMargin,
      remainingWidth,
      allowedFloatingConversationCount,
      hasConversationWindows,
      restRequest,
      wsRequest,
      request,
      setFloatingConversationChats,
      setFloatingConversationFocused,
      toggleFloatingConversationExpanded,
      toggleFloatingConversationHidden,
      getUser,
      initWs,
      addFloatingConversationChatUpdate,
      loadAndRefreshOpenConversations,
      openFloatingConversation,
      loadGroupConversationChats,
      loadSingleConversationChats,
      loadConversations,
      handleWidthChange,
      refreshFloatingConversations,
      removePushEventHandler,
      addPushEventHandler,
      removeFloatingConversationChat,
      removeFloatingConversation,
      addFloatingConversation,
      removeConversationWindow,
      addConversationWindow,
      computeMaxWidth,
      setState,
      floatingConversationChats,
      floatingConversationExpandedState,
      floatingConversationHiddenState,
      closeFloatingConversation,
      handlersForPushEvent,
      currentUserProfile,
      hasFloatingConversations,
      lastOpenedConversation,
      setConversationWindowChat,
    };
  },
  {
    persistedState: {
      persist: false,
      overwrite: false,
      merge(state /*, savedState */) {
        return state;
      },
      // includePaths: [
      //   'token',
      //   'continue',
      //   'resetPasswordTokenCreatedDate',
      //   'hasForgottenPassword',
      //   'resetPasswordTokenExpiryDate',
      //   'forgotPasswordUerPhoneNumber',
      //   'forgotPasswordToken',
      // ],
    },
  },
);
