import { defineStore, getActivePinia } from 'pinia';

import { FetchOptions } from 'ofetch';
import ucwords from 'locutus/php/strings/ucwords';
import {
  cloneDeep,
  has,
  isEmpty,
  set,
  each,
  isEqual,
  isBoolean,
  capitalize,
  isArray,
  orderBy,
  findLast,
  isString,
  isObject,
  get,
  pick,
  uniq,
  unset,
  omit,
  uniqBy,
  find,
  includes,
  round,
  forEach,
} from 'lodash-es';
import { reactive, Ref, toRaw, triggerRef, watch } from 'vue';
import isEmail from 'validator/lib/isEmail';
import {
  isValid as isDateValid,
  format as formatDate,
} from 'date-fns';
import isURL from 'validator/lib/isURL';
import { useRouter } from 'vue-router';
import until from 'until-promise';
import normalizeUrl from 'normalize-url';
import { FilePurpose } from '@/support/uploader';
import { FileType } from '@/types/attachment';
import {
  formatAmount,
  formatAmountRange,
  setObjDataDeepForStore,
} from '@/utils/helpers';
import {
  TalentProfileEditType,
  TalentProfileType,
  WorkHistoryType,
} from '@/types/talentProfile';

type Skill = {
  id: string;
  name: string;
  isVerified?: boolean | undefined;
};

interface State {
  newData: Partial<TalentProfileEditType> | Record<string, never>;
  oldData: Partial<TalentProfileType> | Record<string, never>;
  isSaving: boolean;
  hasSentVerificationMails: boolean;
  isCheckingIfEmailIsValid: boolean;
  hasLoadedOldData: boolean;
  asyncIsEmailValid: boolean | null;
  removedWorkHistoryIds: string[];
  removedFileIds: string[];
  fileRecords: FileType[];
  serverProfileReloadKey: number;
  serverLastUpdateMs: number;
  isLoadingProfileFromServer: boolean;
}

export const useTalentProfile = defineStore(
  'talent_profile',
  () => {
    const state = reactive<State>({
      newData: {},
      oldData: {},
      isSaving: false,
      hasSentVerificationMails: false,
      isCheckingIfEmailIsValid: false,
      hasLoadedOldData: false,
      asyncIsEmailValid: false,
      removedWorkHistoryIds: [],
      removedFileIds: [],
      fileRecords: [],
      serverProfileReloadKey: Date.now(),
      serverLastUpdateMs: Date.now(),
      isLoadingProfileFromServer: false,
    });

    const initialState = cloneDeep(state);

    const profileCompletionPercentage = computed<number>(() => {
      return round(
        parseFloat(
          String(get(state.oldData, 'percentageCompletion', '0')),
        ) || 0,
        2,
      );
    });

    const hasData = computed(() => {
      const arrayValues = [
        'isSaving',
        'hasSentVerificationMails',
        'isCheckingIfEmailIsValid',
        'hasLoadedOldData',
        'asyncIsEmailValid',
      ];

      return find(
        [
          'newData',
          'oldData',
          'isSaving',
          'hasSentVerificationMails',
          'isCheckingIfEmailIsValid',
          'hasLoadedOldData',
          'asyncIsEmailValid',
          'removedWorkHistoryIds',
          'removedFileIds',
          'fileRecords',
        ],
        (key) => {
          const value = get(this, key, '') as string;
          if (includes(arrayValues, key)) {
            return !(isBoolean(value) && !value);
          }

          return !isEmpty(value);
        },
      );
    });

    const mode = computed(() => {
      const user = useUser();
      if (user.userType === 'company-user') {
        return 'create-company-talent';
      } else if (user.userType === 'candidate') {
        return 'edit-profile';
      } else {
        return 'create-profile';
      }
    });

    const availabilityList = computed(() => {
      return [
        {
          name: 'Actively Seeking',
          isAvailable: toRaw(state.newData.isActivelySeeking),
          ref: 'isActivelySeeking',
        },
        {
          name: 'Passively Seeking',
          isAvailable: toRaw(state.newData.isPassivelySeeking),
          ref: 'isPassivelySeeking',
        },
        {
          name: 'Not Available',
          isAvailable: toRaw(state.newData.isAvailable),
          ref: 'isAvailable',
        },
        {
          name: 'Gigs Only',
          isAvailable: toRaw(state.newData.onlyGigs),
          ref: 'onlyGigs',
        },
        {
          name: 'Projects Only',
          isAvailable: toRaw(state.newData.onlyProjects),
          ref: 'onlyProjects',
        },
        {
          name: 'Part-time Only',
          isAvailable: toRaw(state.newData.onlyPartTime),
          ref: 'onlyPartTime',
        },
      ];
    });

    const interestList = computed(() => {
      return [
        {
          name: 'Short-term Contract',
          isInterested: toRaw(
            state.newData.interestedInShortTermContract,
          ),
          ref: 'interestedInShortTermContract',
        },
        {
          name: 'Full Time Salaried',
          isInterested: toRaw(
            state.newData.interestedInFullTimeSalaried,
          ),
          ref: 'interestedInFullTimeSalaried',
        },
        {
          name: 'Freelance',
          isInterested: toRaw(state.newData.interestedInFreelance),
          ref: 'interestedInFreelance',
        },
        {
          name: 'Remote',
          isInterested: toRaw(state.newData.interestedInRemote),
          ref: 'interestedInRemote',
        },
        {
          name: 'On Site',
          isInterested: toRaw(state.newData.interestedInOnSite),
          ref: 'interestedInOnSite',
        },
      ];
    });

    const hasOldData = computed<boolean>(() => {
      return !isEmpty(state.oldData);
    });

    const hasNewData = computed<boolean>(() => {
      return !isEmpty(state.newData);
    });

    const isInEditMode = computed<boolean>(() => {
      return mode.value === 'edit-profile';
    });

    const isInCompanyCreateMode = computed<boolean>(() => {
      return mode.value === 'create-company-talent';
    });

    const isInCandidateCreateMode = computed<boolean>(() => {
      return mode.value === 'create-profile';
    });

    const isInCreateMode = computed<boolean>(() => {
      return (
        isInCompanyCreateMode.value || isInCandidateCreateMode.value
      );
    });

    const uniqueId = computed<string>(() => {
      return state.oldData.uniqueId || '';
    });

    const name = computed<string>(() => {
      return ucwords(
        `${state.oldData.firstName} ${state.oldData.lastName}`,
      );
    });

    const isNameValid = computed<string | boolean | undefined>(() => {
      return (
        state.newData.firstName &&
        state.newData.lastName &&
        !(
          isInEditMode.value &&
          state.newData.firstName === state.oldData.firstName &&
          state.newData.lastName === state.oldData.lastName
        )
      );
    });

    const gender = computed<string>(() => {
      return ucwords(state.oldData.gender || '');
    });

    const isGenderValid = computed<boolean>(() => {
      return (
        !!state.newData.gender &&
        !(
          isInEditMode.value &&
          String(state.newData.gender.value) ===
            String(state.oldData.genderId)
        )
      );
    });

    const headline = computed<string>(() => {
      return ucwords(state.oldData.headline || '');
    });

    const isHeadlineValid = computed<boolean>(() => {
      return (
        !!state.newData.headline &&
        !(
          isInEditMode.value &&
          state.newData.headline === state.oldData.headline
        )
      );
    });

    const preferedRoles = computed<string[]>(() => {
      return (state.oldData.preferedRoles || [])
        .map((role) => ucwords(role.name))
        .reduce(
          (prevVal, currVal) =>
            prevVal ? `${prevVal}, ${currVal}` : currVal,
          '',
        );
    });

    const isPreferedRolesValid = computed<boolean>(() => {
      const profilePreferedRoles = state.oldData.preferedRoles || [];
      const newProfilePreferedRoles = state.newData.preferedRoles;

      return !(
        isInEditMode.value &&
        isEqual(profilePreferedRoles, newProfilePreferedRoles)
      );
    });

    const email = computed<string>(() => {
      return ucwords(state.oldData.email || '');
    });

    const isEmailValid = computed<boolean>(() => {
      const isEmailValid = (Boolean(state.newData.email) &&
        isEmail(state.newData.email as string) &&
        !(
          isInEditMode.value && state.newData.email === email.value
        )) as boolean;

      let checkAsyncEmailValid = true;
      if (isInCompanyCreateMode.value) {
        if (state.isCheckingIfEmailIsValid) {
          checkAsyncEmailValid = false;
        } else {
          checkAsyncEmailValid = isBoolean(state.asyncIsEmailValid)
            ? state.asyncIsEmailValid
            : false;
        }
      }

      return isEmailValid && checkAsyncEmailValid;
    });

    const isOldAndNewEmailValid = computed<
      string | boolean | undefined
    >(() => {
      return (
        state.newData.oldEmail &&
        isEmail(state.newData.oldEmail) &&
        state.newData.newEmail &&
        isEmail(state.newData.newEmail) &&
        state.newData.oldEmail !== state.newData.newEmail &&
        state.newData.newEmail !== email.value
      );
    });

    const oldProfileFiles = computed<Partial<TalentProfileType>[]>(
      () => {
        if (state.oldData.files) {
          return state.oldData.files;
        }

        return [];
      },
    );

    const isEmailTokensValid = computed<boolean>(() => {
      return (
        !!state.newData.oldEmailToken && !!state.newData.newEmailToken
      );
    });

    const location = computed<string>(() => {
      let locationString = '';

      if (state.oldData.country) {
        locationString = capitalize(
          String(state.oldData.country).trim(),
        );
      }

      if (state.oldData.city) {
        const city = capitalize(String(state.oldData.city).trim());

        if (locationString) {
          locationString = `${locationString}, ${city}`;
        } else {
          locationString = city;
        }
      }

      return locationString;
    });

    const isLocationValid = computed<boolean | undefined>(() => {
      return (
        state.newData.city &&
        state.newData.country &&
        !(
          isInEditMode.value &&
          state.newData.city.value === state.oldData.cityId &&
          state.newData.country.value === state.oldData.countryId
        )
      );
    });

    const minimumAvailableWeeklyHours = computed<number>(() => {
      return parseInt(
        String(state.oldData.minAvailableWeeklyHours || '0'),
        10,
      );
    });

    const maximumAvailableWeeklyHours = computed<number>(() => {
      return parseInt(
        String(state.oldData.maxAvailableWeeklyHours || '0'),
        10,
      );
    });

    const availableWeeklyHours = computed<string>(() => {
      if (
        minimumAvailableWeeklyHours.value ===
        maximumAvailableWeeklyHours.value
      ) {
        return `${minimumAvailableWeeklyHours.value} hrs/week`;
      }

      return `${minimumAvailableWeeklyHours.value} hrs/week - ${maximumAvailableWeeklyHours.value} hrs/week`;
    });

    const isWeeklyAvailableHoursValid = computed<
      boolean | undefined | number | null
    >(() => {
      return (
        state.newData.maxAvailableWeeklyHours &&
        state.newData.minAvailableWeeklyHours &&
        state.newData.maxAvailableWeeklyHours >=
          state.newData.minAvailableWeeklyHours
      );
    });

    const hourlyRate = computed<string>(() => {
      const min =
        parseFloat(String(state.oldData.minHourlyRate || '0')) || 0;
      const max =
        parseFloat(String(state.oldData.maxHourlyRate || '0')) || 0;

      if (min === max) {
        const rate = formatAmount({
          amount: min,
          currency: 'USD',
        });

        return rate.value;
      }

      const rate = formatAmountRange({
        range: {
          min,
          max,
        },
        currency: 'USD',
      });

      return rate.value;
    });

    const isHourlyRateValid = computed<
      boolean | undefined | number | null
    >(() => {
      return (
        state.newData.maxHourlyRate &&
        state.newData.minHourlyRate &&
        state.newData.maxHourlyRate >= state.newData.minHourlyRate
      );
    });

    const monthlyRate = computed<string>(() => {
      const min =
        parseFloat(String(state.oldData.minMonthlyRate || '0')) || 0;
      const max =
        parseFloat(String(state.oldData.maxMonthlyRate || '0')) || 0;

      if (min === max) {
        const rate = formatAmount({
          amount: min,
          currency: 'USD',
        });

        return rate.value;
      }

      const rate = formatAmountRange({
        range: {
          min,
          max,
        },
        currency: 'USD',
      });

      return rate.value;
    });

    const isMonthlyRateValid = computed<
      number | boolean | null | undefined
    >(() => {
      return (
        state.newData.maxMonthlyRate &&
        state.newData.minMonthlyRate &&
        state.newData.maxMonthlyRate >= state.newData.minMonthlyRate
      );
    });

    const yearlyRate = computed<string>(() => {
      const min =
        parseFloat(String(state.oldData.minYearlyRate || '0')) || 0;
      const max =
        parseFloat(String(state.oldData.maxYearlyRate || '0')) || 0;

      if (min === max) {
        const rate = formatAmount({
          amount: min,
          currency: 'USD',
        });

        return rate.value;
      }

      const rate = formatAmountRange({
        range: {
          min,
          max,
        },
        currency: 'USD',
      });

      return rate.value;
    });

    const isYearlyRateValid = computed<
      number | boolean | null | undefined
    >(() => {
      return (
        state.newData.maxYearlyRate &&
        state.newData.minYearlyRate &&
        state.newData.maxYearlyRate >= state.newData.minYearlyRate
      );
    });

    const phoneNumber = computed<string>(() => {
      if (state.oldData && state.oldData.phoneNumber) {
        let phoneString = '';

        if (state.oldData.phoneCode) {
          phoneString = state.oldData.phoneCode;
        }

        return phoneString
          ? `${phoneString} ${state.oldData.phoneNumber}`
          : state.oldData.phoneNumber;
      }

      return '';
    });

    const isPhoneNumberValid = computed<
      string | number | boolean | undefined
    >(() => {
      return (
        state.newData.phoneCode &&
        state.newData.phoneNumber &&
        !(
          isInEditMode.value &&
          String(state.newData.phoneCode.value) ===
            String(state.oldData.phoneCodeId) &&
          state.newData.phoneNumber === state.oldData.phoneNumber
        )
      );
    });

    const skills = computed<Skill[]>(() => {
      let skills = state.oldData.skills || [];
      if (!isArray(skills)) {
        skills = [];
      }

      return skills;
    });

    const profileNamedSkills = computed<string[]>(() => {
      return skills.value.map((skill) => ucwords(skill.name));
    });

    const verifiedNamedSkills = computed<string[]>(() => {
      return skills.value
        .map((skill) => skill.isVerified && ucwords(skill.name))
        .filter((skill) => !!skill);
    });

    const historyList = computed(() => {
      if (
        state.oldData.workHistory &&
        isArray(state.oldData.workHistory)
      ) {
        return orderBy(
          state.oldData.workHistory,
          [
            function (val) {
              const date = new Date(val.startDate);
              if (isDateValid(date)) return date.getTime();
              return 0;
            },
          ],
          ['desc'],
        ).filter(
          (history) =>
            !state.removedWorkHistoryIds.includes(history.id),
        );
      }

      return [];
    });

    const isDeletingWorkHistory = computed<any>(() => {
      return (history: WorkHistoryType) => {
        return state.removedWorkHistoryIds.includes(history.id);
      };
    });

    const oldFiles = computed<FileType[]>(() => {
      return state.oldData.files || [];
    });

    const files = computed<any>(() => {
      let fileStack: FileType[] = [];
      if (isInCreateMode.value) {
        fileStack = state.fileRecords;
      } else {
        fileStack = uniqBy(
          oldFiles.value.concat(state.fileRecords),
          'id',
        );
      }

      return fileStack;
    });

    const resumes = computed<[]>(() => {
      return files.value.filter(
        (file: { purpose: FilePurpose }) =>
          file.purpose === FilePurpose.TALENT_PROFILE_RESUME,
      );
    });

    const hasResume = computed<boolean>(() => {
      return resumes.value.length > 0;
    });

    const otherDocuments = computed<[]>(() => {
      return files.value.filter(
        (file: { purpose: FilePurpose }) =>
          file.purpose === FilePurpose.TALENT_PROFILE_DOCUMENTS,
      );
    });

    const profileImageUrl = computed<string>(() => {
      const file = findLast(
        files.value,
        (file) => file.purpose === FilePurpose.TALENT_PROFILE_PICTURE,
      );

      return file?.url || '';
    });

    const videoLink = computed<string>(() => {
      return state.oldData.videoLink || '';
    });

    const isVideoLinkValid = computed<string | boolean | undefined>(
      () => {
        return (
          state.newData.videoLink &&
          profileVideoUrl.value !== state.newData.videoLink &&
          isURL(state.newData.videoLink, {
            require_protocol: false,
            protocols: ['http', 'https'],
            require_valid_protocol: true,
          }) &&
          !(
            isInEditMode.value &&
            state.newData.videoLink === state.oldData.videoLink
          )
        );
      },
    );

    const profileVideoUrl = computed<string>(() => {
      if (!isInCreateMode.value) {
        if (
          state.oldData.videoLink &&
          isString(state.oldData.videoLink) &&
          isURL(state.oldData.videoLink, {
            require_protocol: false,
            require_valid_protocol: true,
            protocols: ['http', 'https'],
          })
        ) {
          return state.oldData.videoLink;
        }
      }

      const profileVideo = findLast(
        files.value,
        (file) => file.purpose === FilePurpose.TALENT_PROFILE_VIDEO,
      );

      if (profileVideo && profileVideo.url) {
        return profileVideo.url;
      }

      const profileInterviewVideo = findLast(
        files.value,
        (file) =>
          file.purpose === FilePurpose.TALENT_PROFILE_INTERVIEW_VIDEO,
      );

      if (profileInterviewVideo && profileInterviewVideo.url) {
        return profileInterviewVideo.url;
      }

      return '';
    });

    const profileVideoMimeType = computed<string>(() => {
      if (profileVideoUrl.value) {
        if (profileVideoUrl.value.includes('youtube.com')) {
          return 'video/youtube';
        } else if (profileVideoUrl.value.includes('vimeo.com')) {
          return 'video/vimeo';
        } else if (
          profileVideoUrl.value.includes('amazonaws.com') ||
          profileVideoUrl.value.endsWith('.webm') ||
          profileVideoUrl.value.endsWith('.mkv') ||
          profileVideoUrl.value.endsWith('.mp4')
        ) {
          return 'video/webm';
        }
      }

      return 'video/mp4';
    });

    const hasVideo = computed<boolean>(() => {
      return !!profileVideoUrl.value;
    });

    const links = computed(() => {
      const links = state.oldData.links;
      if (!isArray(links)) {
        return [];
      }

      return links
        .filter((link) => !!link.link)
        .map((link) => {
          const linkName = link.name.toLowerCase();
          return {
            ...link,
            link: link.link
              ? normalizeUrl(link.link, {
                  defaultProtocol: 'https',
                  normalizeProtocol: true,
                  forceHttps: true,
                })
              : '',
            icon: getSocialIcon.value(linkName),
          };
        });
    });

    const biography = computed<string>(() => {
      return state.oldData.biography || '';
    });

    const isBiographyValid = computed<string | boolean | undefined>(
      () => {
        return (
          state.newData.biography &&
          !(
            isInEditMode.value &&
            state.newData.biography === state.oldData.biography
          )
        );
      },
    );

    const error = computed<string>(() => {
      if (!isNameValid.value) {
        return 'Please input a valid first name and last name';
      }

      if (isInCompanyCreateMode.value) {
        if (!isEmailValid.value) {
          if (!state.asyncIsEmailValid && isEmail(email.value)) {
            return 'Email address already exist';
          }

          return 'Please input a valid candidate email address';
        }
      }

      if (!isGenderValid.value) {
        return 'Please select a valid gender';
      }

      if (!isLocationValid.value) {
        return 'Please input a valid location data by selecting or inputing country and city';
      }

      if (!isHeadlineValid.value) {
        return 'Please input a or select a valid headline';
      }

      if (!isPhoneNumberValid.value) {
        return 'Contact information is invalid. Please select a valid phone code and input a valid phone number';
      }

      if ((skills.value || []).length < 4) {
        return 'A minimum of four (4) skills is required';
      }

      if (!hasResume.value) {
        return 'Please upload at least one resume.';
      }

      return '';
    });

    const getFormattedDataFor = computed(() => {
      return (
        name: string,
        options: Record<string | number | symbol, unknown> = {},
      ) => {
        const obj: Record<string, unknown> = {};
        let trackedKey = name;
        let isValid = false;
        let invalidInputMessage = '';
        let updatedProfileData: Partial<TalentProfileType> = {};

        switch (name) {
          case 'name':
            isValid = !!isNameValid.value;
            invalidInputMessage = 'enter a valid name';
            obj.firstName = state.newData.firstName;
            obj.lastName = state.newData.lastName;
            break;
          case 'gender':
            isValid = !!isGenderValid.value;
            invalidInputMessage = 'enter a valid gender';
            obj.genderId = get(state.newData, 'gender.value');
            updatedProfileData.genderId = obj.genderId as string;
            updatedProfileData.gender = get(
              state.newData,
              'gender.label',
            );
            break;
          case 'headline':
            isValid = !!isHeadlineValid.value;
            invalidInputMessage = 'enter a valid headline';
            obj.headline = get(state.newData, 'headline');
            break;
          case 'preferedRoles':
            isValid = !!isPreferedRolesValid.value;
            invalidInputMessage = 'enter valid roles';
            obj.preferedRoles = (state.newData.preferedRoles || [])
              .map((role) => get(role, 'value'))
              .filter((role) => !!role);
            updatedProfileData.preferedRoles = (
              state.newData.preferedRoles || []
            )
              .filter((role) => !!role.value && !!role.label)
              .map((role) => ({
                id: get(role, 'value'),
                name: get(role, 'label'),
              }));
            break;
          case 'email':
            isValid = !!isEmailValid.value;
            invalidInputMessage = 'enter a valid email';
            obj.email = get(state.newData, 'email');
            break;
          case 'action_send_verification_emails':
            trackedKey = 'email';
            isValid = !!isOldAndNewEmailValid.value;
            invalidInputMessage = 'invalid email';
            obj.oldEmail = get(state.newData, 'oldEmail');
            obj.newEmail = get(state.newData, 'newEmail');
            break;
          case 'action_verify_email_tokens':
            trackedKey = 'email';
            isValid = !!isEmailTokensValid.value;
            invalidInputMessage = 'invalid email token';
            obj.oldEmailToken = get(state.newData, 'oldEmailToken');
            obj.newEmailToken = get(state.newData, 'newEmailToken');
            break;
          case 'phoneNumber':
            isValid = !!isPhoneNumberValid.value;
            invalidInputMessage = 'enter a valid phone number';
            obj.phoneCodeId = get(state.newData, 'phoneCode.value');
            obj.phoneNumber = get(state.newData, 'phoneNumber');
            updatedProfileData.phoneCodeId = get(
              state.newData,
              'phoneCode.value',
            );
            updatedProfileData.phoneCode = get(
              state.newData,
              'phoneCode.label',
            );
            updatedProfileData.phoneNumber = (
              get(state.newData, 'phoneNumber') as string
            ).replaceAll(' ', '');
            break;
          case 'location':
            isValid = !!isLocationValid.value;
            invalidInputMessage = 'enter a valid location';
            obj.countryId = get(state.newData, 'country.value');
            obj.cityId = String(get(state.newData, 'city.value', ''));
            updatedProfileData.countryId = get(
              state.newData,
              'country.value',
            );
            updatedProfileData.country = get(
              state.newData,
              'country.label',
            );
            updatedProfileData.cityId = get(
              state.newData,
              'city.value',
            );
            updatedProfileData.city = get(
              state.newData,
              'city.label',
            );
            break;
          case 'isActivelySeeking':
            obj.isActivelySeeking = !!state.newData.isActivelySeeking;
            break;
          case 'isPassivelySeeking':
            obj.isPassivelySeeking =
              !!state.newData.isPassivelySeeking;
            break;
          case 'isAvailable':
            obj.isAvailable = !!state.newData.isAvailable;
            break;
          case 'onlyGigs':
            obj.onlyGigs = !!state.newData.onlyGigs;
            break;
          case 'onlyProjects':
            obj.onlyProjects = !!state.newData.onlyProjects;
            break;
          case 'onlyPartTime':
            obj.onlyPartTime = !!state.newData.onlyPartTime;
            break;
          case 'interestedInShortTermContract':
            obj.interestedInShortTermContract =
              !!state.newData.interestedInShortTermContract;
            break;
          case 'interestedInFullTimeSalaried':
            obj.interestedInFullTimeSalaried =
              !!state.newData.interestedInFullTimeSalaried;
            break;
          case 'interestedInFreelance':
            obj.interestedInFreelance =
              !!state.newData.interestedInFreelance;
            break;
          case 'interestedInRemote':
            obj.interestedInRemote =
              !!state.newData.interestedInRemote;
            break;
          case 'interestedInOnSite':
            obj.interestedInOnSite =
              !!state.newData.interestedInOnSite;
            break;
          case 'weeklyHoursAvailable':
            isValid = !!isWeeklyAvailableHoursValid.value;
            invalidInputMessage = 'invalid weekly hours';
            obj.minAvailableWeeklyHours =
              state.newData.minAvailableWeeklyHours;
            obj.maxAvailableWeeklyHours =
              state.newData.maxAvailableWeeklyHours;
            break;
          case 'hourlyRate':
            isValid = !!isHourlyRateValid.value;
            invalidInputMessage = 'invalid hourly rate';
            obj.minHourlyRate = state.newData.minHourlyRate;
            obj.maxHourlyRate = state.newData.maxHourlyRate;
            break;
          case 'monthlyRate':
            isValid = !!isMonthlyRateValid.value;
            invalidInputMessage = 'invalid monthly rate';
            obj.minMonthlyRate = state.newData.minMonthlyRate;
            obj.maxMonthlyRate = state.newData.maxMonthlyRate;
            break;
          case 'yearlyRate':
            isValid = !!isYearlyRateValid.value;
            invalidInputMessage = 'invalid yearly rate';
            obj.minYearlyRate = state.newData.minYearlyRate;
            obj.maxYearlyRate = state.newData.maxYearlyRate;
            break;
          case 'skills': {
            const skills = state.newData.skills || [];

            isValid = skills.length >= 4;
            invalidInputMessage = 'minimum of 4 skills required';
            obj.skills = skills
              .map((skill) => get(skill, 'value'))
              .filter((skill) => !!skill);

            updatedProfileData.skills = skills
              .filter((skill) => !!skill)
              .map((skill) => ({
                id: skill.value,
                name: skill.label,
                isVerified: !!get(skill, 'isVerified', false),
              }));
            break;
          }
          case 'work-history': {
            const removedId = get(options, 'removedId', undefined);

            const workHistory = state.newData.workHistory || [];
            isValid = isArray(workHistory);
            invalidInputMessage = 'invalid work history';
            obj.workHistory = workHistory
              .filter((history) => history.id !== removedId)
              .map((history) => {
                const val: Record<string, unknown> = {
                  ...pick(history, [
                    'startDate',
                    'endDate',
                    'isCurrent',
                    'workSummary',
                  ]),
                  jobTitleId: String(history.jobTitle.id || ''),
                };

                if (history.id) {
                  val.id = history.id;
                }

                if (get(history, 'isNew')) {
                  val.isNew = true;
                  unset(val, 'id');
                } else {
                  val.isNew = false;
                }

                val.companyId = String(history.company.id);

                if (history.roles.length > 0) {
                  val.otherRoleIds = history.roles.map((role) =>
                    String(role.id),
                  );
                }

                if (history.skills.length > 0) {
                  val.skillIds = history.skills.map((skill) =>
                    String(skill.id),
                  );
                }

                return val;
              });

            updatedProfileData.workHistory = workHistory.filter(
              (history) => history.id !== removedId,
            );
            break;
          }
          case 'links': {
            const links = state.newData.links || [];
            isValid = isArray(links);
            invalidInputMessage = 'invalid links';
            obj.links = cloneDeep(links);
            break;
          }
          case 'files': {
            const removedId = get(options, 'removedId', undefined);
            const fileIds = state.newData.fileIds || [];

            isValid = true;
            obj.fileIds = uniq(
              state.fileRecords
                .map((file) => file.id)
                .concat(cloneDeep(fileIds))
                .filter((fileId) => fileId !== removedId),
            );
            break;
          }
          case 'biography':
            isValid = !!isBiographyValid.value;
            invalidInputMessage = 'enter biography';
            obj.biography = state.newData.biography;
            break;
          case 'videoLink':
            isValid = !!isVideoLinkValid.value;
            invalidInputMessage = 'invalid video link';
            obj.videoLink = state.newData.videoLink;
            break;
        }

        if (
          [
            'isActivelySeeking',
            'isPassivelySeeking',
            'isAvailable',
            'onlyGigs',
            'onlyProjects',
            'onlyPartTime',
            'interestedInShortTermContract',
            'interestedInFullTimeSalaried',
            'interestedInFreelance',
            'interestedInRemote',
            'interestedInOnSite',
          ].includes(name)
        ) {
          isValid = true;
        }

        if (isValid && isEmpty(updatedProfileData)) {
          if (
            includes(
              [
                'name',
                'weeklyHoursAvailable',
                'hourlyRate',
                'monthlyRate',
                'yearlyRate',
                'links',
              ],
              name,
            )
          ) {
            updatedProfileData = obj;
          } else if (has(state.newData, name)) {
            set(updatedProfileData, name, get(state.newData, name));
          }
        }
        if (isValid) invalidInputMessage = '';

        return {
          talentProfileRecord: obj,
          isValid,
          invalidInputMessage,
          trackedKey,
          updatedProfileData,
        };
      };
    });

    const formattedData = computed(() => {
      const fieldsToCheck = [
        'name',
        'gender',
        'headline',
        'preferedRoles',
        'email',
        'phoneNumber',
        'location',
        'isActivelySeeking',
        'isPassivelySeeking',
        'isAvailable',
        'onlyGigs',
        'onlyProjects',
        'onlyPartTime',
        'interestedInShortTermContract',
        'interestedInFullTimeSalaried',
        'interestedInFreelance',
        'interestedInRemote',
        'interestedInOnSite',
        'weeklyHoursAvailable',
        'hourlyRate',
        'monthlyRate',
        'yearlyRate',
        'skills',
        'work-history',
        'links',
        'files',
        'biography',
        'videoLink',
      ];

      let talentProfileData: Record<string, unknown> = {};
      const trackedKeys: string[] = [];
      for (const fieldToCheck of fieldsToCheck) {
        const { talentProfileRecord, isValid, trackedKey } =
          getFormattedDataFor.value(fieldToCheck);

        trackedKeys.push(trackedKey);
        if (!isValid) {
          continue;
        }

        talentProfileData = {
          ...talentProfileData,
          ...talentProfileRecord,
        };
      }

      return {
        talentProfile: talentProfileData,
        trackedKeys,
      };
    });

    const reloadProfileFromServer = (): void => {
      state.serverProfileReloadKey = Date.now();
    };

    const clearNewData = (): void => {
      state.newData = {};
    };

    const setNewData = (data: Record<string, unknown>): void => {
      let destObj = state.newData as Record<string, unknown>;
      if (!isObject(destObj)) {
        state.newData = reactive({});
        destObj = state.newData;
      }
      setObjDataDeepForStore(data, destObj);
      triggerRef(state.newData as Ref);
    };

    const setEmail = (email: string) => {
      if (!isObject(state.newData)) {
        state.newData = reactive({});
      }

      set(state.newData, 'email', email);
    };

    const setOldData = (data: Record<string, unknown>): void => {
      state.oldData = reactive(cloneDeep(data));

      each(
        omit(state.oldData, [
          'files',
          'preferedRoles',
          'genderId',
          'gender',
          'countryId',
          'country',
          'cityId',
          'city',
          'phoneCodeId',
          'phoneCode',
          'skills',
        ]),
        (value, key) => {
          set(state.newData, key, value);
        },
      );

      if (
        state.oldData.preferedRoles &&
        state.oldData.preferedRoles.length
      ) {
        set(
          state.newData,
          'preferedRoles',
          state.oldData.preferedRoles.map((role) => ({
            label: ucwords(role.name),
            value: String(role.id),
          })) || [],
        );
      }

      if (state.oldData.gender && state.oldData.genderId) {
        set(state.newData, 'gender', {
          label: ucwords(state.oldData.gender),
          value: state.oldData.genderId,
        });
      }

      if (state.oldData.country && state.oldData.countryId) {
        set(state.newData, 'country', {
          label: ucwords(state.oldData.country),
          value: state.oldData.countryId,
        });
      }

      if (
        state.oldData.cityId &&
        state.oldData.city &&
        state.oldData.countryId &&
        state.oldData.country
      ) {
        set(state.newData, 'city', {
          label: ucwords(state.oldData.city),
          value: state.oldData.cityId,
          country: {
            id: state.oldData.countryId,
            name: state.oldData.country,
          },
        });
      }

      if (state.oldData.phoneCodeId && state.oldData.phoneCode) {
        set(state.newData, 'phoneCode', {
          label: ucwords(state.oldData.phoneCode),
          value: state.oldData.phoneCodeId,
        });
      }

      if (state.oldData.skills && state.oldData.skills.length) {
        set(
          state.newData,
          'skills',
          state.oldData.skills.map((skill) => ({
            label: ucwords(skill.name),
            value: String(skill.id),
          })),
        );
      }

      if (isArray(state.oldData.files)) {
        each(state.oldData.files, (file) => {
          if (!isArray(state.newData.fileIds)) {
            state.newData.fileIds = [];
          }

          if (!state.newData.fileIds.includes(file.id)) {
            state.newData.fileIds.push(file.id);
          }
        });

        state.newData.fileIds = uniq(state.newData.fileIds);
      }
    };

    const setState = (data: Partial<State>): void => {
      each(data, (value, key) => {
        if (has(state, key)) {
          set(state, key, value);
        }
      });
    };

    const doesEmailExists = async (
      email: string,
    ): Promise<boolean> => {
      if (!isInCompanyCreateMode.value) {
        return false;
      }

      try {
        const options: FetchOptions = {
          params: {
            email,
            for: 'talent',
          },
        };

        const response = await useRequest('profile/check-email', {
          ...options,
          method: 'GET',
        });

        if (response.status === 204) {
          return false;
        }

        return true;
      } catch (e) {
        return true;
      }
    };

    const loadProfileFromServer = async (
      savedData: Partial<TalentProfileType> = {},
    ) => {
      if (state.isLoadingProfileFromServer) {
        return 'loading';
      }

      if (!isInEditMode.value) {
        return 'not-allowed';
      }

      let resp = '';
      setState({ isLoadingProfileFromServer: true });

      try {
        const res = await useRequest('profile');

        if (res.status === 200) {
          const data = res._data as {
            body: TalentProfileType;
          };

          setOldData({
            ...(data.body as TalentProfileType),
            ...savedData,
          });

          setState({
            serverLastUpdateMs: Date.now(),
          });
        }
      } catch (e) {
        if (isDevelopment.value || inStaging.value) {
          console.log(
            'error loading profile for editing users ===>',
            e,
          );

          console.dir(e);
        }

        resp = 'error';
      }

      setState({
        isLoadingProfileFromServer: false,
      });

      return resp;
    };

    const getLinkIcon = (name: string): string | false => {
      if (['linkedin', 'github'].includes(name)) {
        return getSocialIcon.value(name);
      }

      return false;
    };

    const workHistoryRangeFor = (
      history: WorkHistoryType,
    ): string => {
      let range = '';
      const startDate = new Date(history.startDate);

      if (isDateValid(startDate)) {
        range += formatDate(startDate, 'MMM yyyy');

        if (range) {
          const isCurrent = history.isCurrent;

          if (isCurrent) {
            range += ' - Present';
          } else if (history.endDate) {
            const endDate = new Date(history.endDate);

            if (isDateValid(endDate)) {
              range += ` - ${formatDate(endDate, 'MMM yyyy')}`;
            }
          } else {
            range = '';
          }
        }
      }

      return range;
    };

    const workHistoryRolesStrFor = (
      history: WorkHistoryType,
    ): string => {
      let str = '';
      if (history.roles.length) {
        for (const role of history.roles) {
          if (str) {
            str += ', ';
          }

          str += ucwords(role.name);
        }
      }

      return str;
    };

    const initWatchers = () => {
      const router = useRouter();

      /** watch email changes and update state if the email is valid */
      watch(
        () => state.newData.email,
        async (email) => {
          if (
            email &&
            isInCompanyCreateMode.value &&
            isEmail(email)
          ) {
            setState({
              asyncIsEmailValid: null,
              isCheckingIfEmailIsValid: true,
            });

            try {
              const doesExists = await doesEmailExists(email);

              setState({
                asyncIsEmailValid: !doesExists,
                isCheckingIfEmailIsValid: false,
              });
            } catch {
              //
            }
          }
        },
      );

      /** Watch fileIds and reset delete work history tracker */
      watch(
        () => state.oldData.workHistory,
        (list) => {
          if (isArray(list)) {
            setState({
              removedWorkHistoryIds:
                state.removedWorkHistoryIds.filter(
                  (removedId: any) =>
                    !!list.find(
                      (history) => history.id === removedId,
                    ),
                ),
            });
          }
        },
        {
          immediate: true,
        },
      );

      /** Watch fileIds and fetch files */
      watch(
        () => state.newData.fileIds,
        async (fileIds, prev) => {
          if (isEqual(fileIds, prev)) {
            return;
          }

          if (isArray(fileIds)) {
            if (isEmpty(fileIds)) {
              setState({
                fileRecords: [],
              });
            } else {
              try {
                const res = await useRequest(`files`, {
                  params: {
                    ids: fileIds,
                  },
                });

                if (res.status === 200) {
                  const data = res._data as {
                    body: FileType[];
                  };

                  const files = data.body as FileType[];

                  setState({
                    fileRecords: files,
                  });
                }
              } catch {
                //
              }
            }
          }
        },
        {
          deep: true,
          immediate: true,
        },
      );

      /** Watch mode and redirect where necessary */
      watch(
        () => mode.value,
        async (mode) => {
          if (
            mode === 'edit-profile' &&
            router &&
            router.currentRoute &&
            !router.currentRoute.value.matched.some(
              (route) => route.path === '/profile/edit',
            )
          ) {
            router?.push({
              path: '/profile/edit',
            });

            return;
          }

          if (
            mode === 'create-company-talent' &&
            router &&
            router.currentRoute &&
            !router.currentRoute.value.matched.some(
              (route) => route.path === '/company/talent/create',
            )
          ) {
            router?.push({
              path: '/company/talent/create',
            });

            return;
          }

          if (
            mode === 'create-profile' &&
            router &&
            router.currentRoute &&
            !router.currentRoute.value.matched.some(
              (route) => route.path === '/profile/talent/create',
            )
          ) {
            router?.push({
              path: '/profile/talent/create',
            });

            return;
          }

          if (!hasVideo.value && mode === 'edit-profile') {
            if (!error.value) {
              await until(
                () => hasOldData.value,
                (value: any) => {
                  return !!value;
                },
                {
                  wait: 1000,
                },
              );

              router?.push({
                path: '/talent-english-fluency-intro',
              });
            }
          }
        },
        {
          immediate: true,
        },
      );

      /** watch server profile reload key and refetch profile */
      watch(
        () => state.serverProfileReloadKey,
        (key, prev) => {
          if (key === prev) {
            return;
          }

          if (key) {
            loadProfileFromServer();
          }
        },
        {
          immediate: true,
        },
      );
    };

    const isModuleReady = async () => {
      const pinia = getActivePinia();

      if (pinia) {
        const talentProfile = useTalentProfile(pinia);

        if (
          talentProfile.$persistedState &&
          talentProfile.$persistedState.isReady
        ) {
          await talentProfile.$persistedState.isReady();
        }

        return true;
      }

      return false;
    };

    const reset = (): void => {
      const newState = cloneDeep(initialState) as Record<
        string,
        unknown
      >;

      forEach(newState, (value, key) => {
        set(state, key, value);
      });
    };

    return {
      ...toRefs(state),
      isModuleReady,
      workHistoryRolesStrFor,
      workHistoryRangeFor,
      getSocialIcon,
      getLinkIcon,
      initWatchers,
      setState,
      setOldData,
      setEmail,
      setNewData,
      clearNewData,
      reset,
      reloadProfileFromServer,
      formattedData,
      getFormattedDataFor,
      error,
      isBiographyValid,
      biography,
      links,
      hasVideo,
      profileVideoMimeType,
      profileVideoUrl,
      isVideoLinkValid,
      videoLink,
      profileImageUrl,
      otherDocuments,
      hasResume,
      resumes,
      files,
      oldFiles,
      isDeletingWorkHistory,
      historyList,
      verifiedNamedSkills,
      profileNamedSkills,
      skills,
      isPhoneNumberValid,
      phoneNumber,
      isYearlyRateValid,
      yearlyRate,
      isMonthlyRateValid,
      monthlyRate,
      isHourlyRateValid,
      hourlyRate,
      isWeeklyAvailableHoursValid,
      availableWeeklyHours,
      maximumAvailableWeeklyHours,
      minimumAvailableWeeklyHours,
      isLocationValid,
      location,
      isEmailTokensValid,
      oldProfileFiles,
      isOldAndNewEmailValid,
      isEmailValid,
      email,
      isPreferedRolesValid,
      preferedRoles,
      isHeadlineValid,
      headline,
      isGenderValid,
      gender,
      isNameValid,
      name,
      uniqueId,
      isInCreateMode,
      isInCandidateCreateMode,
      isInCompanyCreateMode,
      isInEditMode,
      hasNewData,
      hasOldData,
      interestList,
      availabilityList,
      mode,
      hasData,
      profileCompletionPercentage,
    };
  },
  {
    persistedState: {
      persist: true,
    },
  },
);
