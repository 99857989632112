/* eslint-disable import/no-named-as-default */
/* eslint-disable import/named */
import type { Properties } from 'devextreme/ui/file_uploader';
import { merge, get, isString, isArray, isEmpty } from 'lodash-es';
import isNumeric from 'fast-isnumeric';
import until from 'until-promise';
import {
  IReCaptchaComposition,
  useReCaptcha,
} from 'vue-recaptcha-v3';
import EventEmitter from 'eventemitter3';
import { evaluate, number } from 'mathjs';

export enum FilePurpose {
  VENDOR_PROFILE_PICTURE = 'vendor/profile/pictures',
  VENDOR_PROFILE_COURSE_MEDIA_TITLE_BANNER = 'vendor/profile/course/media/banners',
  VENDOR_PROFILE_COURSE_MEDIA_VIDEO = 'vendor/profile/course/media/videos',
  TALENT_PROFILE_RESUME = 'talent/profile/resumes',
  TALENT_PROFILE_PICTURE = 'talent/profile/pictures',
  TALENT_PROFILE_DOCUMENTS = 'talent/profile/documents',
  TALENT_PROFILE_VIDEO = 'talent/profile/videos',
  TALENT_CODING_ASSESSMENT_TALENT_VIDEO = 'talent/profile/coding-assessment/talent/videos',
  TALENT_CODING_ASSESSMENT_SCREEN_VIDEO = 'talent/profile/coding-assessment/screen/videos',
  TALENT_CODING_ASSESSMENT_TALENT_PICTURE = 'talent/profile/coding-assessment/talent/pictures',
  TALENT_PROFILE_INTERVIEW_VIDEO = 'talent/profile/interview/videos',
  JOB_DOCUMENT = 'job/documents',
  JOB_APPLICATION_RESUME = 'job/application/resumes',
  JOB_APPLICATION_ATTACHMENT = 'job/application/attachments',
  JOB_APPLICATION_DOCUMENT = 'job/application/documents',
  JOB_APPLICATION_INTERVIEW_VIDEO = 'job/application/interview/videos',
  COMPANY_PROFILE_LOGO = 'company/profile/logos',
  COMPANY_DEPARTMENT_LOGO = 'company/department/logos',
  COMPANY_USER_PROFILE_PICTURE = 'company/user/profile/pictures',
  COMPANY_TALENT_PROFILE_ATTACHMENT = 'company/talent/profile/attachments',
  SOCIAL_POST_PICTURE = 'social/post/pictures',
  SOCIAL_POST_VIDEO = 'social/post/videos',
  SOCIAL_POST_ATTACHMENT = 'social/post/attachments',
  COMPANY_PROFILE_BACKGROUND_IMAGE = 'company/profile/background/images',
  TALENT_PROFILE_BACKGROUND_IMAGE = 'talent/profile/background/images',
  SOCIAL_POST_COMMENT_PICTURE = 'social/post/comment/pictures',
  SOCIAL_POST_COMMENT_REPLY_PICTURE = 'social/post/comment/reply/pictures',
  SOCIAL_MESSAGE_SINGLE_CHAT_PICTURE = 'social/message/chat/pictures',
  SOCIAL_MESSAGE_SINGLE_CHAT_VIDEO = 'social/message/chat/videos',
  SOCIAL_MESSAGE_SINGLE_CHAT_ATTACHMENT = 'social/message/chat/attachments',
  SOCIAL_MESSAGE_GROUP_CHAT_PICTURE = 'social/message/group/chat/pictures',
  SOCIAL_MESSAGE_GROUP_CHAT_VIDEO = 'social/message/group/chat/videos',
  SOCIAL_MESSAGE_GROUP_CHAT_ATTACHMENT = 'social/message/group/chat/attachments',
  CUSTOM_MESSAGES_ATTACHMENT = 'custom/messages/attachments',
  WALLET_COMPANY_COMPLIANCE_OWNERSHIP = 'wallet/company/compliance/ownership',
  WALLET_COMPANY_COMPLIANCE_REGISTRATION = 'wallet/company/compliance/registration',
  WALLET_COMPANY_COMPLIANCE_BANK = 'wallet/company/compliance/bank',
  WALLET_COMPANY_COMPLIANCE_TRADING = 'wallet/company/compliance/trading',
  REFERRER_PROFILE_PICTURE = 'referrer/profile/pictures',
  OFFLINE_WALLET_TOPUP_PROOF = 'wallet/transactions/offline/topup/proofs',
}

type CustomData = {
  purpose: FilePurpose;
  other?: Record<string, string>;
  onFileUpload?: (fileId: string, file: Blob) => unknown;
  onFilesUploaded?: (
    records: { file: File; fileId: string }[],
  ) => unknown;
};

type ConstructorProperties = Properties & {
  customData: CustomData;
};

type FileUploadUrlResponse = {
  fileId: string;
};

type SingleFileUploadUrlResponse = FileUploadUrlResponse & {
  type: 'single';
  url: string;
};

type DynamicFileUploadResponse = FileUploadUrlResponse & {
  type: 'dynamic';
};

type PartResponseUrl = {
  preSignedUrl: string;
  partNumber: number;
  bytesFrom: number;
  bytesTo: number;
};

type MultipartFileUploadUrlResponse = FileUploadUrlResponse & {
  type: 'multipart';
  parts: PartResponseUrl[];
};

function isSingleFileUploadUrl(
  data: Record<string, unknown>,
): data is SingleFileUploadUrlResponse {
  return (
    isString(get(data, 'fileId')) &&
    get(data, 'type') === 'single' &&
    isString(get(data, 'url'))
  );
}

function isDynamicFileUpload(
  data: Record<string, unknown>,
): data is DynamicFileUploadResponse {
  return (
    isString(get(data, 'fileId')) && get(data, 'type') === 'dynamic'
  );
}

function isPartResponseUrl(
  data: Record<string, unknown>,
): data is PartResponseUrl {
  return (
    isString(get(data, 'preSignedUrl')) &&
    isNumeric(get(data, 'partNumber')) &&
    isNumeric(get(data, 'bytesFrom')) &&
    isNumeric(get(data, 'bytesTo'))
  );
}

function isMultipartFileUploadUrl(
  data: Record<string, unknown>,
): data is MultipartFileUploadUrlResponse {
  const parts = get(data, 'parts');

  return (
    isString(get(data, 'fileId')) &&
    get(data, 'type') === 'multipart' &&
    isArray(parts) &&
    parts.find((part) => !isPartResponseUrl(part)) === undefined
  );
}

type FilePartData = {
  PartNumber: number;
  ETag: string;
};

export class FileUploader {
  options: ConstructorProperties;
  fileRecords: {
    fileId: string;
    file: File;
    xhrs: XMLHttpRequest[];
    filePartsData: FilePartData[];
  }[] = [];

  recaptcha: IReCaptchaComposition | undefined;
  toastId: string;
  uploadedFileRecords: {
    fileId: string;
    file: File;
  }[] = [];

  static toast = useToast();

  constructor(options: ConstructorProperties) {
    this.options = options;
    this.recaptcha = useReCaptcha();
    this.toastId =
      String(Date.now()) + String(Math.random() * Date.now());
  }

  getOptions(): Partial<Properties> {
    return merge(
      {
        accept: '.doc, .docx, .pdf',
        multiple: true,
        abortUpload: this.abortUpload.bind(this),
        allowCanceling: true,
        hint: 'Drag and drop attachments required for job',
        invalidMaxFileSizeMessage:
          'Ooops.. maximum file size is 10MB',
        invalidMinFileSizeMessage:
          'Please upload a valid file that is not empty',
        maxFileSize: 10 * 1024 * 1024,
        minFileSize: 1,
        onUploaded: this.onUploaded.bind(this),
        onUploadError: this.onUploadError.bind(this),
        uploadFile: this.uploadFile.bind(this),
        uploadMode: 'useButtons',
      },
      this.options,
    );
  }

  async abortUpload(
    ...args: Parameters<Required<Properties>['abortUpload']>
  ): Promise<void> {
    const [file] = args;

    await until(
      () => {
        const fileRecord = this.fileRecords.find(
          (record) => record.file === file,
        );

        return fileRecord;
      },
      (fileRecord) => !!fileRecord,
      {
        wait: 50,
      },
    );

    const fileRecord = this.fileRecords.find(
      (record) => record.file === file,
    );

    if (fileRecord) {
      fileRecord.xhrs.forEach((xhr) => {
        xhr.abort();
      });

      if (fileRecord.fileId) {
        await useRequest(`files/upload/${fileRecord.fileId}`, {
          method: 'delete',
        });
      }

      this.removeFileRecord(fileRecord.file);
    }
  }

  async onUploaded(
    ...args: Parameters<Required<Properties>['onUploaded']>
  ): Promise<void> {
    const [event] = args;
    const fileRecord = this.fileRecords.find(
      (record) => record.file === event.file,
    );

    if (fileRecord) {
      await until(
        () => {
          const isValid =
            !isEmpty(fileRecord.filePartsData) &&
            fileRecord.filePartsData.length ===
              fileRecord.xhrs.length;

          return isValid;
        },
        (isValid) => !!isValid,
        {
          wait: 50,
        },
      );

      const completeRequest = await useRequest('files/upload', {
        method: 'put',
        body: {
          fileId: fileRecord.fileId,
          completedParts: fileRecord.filePartsData,
          type: fileRecord.xhrs.length > 1 ? 'multipart' : 'single',
        },
      });

      this.removeFileRecord(fileRecord.file);
      if (completeRequest.status === 204) {
        this.removeUploadedFileRecord(fileRecord.file);
        this.uploadedFileRecords.push({
          file: fileRecord.file,
          fileId: fileRecord.fileId,
        });

        if (this.options.customData.onFileUpload) {
          await this.options.customData.onFileUpload(
            fileRecord.fileId,
            fileRecord.file,
          );
        }

        if (!this.isUploading()) {
          if (this.options.customData.onFilesUploaded) {
            await this.options.customData.onFilesUploaded(
              this.uploadedFileRecords,
            );
          }

          this.uploadedFileRecords = [];
        }

        return;
      }

      throw new Error('Invalid upload request');
    }
  }

  async onUploadError(
    ...args: Parameters<Required<Properties>['onUploadError']>
  ): Promise<void> {
    const [event] = args;
    await this.abortUpload(event.file);
    this.removeFileRecord(event.file);
    this.removeUploadedFileRecord(event.file);
  }

  async uploadFile(
    ...args: Parameters<Required<Properties>['uploadFile']>
  ): Promise<void> {
    const [file, onProgress] = args;

    this.fileRecords.push({
      fileId: '0',
      file,
      xhrs: [],
      filePartsData: [],
    });

    const regex = /(?:\.([^.]+))?$/;
    const execSplit = regex.exec(file.name);

    let type = 'media';
    let ext = 'unknown';

    if (!execSplit) {
      type = 'media';
      ext = 'unknown';
    } else {
      switch (execSplit[1]) {
        case 'doc':
          type = 'word_doc';
          ext = 'doc';
          break;
        case 'docx':
          type = 'word_docx';
          ext = 'docx';
          break;
        case 'pdf':
          type = 'pdf';
          ext = 'pdf';
          break;
        case 'png':
          type = 'image_png';
          ext = 'png';
          break;
        case 'jpg':
          type = 'image_jpg';
          ext = 'jpg';
          break;
        case 'jpeg':
          type = 'image_jpeg';
          ext = 'jpeg';
          break;
        case 'gif':
          type = 'image_gif';
          ext = 'gif';
          break;
        case 'mp4':
          type = 'video_mp4';
          ext = 'mp4';
          break;
        case 'webm':
          type = 'video_webm';
          ext = 'webm';
          break;
        case 'mkv':
          type = 'video_mkv';
          ext = 'mkv';
          break;
        case '3gp':
          type = 'video_3gp';
          ext = '3gp';
          break;
        case 'avi': {
          type = 'video_avi';
          ext = 'avi';
          break;
        }
        case 'wmv': {
          type = 'video_wmv';
          ext = 'wmv';
          break;
        }
        case 'mov': {
          type = 'video_mov';
          ext = 'mov';
          break;
        }
        case 'm3u8': {
          type = 'video_m3u8';
          ext = 'm3u8';
          break;
        }
        case 'ts': {
          type = 'video_ts';
          ext = 'ts';
          break;
        }
        case 'flv': {
          type = 'video_flv';
          ext = 'flv';
          break;
        }
      }
    }

    const uploadRequestResp = await useRequest('files/upload', {
      method: 'post',
      body: {
        fileName: file.name,
        fileExtension: ext,
        filePurpose: this.options.customData.purpose,
        fileType: type,
        fileSize: file.size,
        otherFileDetails: this.options.customData.other || {},
      },
    });

    if (uploadRequestResp.status === 200) {
      const data = uploadRequestResp._data as {
        body: Record<string, unknown>;
      };
      const uploadUrlResponse = data.body;
      const xhrs: XMLHttpRequest[] = [];
      const filePartsData: FilePartData[] = [];
      let totalLoaded = 0;

      if (isSingleFileUploadUrl(uploadUrlResponse)) {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener('progress', function (e) {
          totalLoaded = e.loaded;
          onProgress(totalLoaded);
        });

        xhr.upload.addEventListener('error', function (e) {
          throw e;
        });

        xhr.onreadystatechange = function () {
          if (this.readyState === this.HEADERS_RECEIVED) {
            const ETag =
              xhr.getResponseHeader('ETag')?.replaceAll('"', '') ||
              '';

            filePartsData.push({
              ETag,
              PartNumber: 1,
            });
          }
        };

        xhr.open('PUT', uploadUrlResponse.url);
        xhr.send(file);

        xhrs.push(xhr);
      } else if (isMultipartFileUploadUrl(uploadUrlResponse)) {
        uploadUrlResponse.parts.forEach((part) => {
          let prevLoaded = 0;
          const xhr = new XMLHttpRequest();
          xhr.upload.addEventListener('progress', function (e) {
            totalLoaded = totalLoaded + e.loaded - prevLoaded;
            prevLoaded = e.loaded;
            onProgress(totalLoaded);
          });

          xhr.upload.addEventListener('error', function (e) {
            throw e;
          });

          xhr.onreadystatechange = function () {
            if (this.readyState === this.HEADERS_RECEIVED) {
              console.log('Response happened here...');
              const ETag =
                xhr.getResponseHeader('ETag')?.replaceAll('"', '') ||
                '';

              filePartsData.push({
                ETag,
                PartNumber: part.partNumber,
              });
            }
          };

          xhr.open('PUT', part.preSignedUrl);
          xhr.send(file.slice(part.bytesFrom, part.bytesTo));

          xhrs.push(xhr);
        });
      }

      this.removeFileRecord(file);
      this.fileRecords.push({
        fileId: uploadUrlResponse.fileId as string,
        file,
        xhrs,
        filePartsData,
      });

      await until(
        () => {
          const isValid = totalLoaded >= file.size;
          return isValid;
        },
        (isValid) => !!isValid,
        {
          wait: 50,
        },
      );

      onProgress(file.size);
      return;
    }

    throw new Error('Invalid upload request');
  }

  isUploading(): boolean {
    return this.fileRecords.length > 0;
  }

  removeFileRecord(savedFile: File): void {
    const index = this.fileRecords.findIndex(
      ({ file }) => file === savedFile,
    );

    if (index > -1) {
      this.fileRecords.splice(index, 1);
    }
  }

  removeUploadedFileRecord(savedFile: File) {
    const uploadedFileIndex = this.uploadedFileRecords.findIndex(
      ({ file }) => file === savedFile,
    );

    if (uploadedFileIndex > -1) {
      this.uploadedFileRecords.splice(uploadedFileIndex, 1);
    }
  }
}

export enum DynamicFileUploaderEventTypes {
  DESTROYED = 'destroyed',
  INITIALIZED = 'initialized',
  READY = 'ready',
  ABORTED = 'aborted',
  UPLOADED = 'uploaded',
  PART_UPLOAD_ERROR = 'part-upload-error',
  PART_UPLOAD_PROGRESS = 'part-upload-progress',
  PART_UPLOAD_SUCCESS = 'part-upload-success',
}

export class DynamicFileUploader extends EventEmitter<DynamicFileUploaderEventTypes> {
  private fileId?: string;
  private _isUploaded = false;
  private _lastPart = 0;
  private parts: {
    number: number;
    blob: Blob;
    xhr?: XMLHttpRequest;
    etag?: string;
    isUploaded: boolean;
    sizeInBytes: number;
    bytesUploaded: number;
  }[] = [];

  private isAborted = false;

  private toastId =
    String(Date.now()) + String(Math.random() * Date.now());

  static toast = useToast();

  constructor(
    private name: string,
    private purpose: FilePurpose,
    private other: Record<string, string> | undefined = undefined,
  ) {
    super();
    this.initFileUpload();
  }

  get isReady() {
    return !!this.fileId;
  }

  get fileIdentifier() {
    return this.fileId;
  }

  get lastPart() {
    return this._lastPart;
  }

  get nextPart() {
    return this._lastPart + 1;
  }

  get currentSize() {
    return this.parts.reduce(
      (prev, part) => evaluate(`${prev} + ${part.sizeInBytes}`),
      0,
    );
  }

  get uploadedSize() {
    return this.parts.reduce(
      (prev, part) =>
        part.isUploaded
          ? evaluate(`${prev} + ${part.sizeInBytes}`)
          : prev,
      0,
    );
  }

  get isUploading(): boolean {
    return (
      !this.isUploaded ||
      this.parts.filter((part) => !part.isUploaded).length > 0
    );
  }

  get isUploaded(): boolean {
    return this._isUploaded;
  }

  public destroy() {
    if (!this.isAborted) {
      this.abortUpload();
    } else {
      this.parts = [];
      this._lastPart = 0;
    }

    this.emit(DynamicFileUploaderEventTypes.DESTROYED);
  }

  private async initFileUpload(): Promise<void> {
    const regex = /(?:\.([^.]+))?$/;
    const execSplit = regex.exec(this.name);

    let type = 'media';
    let ext = 'unknown';

    if (!execSplit) {
      type = 'media';
      ext = 'unknown';
    } else {
      switch (execSplit[1]) {
        case 'doc':
          type = 'word_doc';
          ext = 'doc';
          break;
        case 'docx':
          type = 'word_docx';
          ext = 'docx';
          break;
        case 'pdf':
          type = 'pdf';
          ext = 'pdf';
          break;
        case 'png':
          type = 'image_png';
          ext = 'png';
          break;
        case 'jpg':
          type = 'image_jpg';
          ext = 'jpg';
          break;
        case 'jpeg':
          type = 'image_jpeg';
          ext = 'jpeg';
          break;
        case 'gif':
          type = 'image_gif';
          ext = 'gif';
          break;
        case 'mp4':
          type = 'video_mp4';
          ext = 'mp4';
          break;
        case 'webm':
          type = 'video_webm';
          ext = 'webm';
          break;
        case 'mkv':
          type = 'video_mkv';
          ext = 'mkv';
          break;
        case '3gp':
          type = 'video_3gp';
          ext = '3gp';
          break;
        case 'avi': {
          type = 'video_avi';
          ext = 'avi';
          break;
        }
        case 'wmv': {
          type = 'video_wmv';
          ext = 'wmv';
          break;
        }
        case 'mov': {
          type = 'video_mov';
          ext = 'mov';
          break;
        }
        case 'm3u8': {
          type = 'video_m3u8';
          ext = 'm3u8';
          break;
        }
        case 'ts': {
          type = 'video_ts';
          ext = 'ts';
          break;
        }
        case 'flv': {
          type = 'video_flv';
          ext = 'flv';
          break;
        }
      }
    }

    const uploadRequestResp = await useRequest(
      'files/dynamic/upload',
      {
        method: 'post',
        body: {
          fileName: this.name,
          fileExtension: ext,
          filePurpose: this.purpose,
          fileType: type,
          otherFileDetails: this.other || {},
        },
      },
    );

    if (uploadRequestResp.status === 200) {
      const data = uploadRequestResp._data as {
        body: Record<string, unknown>;
      };
      const uploadUrlResponse = data.body;

      if (isDynamicFileUpload(uploadUrlResponse)) {
        this.fileId = uploadUrlResponse.fileId;

        this.emit(
          DynamicFileUploaderEventTypes.INITIALIZED,
          this.fileId,
        );
        this.emit(DynamicFileUploaderEventTypes.READY);
      }

      return;
    }

    throw new Error('Invalid upload request');
  }

  async abortUpload(): Promise<void> {
    await until(
      () => {
        return this.fileId;
      },
      (fileId) => !!fileId,
      {
        wait: 50,
      },
    );

    await Promise.allSettled(
      this.parts.map((part) => part.xhr?.abort()),
    );
    await useRequest(`files/upload/${this.fileId}`, {
      method: 'delete',
    });
    this.isAborted = true;
    this.destroy();
    this.emit(DynamicFileUploaderEventTypes.ABORTED);
  }

  async uploadPartBlob(blob: Blob | File) {
    if (!this.isReady) {
      throw new Error('Dynamic upload is not ready');
    }

    if (this.isUploaded) {
      throw new Error('Upload is already complete');
    }

    const partNumber = this.nextPart;
    const prevPartIndex = this.parts.findIndex(
      (part) => part.number === partNumber,
    );
    if (prevPartIndex > -1) {
      const part = this.parts[prevPartIndex];

      if (part.isUploaded) {
        return true;
      }

      if (part.xhr) {
        await part.xhr.abort();
      }

      this.parts.splice(prevPartIndex, 1);
    }

    const partUrlResp = await useRequest(`files/dynamic/upload/url`, {
      method: 'post',
      body: {
        fileId: this.fileId,
        partNumber: number(partNumber),
        size: blob.size,
      },
    });

    if (partUrlResp.status === 200) {
      const data = partUrlResp._data as {
        body:
          | {
              type: 'single';
              url: string;
              fileId: string;
            }
          | {
              type: 'dynamic';
              url: string;
              partNumber: number;
              fileId: string;
            };
      };

      const url = data.body.url;
      const partData = {
        number: partNumber,
        blob,
        xhr: undefined,
        etag: undefined,
        isUploaded: false,
        sizeInBytes: blob.size,
        bytesUploaded: 0,
      } as (typeof this.parts)[0];

      const xhr = new XMLHttpRequest();
      partData.xhr = xhr;

      xhr.upload.addEventListener('progress', (e) => {
        partData.bytesUploaded = e.loaded;
        this.emit(
          DynamicFileUploaderEventTypes.PART_UPLOAD_PROGRESS,
          {
            ...partData,
            number: partNumber,
          },
        );
      });

      xhr.upload.addEventListener('error', (e) => {
        this.emit(DynamicFileUploaderEventTypes.PART_UPLOAD_ERROR, {
          number: partNumber,
          error: e,
        });
      });

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      xhr.onreadystatechange = async function () {
        if (this.readyState === this.HEADERS_RECEIVED) {
          const ETag =
            xhr.getResponseHeader('ETag')?.replaceAll('"', '') || '';

          partData.etag = ETag;
          self.emit(
            DynamicFileUploaderEventTypes.PART_UPLOAD_PROGRESS,
            {
              ...partData,
              etag: ETag,
            },
          );
        } else if (this.readyState === this.DONE) {
          if (data.body.type === 'dynamic') {
            await useRequest(`files/dynamic/upload/progress`, {
              method: 'post',
              body: {
                fileId: self.fileId,
                partNumber: number(partNumber),
                etag: partData.etag,
              },
            });

            partData.isUploaded = true;
            partData.bytesUploaded = blob.size;

            self.emit(
              DynamicFileUploaderEventTypes.PART_UPLOAD_SUCCESS,
              {
                ...partData,
              },
            );
          } else if (data.body.type === 'single') {
            await self.completeUpload();
          }
        }
      };

      xhr.open('PUT', url);
      xhr.send(blob);

      if (data.body.type === 'dynamic') {
        this.parts.push(partData);
      }

      await until(
        () => {
          return partData;
        },
        (partData) => !!partData.isUploaded,
        {
          wait: 50,
        },
      );
    }

    throw new Error('Invalid Dynamic part upload request');
  }

  async completeUpload() {
    if (this.isUploading) {
      await until(
        () => {
          return this.parts;
        },
        (parts) =>
          parts.filter((part) => !part.isUploaded).length === 0,
        {
          wait: 50,
        },
      );
    }

    const completeUploadResp = await useRequest('files/upload', {
      method: 'put',
      body: {
        fileId: this.fileId,
        type: 'dynamic',
      },
    });

    if (completeUploadResp.status === 204) {
      this._isUploaded = true;
      this.emit(DynamicFileUploaderEventTypes.UPLOADED, this.fileId);
      return;
    }

    throw new Error('Invalid Dynamic part upload request.');
  }
}
