import * as VueToastification from 'vue-toastification';
import type { PluginOptions as ToastPluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { get } from 'lodash-es';

const options: ToastPluginOptions = {
  shareAppContext: true,
  position: get(
    VueToastification,
    'default.POSITION.TOP_LEFT',
    'top-left',
  ) as ToastPluginOptions['position'],
  toastDefaults: {
    [get(VueToastification, 'default.TYPE.ERROR', 'error')]: {
      timeout: 5000,
    },
    [get(VueToastification, 'default.TYPE.SUCCESS', 'success')]: {
      timeout: 3000,
      hideProgressBar: false,
    },
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueToastification.default, options);
});
