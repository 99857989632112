import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: runtimeConfig.public.googleRecaptchaSiteKey,
    loaderOptions: {
      useRecaptchaNet: true,
      useEnterprise: false,
      autoHideBadge: true,
      renderParameters: undefined,
      explicitRenderParameters: undefined,
      customUrl: undefined,
    },
  });
});
