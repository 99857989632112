import { get } from 'lodash-es';

const linkedInIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/linkedin_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const githubIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/github_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const gitlabIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/gitlab_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const bitbucketIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/bitbucket_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const stackoverflowIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/stackoverflow_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const twitterIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/twitter_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const portfolioIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/portfolio_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const websiteIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/website_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const facebookIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/facebook_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const instagramIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/icons/instagram_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

const tiktokIcon = computedAsync(async () => {
  const iconData = await import(
    `@/assets/svgs/icons/tiktok_social.svg?icon`
  );
  return (get(iconData, 'default', '') as string) || '';
}, '');

export const getSocialIcon = computed(() => (name: string) => {
  switch (name) {
    case 'linkedin': {
      return linkedInIcon.value;
    }

    case 'github': {
      return githubIcon.value;
    }

    case 'gitlab': {
      return gitlabIcon.value;
    }

    case 'bitbucket': {
      return bitbucketIcon.value;
    }

    case 'stackoverflow': {
      return stackoverflowIcon.value;
    }

    case 'twitter': {
      return twitterIcon.value;
    }

    case 'portfolio': {
      return portfolioIcon.value;
    }

    case 'website': {
      return websiteIcon.value;
    }

    case 'facebook': {
      return facebookIcon.value;
    }

    case 'instagram': {
      return instagramIcon.value;
    }

    case 'tiktok': {
      return tiktokIcon.value;
    }

    default: {
      return '';
    }
  }
});
