import { useSound } from '@vueuse/sound';
import { each, set } from 'lodash-es';

const soundHandlers: Record<
  string,
  ReturnType<typeof useSound> | undefined
> = {};

export const getSoundFor = (
  soundName: string,
): ReturnType<typeof useSound> | null => {
  if (soundHandlers[soundName]) {
    return soundHandlers[soundName] || null;
  }

  return null;
};

export const setSoundFor = (
  soundName: string,
  handler: ReturnType<typeof useSound>,
): void => {
  soundHandlers[soundName] = handler;
};

export const setSounds = (
  sounds: Record<string, ReturnType<typeof useSound> | undefined>,
) => {
  each(sounds, (value, key) => {
    set(soundHandlers, key, value);
  });
};
