import { assign, get, has } from 'lodash-es';
import { useToast as toastFn } from 'vue-toastification';
import * as vueToastification from 'vue-toastification';

type ToastFn = typeof toastFn;
export const useToast: ToastFn = (...args) => {
  let toastFn: ToastFn | undefined;

  if (!toastFn && has(vueToastification, 'default.useToast')) {
    toastFn = get(
      vueToastification,
      'default.useToast',
      undefined,
    ) as ToastFn | undefined;
  }

  if (!toastFn && has(vueToastification, 'useToast')) {
    toastFn = get(vueToastification, 'useToast', undefined) as
      | ToastFn
      | undefined;
  }

  if (toastFn) {
    return toastFn(...args);
  }

  const fn = function () {
    return '';
  };
  const undefinedFn = () => undefined;
  const strFn = () => '';

  assign(fn, {
    clear: undefinedFn,
    updateDefaults: undefinedFn,
    dismiss: undefinedFn,
    update: undefinedFn,
    success: strFn,
    info: strFn,
    error: strFn,
    warning: strFn,
  });

  return fn as unknown as ReturnType<ToastFn>;
};
