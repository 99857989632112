import { set } from 'lodash-es';
import 'assets/scss/nprogress.scss';
import nprogress from 'nprogress';

export default defineNuxtPlugin((nuxtApp) => {
  if (process.server) {
    nuxtApp.hook('page:start', () => {
      console.log(
        'processed in the server ohhhhh app plugin..... ',
        nuxtApp.$route.fullPath,
      );
    });
  }

  if (process.client) {
    (async () => {
      const hljs = await import(
        /* webpackChunkName: "highlight-js" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        'highlight.js'
      );

      hljs.default.configure({
        cssSelector: 'pre code',
      });

      set(window, 'hljs', hljs.default);
    })();

    nprogress.configure({
      trickle: true,
      showSpinner: true,
      trickleSpeed: 800,
      easing: 'ease-in-out',
      speed: 500,
    });

    nuxtApp.hook('page:start', async () => {
      nprogress.start();
      const user = useUser();
      await user.isModuleReady();
      await user.syncToken();
    });

    nuxtApp.hook('page:finish', () => {
      nprogress.done(!nprogress.isStarted());
    });
  }
});
