<template>
  <Multiselect
    v-bind="$attrs"
    ref="multiselect"
    :mode="mode"
    :object="object"
    :close-on-select="localCloseOnSelect"
    :model-value="value"
    :value="value"
    :loading="loadingLocal"
    :options="customOptions"
    class="component multiselect entry"
    @click="openOptions"
    @open="handleOpenOptions"
    @close="handleCloseOptions"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
    @select="handleSelect"
    @search-change="handleSearch"
  >
    <template
      v-for="name in slotNames"
      #[getSlotNameFor(name)]="slotData"
    >
      <slot
        :name="name"
        v-bind="getSlotDataFor(slotData)"
      />
    </template>
  </Multiselect>
</template>

<script lang="ts" setup>
import {
  createPopper,
  type Instance,
  type Placement,
} from '@popperjs/core';
import Multiselect from '@vueform/multiselect';
import {
  controlledRef,
  debouncedRef,
  onClickOutside,
  syncRef,
  templateRef,
  type MaybeElement,
  type MaybeElementRef,
} from '@vueuse/core';
import isNumeric from 'fast-isnumeric';
import {
  debounce,
  each,
  get,
  invoke,
  isArray,
  isBoolean,
  isEqual,
  isFunction,
  isNull,
  isObject,
  isString,
  keys,
  set,
} from 'lodash-es';
import until from 'until-promise';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  toRaw,
  watch,
  watchEffect,
  type PropType,
  type UnwrapRef,
} from 'vue';

const props = defineProps({
  showOptionsOnHover: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  optionsPlacement: {
    type: String as PropType<Placement>,
    default: 'bottom',
    required: false,
  },
  closeOnSelect: {
    type: Boolean as PropType<boolean | undefined>,
    required: false,
    default: undefined,
  },
  mode: {
    type: String as PropType<'single' | 'multiple' | 'tags'>,
    required: false,
    default: 'single',
  },
  addToOptionsWidth: {
    type: [Number, String] as PropType<number | string>,
    required: false,
    default: 0,
  },
  maxOptionsHeight: {
    type: [Number, String] as PropType<number | string>,
    required: false,
    default: '400px',
  },
  optionsHeight: {
    type: [Number, String] as PropType<number | string>,
    required: false,
    default: 'auto',
  },
  refreshOptionsRef: {
    type: [Boolean, Number, String] as PropType<
      boolean | number | string
    >,
    required: false,
    default: Date.now(),
  },
  show: {
    type: Boolean as PropType<boolean | null>,
    required: false,
    default: null,
  },
  object: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  options: {
    type: [Array, Function] as PropType<
      unknown[] | ((search: string) => unknown[] | Promise<unknown[]>)
    >,
    required: true,
  },
});

const emit = defineEmits(['focus', 'blur', 'options']);
const attrs = useAttrs();
const slots = useSlots();

const lastSelectedOption = ref<unknown>();
const select = (option: unknown) => {
  if (
    lastSelectedOption.value &&
    isEqual(lastSelectedOption.value, option)
  ) {
    return;
  }

  if (multiselectInstance.value) {
    multiselectInstance.value.select(option);
  }
};

const loadingLocal = ref(props.loading);
watch(
  () => props.loading,
  (loading) => {
    loadingLocal.value = loading;
  },
);

const searchTerm = controlledRef('');
const debouncedSearchTerm = debouncedRef(searchTerm, 300, {
  maxWait: 700,
});

const hasSelectedValue = controlledRef(false);
const value = computed(
  () => attrs['model-value'] || attrs.value || attrs.modelValue,
);
const controlledValue = controlledRef(value);
syncRef(
  value as unknown as Ref<unknown>,
  controlledValue as unknown as Ref<unknown>,
  {
    direction: 'ltr',
  },
);

const localOptions = controlledRef<unknown[]>([]);
const preloadOptions = (list: typeof props.options) => {
  loadingLocal.value = true;

  if (isFunction(list)) {
    invoke(props, 'options', debouncedSearchTerm.value).then(
      (list: unknown[]) => {
        localOptions.value = list;
      },
    );
  } else if (isArray(list)) {
    localOptions.value = list as unknown[];
  } else {
    localOptions.value = [];
  }

  loadingLocal.value = false;
};

watch(
  debouncedSearchTerm,
  () => {
    preloadOptions(props.options);
  },
  {
    immediate: true,
  },
);

watch(
  () => props.options,
  (options) => {
    preloadOptions(options);
  },
  {
    deep: true,
  },
);

const customOptions = () => Promise.resolve(localOptions.value);

const forceFullySelectValue = () => {
  if (
    localOptions.value &&
    localOptions.value.length > 0 &&
    value.value &&
    !hasSelectedValue.value &&
    !props.object
  ) {
    const optionValueKey =
      attrs['value-prop'] || attrs.valueProp || 'value';

    if (props.mode === 'single') {
      const selectedValue = localOptions.value.find((option) => {
        const isOptionSame = isEqual(option, value.value);
        const optionValue = get(
          option,
          optionValueKey as string,
          undefined,
        );

        return (
          isOptionSame ||
          (isObject(option) &&
            optionValue &&
            String(optionValue) === String(value.value))
        );
      });

      if (selectedValue) {
        if (!multiselectInstance.value?.isSelected(selectedValue)) {
          select(selectedValue);
          hasSelectedValue.value = true;
        }
      }
    } else if (isArray(value.value)) {
      each(value.value, (option) => {
        const localOption = localOptions.value.find(
          (localOption) =>
            String(
              get(localOption, optionValueKey as string, undefined),
            ) === String(option),
        );

        if (
          localOption &&
          !multiselectInstance.value?.isSelected(localOption)
        ) {
          select(localOption);
        }
      });

      hasSelectedValue.value = true;
    }
  }
};

watch(
  value,
  async () => {
    hasSelectedValue.value = false;
    await nextTick();
    forceFullySelectValue();
  },
  { deep: true, immediate: true },
);

const localIsOpen = ref(false);
const isShowingOptions = computed({
  get() {
    return localIsOpen.value;
  },

  set(val: boolean) {
    if (isNull(props.show)) {
      localIsOpen.value = !!val;
    }
  },
});

watch(
  () => props.show,
  (show) => {
    if (isBoolean(show)) {
      localIsOpen.value = show;
    }
  },
  {
    immediate: true,
  },
);

const multiselect = templateRef('multiselect');
const containerEl = computed<HTMLElement | undefined>(() => {
  if (multiselect.value) {
    return get(multiselect, 'value.$el', undefined) as
      | HTMLElement
      | undefined;
  }

  return undefined;
});

const optionsEl = computed(() => {
  if (containerEl.value) {
    const optionsEl = containerEl.value.querySelector(
      '.multiselect-dropdown',
    );
    if (optionsEl) return optionsEl as HTMLElement;
  }

  return undefined;
});

const multiselectInstance = computed(() => {
  if (multiselect.value) {
    return multiselect.value as Multiselect & {
      close: () => void;
      open: () => void;
      clear: () => void;
    };
  }

  return null;
});

const popper = ref<Instance>();

const optionsElStyle = ref<Record<string, string>>({});
const resizeObserver = new ResizeObserver(() => {
  if (containerEl.value) {
    const rect = containerEl.value.getBoundingClientRect();
    const addedOptionsWidth = isNumeric(props.addToOptionsWidth)
      ? String(props.addToOptionsWidth)
      : '0';

    const maxOptionsHeight = props.maxOptionsHeight
      ? isString(props.maxOptionsHeight)
        ? props.maxOptionsHeight
        : isFinite(props.maxOptionsHeight)
          ? `${props.maxOptionsHeight}px`
          : '300px'
      : '300px';

    const optionsHeight = props.optionsHeight
      ? isString(props.optionsHeight)
        ? props.optionsHeight
        : isFinite(props.optionsHeight)
          ? `${props.optionsHeight}px`
          : 'auto'
      : 'auto';

    optionsElStyle.value = {
      width: rect.width
        ? `calc(${rect.width}px + ${addedOptionsWidth}px)`
        : '100%',
      height: optionsHeight,
      maxHeight: maxOptionsHeight,
      minHeight: 'auto',
    };

    repositionPopper();
  }
});

const initPopperInstance = async () => {
  if (popper.value) {
    popper.value.destroy();
    await nextTick();
  }

  if (containerEl.value && optionsEl.value) {
    popper.value = createPopper(containerEl.value, optionsEl.value, {
      placement: props.optionsPlacement,
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            padding: [0, 1],
          },
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            padding: [0, 1],
            fallbackPlacements: [
              'top',
              'left',
              'right',
              'left-start',
              'right-start',
              'left-end',
              'right-end',
              'auto-start',
              'auto-end',
              'auto',
            ],
          },
        },
      ],
    });
  }
};

const repositionPopper = debounce(
  async () => {
    if (popper.value) {
      await popper.value.forceUpdate();
    }
  },
  100,
  {
    maxWait: 200,
    leading: true,
  },
);

onClickOutside(
  optionsEl as unknown as MaybeElementRef<MaybeElement>,
  (e) => {
    const target = get(e, 'target') as Node;

    if (containerEl.value && !containerEl.value.contains(target)) {
      isShowingOptions.value = false;
    }
  },
);

watch(
  isShowingOptions,
  async (show) => {
    if (show) {
      if (
        containerEl.value &&
        optionsEl.value &&
        multiselectInstance.value
      ) {
        if (!multiselectInstance.value.isOpen) {
          await multiselectInstance.value.open();
        }
      }

      await until(
        () => {
          return multiselectInstance.value?.isOpen;
        },
        (val: boolean | undefined) => !!val,
        {
          wait: 20,
        },
      );

      await nextTick();
      await initPopperInstance();
    } else {
      if (
        multiselectInstance.value &&
        multiselectInstance.value.isOpen
      ) {
        await multiselectInstance.value.close();
      }

      if (popper.value) {
        popper.value.destroy();
      }
    }
  },
  {
    immediate: true,
  },
);

const addFocusEventHandler = (e: Event) => {
  emit('focus', get(e, 'target.value'));

  if (multiselectInstance.value) {
    if (!multiselectInstance.value.isOpen) {
      multiselectInstance.value.open();
    }
  }
};

const addBlurEventHandler = (e: Event) => {
  emit('blur', get(e, 'target.value'));
};

watch(containerEl, (containerEl, prevEl) => {
  if (containerEl) {
    resizeObserver.observe(containerEl);

    const search = containerEl.querySelector(
      props.mode === 'tags'
        ? '.multiselect-tags-search'
        : '.multiselect-search',
    );

    if (search) {
      search.setAttribute('autocomplete', 'off');
      search.setAttribute('name', 'unknown_input_field');
      search.addEventListener('focusin', addFocusEventHandler);
      search.addEventListener('focusout', addBlurEventHandler);
    }
  } else if (prevEl) {
    resizeObserver.unobserve(prevEl);

    const search = prevEl.querySelector(
      props.mode === 'tags'
        ? '.multiselect-tags-search'
        : '.multiselect-search',
    );

    if (search) {
      search.removeEventListener('focusin', addFocusEventHandler);
      search.removeEventListener('focusout', addBlurEventHandler);
    }
  }
});

watchEffect(() => {
  if (
    optionsEl.value &&
    optionsElStyle.value &&
    containerEl.value &&
    props.optionsHeight !== undefined
  ) {
    if (optionsEl.value) {
      const el = toRaw(optionsEl.value);
      each(optionsElStyle.value, (value, name) => {
        set(el.style, name, value);
      });
    }
  }
});

const handleMouseOver = function () {
  if (props.showOptionsOnHover && multiselectInstance.value) {
    multiselectInstance.value.open();
  }
};

const handleMouseLeave = function () {
  if (props.showOptionsOnHover && multiselectInstance.value) {
    multiselectInstance.value.close();
  }
};

const ensureStateIsRespected = async () => {
  const instance = multiselectInstance.value;

  if (instance) {
    if (isShowingOptions.value) {
      if (!instance.isOpen) {
        await instance.open();
      }
    } else if (instance.isOpen) {
      await instance.close();
    }
  }
};

const handleOpenOptions = () => {
  isShowingOptions.value = true;

  ensureStateIsRespected();
};

const handleCloseOptions = () => {
  isShowingOptions.value = false;

  ensureStateIsRespected();
};

const openOptions = (e: Event) => {
  if (!isShowingOptions.value) {
    const instance = multiselectInstance.value;
    const target = e.target as Node;

    if (
      instance &&
      props.mode === 'single' &&
      optionsEl.value &&
      !optionsEl.value.contains(target)
    ) {
      instance.open();
      isShowingOptions.value = true;
    }
  }
};

const localCloseOnSelect = computed(() => {
  if (isBoolean(props.closeOnSelect)) {
    return props.closeOnSelect;
  } else {
    if (props.mode === 'single') return true;
    return false;
  }
});

const handleSelect = <RV, SO>(
  _: RV,
  selectedObject: SO,
  instanceWithoutType: Multiselect,
) => {
  const instance = instanceWithoutType as UnwrapRef<
    typeof multiselectInstance
  >;
  lastSelectedOption.value = selectedObject;

  if (
    instance &&
    props.mode === 'single' &&
    localCloseOnSelect.value
  ) {
    instance.clearSearch();
    instance.close();
  }
};

const slotNames = computed(() =>
  keys(slots).map((slotName: string | number) => String(slotName)),
);

const getSlotNameFor = (name: unknown): 'placeholder' => {
  return String(name) as 'placeholder';
};

const getSlotDataFor = (data: unknown): Record<string, unknown> => {
  return data as Record<string, unknown>;
};

const handleSearch = (searchVar: string) => {
  searchTerm.value = searchVar;
  repositionPopper();
};

const refreshOptions = async (options: unknown[]) => {
  emit('options', options);

  if (multiselectInstance.value) {
    await multiselectInstance.value.refreshOptions(() => null);
  }

  await nextTick();
  forceFullySelectValue();
};

watch(localOptions as unknown as Ref, refreshOptions, {
  deep: true,
  immediate: true,
});

watch(
  () => props.refreshOptionsRef,
  () => {
    refreshOptions(localOptions.value);
  },
);

onMounted(async () => {
  await Promise.allSettled([
    initPopperInstance(),
    refreshOptions(localOptions.value),
  ]);

  resizeObserver.observe(document.body);
});

onBeforeUnmount(() => {
  if (popper.value) {
    popper.value.destroy();

    resizeObserver.unobserve(document.body);
  }
});
</script>

<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
});
</script>

<style lang="scss" scoped>
@use 'sass:color' as sasscolor;
@use '@/assets/scss/_colors.scss' as color;
@use '@/assets/scss/_breakpoints.scss' as breakpoint;
@use '@/assets/scss/_viewport.scss' as viewport;

.component.multiselect.entry {
  :deep() {
    > .multiselect-spinner {
      background-color: color.$brown-2;
    }

    > .multiselect-dropdown {
      margin: initial;
      margin-left: initial;
      margin-right: initial;
      margin-bottom: initial;
      margin-top: initial;
      left: initial;
      right: initial;
      top: initial;
      bottom: initial;
      position: absolute;

      > .multiselect-options {
        max-height: initial !important;
      }
    }

    > .multiselect-placeholder {
      white-space: nowrap;
      overflow: hidden;
      max-width: 90%;
    }
  }
}
</style>
