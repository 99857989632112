import { FileType } from './attachment';
import { SelectListItemType } from './logged';

export type AssessmentQuestionOption = {
  text: string;
  uuid: string;
};

export type AssessmentDetails = {
  assessmentId: string;
  title: string;
  description: string;
  noOfQuestions: number;
  duration: number;
  hasAttemptedAssessment: boolean;
  questionsType: string;
  questions: {
    id: string;
    text: string;
    answerOptions: {
      uuid: string;
      text: string;
    }[];
    questionType: string;
    difficultyLevel: string;
  }[];
};

export enum AssessmentQuestionDifficultyLevels {
  EASY = 'Easy',
  INTERMEDIATE = 'Intermediate',
  DIFFICULT = 'Difficult',
}

export enum AssessmentQuestionTypes {
  MULTICHOICE = 'multichoice',
  PARAGRAPH = 'paragraph',
  HANDS_ON_CODING = 'hands-on-coding',
  MULTICHOICE_WITH_HANDS_ON_CODING = 'multichoice-with-hands-on-coding',
}

export type TestCase = {
  expectedValue: unknown;
  /** This is in bytes. */
  memoryLimit: number;
  /** This is in miliseconds. */
  timeLimit: number;
};

export enum TestCaseInputValueTypes {
  ARRAY = 'Array',
  OBJECT = 'Object',
  STRING = 'String',
  MULTIPLE_ARGUMENTS = 'Multiple_arguments',
}

export enum AssessmentQuestionPurpose {
  PRACTISE = 'practise',
  ASSESSMENT = 'assessment',
  CUSTOM = 'custom',
}

export interface AssessmentQuestion {
  id: string;
  text: string;
  assessmentId: string;
  answerOptions: AssessmentQuestionOption[] | undefined;
  /**
   * The correct answer is a uuid of the answer option
   */
  correctAnswer?: string;
  difficultyLevel: AssessmentQuestionDifficultyLevels;
  testCases: TestCase[] | null;
  solutionsTemplates: {
    codingExecutionEngineLanguageId: number;
    template: string;
    solutionExecutionSnippet: string;
  }[];
  questionType: AssessmentQuestionTypes;
  tags: string[] | undefined;
  purpose: AssessmentQuestionPurpose;
  customType?: string;
  isCustomQuestion?: boolean;
  type?: string;
  uuid?: string;
}

export type Assessment = {
  id: string;
  title: string;
  description?: string;
  duration: number;
  languagesAllowed: { id: number; name: string }[];
  assessmentQuestions?: AssessmentQuestion[];
  selectedCandidates: string[];
  hasAttemptedAssessment: boolean;
};

export type MonacoLanguageType =
  | 'plaintext'
  | 'abap'
  | 'apex'
  | 'azcli'
  | 'bat'
  | 'bicep'
  | 'cameligo'
  | 'clojure'
  | 'coffeescript'
  | 'c'
  | 'cpp'
  | 'csharp'
  | 'csp'
  | 'css'
  | 'cypher'
  | 'dart'
  | 'dockerfile'
  | 'ecl'
  | 'elixir'
  | 'flow9'
  | 'fsharp'
  | 'freemarker2'
  | 'go'
  | 'graphql'
  | 'handlebars'
  | 'hcl'
  | 'html'
  | 'ini'
  | 'java'
  | 'javascript'
  | 'julia'
  | 'kotlin'
  | 'less'
  | 'lexon'
  | 'lua'
  | 'liquid'
  | 'm3'
  | 'markdown'
  | 'nips'
  | 'msdax'
  | 'mysql'
  | 'objective-c'
  | 'pascal'
  | 'pascaligo'
  | 'perl'
  | 'pgsql'
  | 'php'
  | 'pla'
  | 'postiats'
  | 'powerquery'
  | 'powershell'
  | 'proto'
  | 'pug'
  | 'python'
  | 'qsharp'
  | 'r'
  | 'razor'
  | 'redis'
  | 'redshift'
  | 'restructuredtext'
  | 'ruby'
  | 'rust'
  | 'sb'
  | 'scala'
  | 'scheme'
  | 'scss'
  | 'shell'
  | 'sol'
  | 'aes'
  | 'sparql'
  | 'sql'
  | 'st'
  | 'swift'
  | 'systemverilog'
  | 'verilog'
  | 'tcl'
  | 'twig'
  | 'typescript'
  | 'vb'
  | 'xml'
  | 'json';

export enum IDEAssessmentCodeCompiler {
  JUDGE_ZERO = 'judge-zero',
  ONE_COMPILER = 'onecompiler',
}

export type IDEAssessmentTestCase = {
  uniqueId: string;
  id: number;
  value: string;
  result: string;
  weight: number;
  languageIdentifier: string;
  isVerified: boolean;
  useResultFromCompilation: boolean;
  benchmarkedMemory?: number;
  benchmarkedTime?: string;
};

export type IDEAssessmentLanguageSolution = {
  solutionTemplate: string;
  talentEditorData: string;
  languageIdentifier: string;
  compiler: IDEAssessmentCodeCompiler;
  monacoLanguage: MonacoLanguageType;
};

export enum JudgeZeroStatus {
  In_QUEUE = 1,
  PROCESSING = 2,
  ACCEPTED = 3,
  WRONG_ANSWER = 4,
  TIME_LIMIT_EXCEEDED = 5,
  COMPILATION_ERROR = 6,
  RUNTIME_ERROR_SIGSEGV = 7,
  RUNTIME_ERROR_SIGXFSZ = 8,
  RUNTIME_ERROR_SIGFPE = 9,
  RUNTIME_ERROR_SIGABRT = 10,
  RUNTIME_ERROR_NZEC = 11,
  RUNTIME_ERROR_OTHER = 12,
  INTERNAL_ERROR = 13,
  EXEC_FORMAT_ERROR = 14,
}

export enum CodeCompiler {
  JUDGE_ZERO = 'judge-zero',
  ONE_COMPILER = 'onecompiler',
}

export type DefaultCodeSolutionEvaluation = {
  assessmentQuestionId: string;
  codeSolution: {
    name: string;
    content: string;
  }[];
  languageId: string;
  score: number;
  codeLines: number;
  codeSimilarityCoefficient: number;
  scorePercentile: number;
  timeUsed: string | number; // In seconds
  startDate: string | Date;
  endDate: string | Date;
  // Extras
  assessmentQuestion?: Pick<
    AssessmentQuestion,
    'text' | 'difficultyLevel' | 'questionType'
  > & {
    skills: SelectListItemType[];
    languages: {
      name: string;
      compiler: CodeCompiler;
      languageId: string;
      monacoLanguageName: MonacoLanguageType;
    };
  };
  language?: {
    name: string;
    compiler: CodeCompiler;
    languageId: string;
    monacoLanguageName: MonacoLanguageType;
  };
  screenRecordingFileUrls?: string[];
};

export type JudgeZeroCodeSolutionEvaluation =
  DefaultCodeSolutionEvaluation & {
    compiler: CodeCompiler.JUDGE_ZERO;
    tests?: {
      value: unknown;
      weight: 1 | number;
      expectedOutput: string;
      codeSolutionExecutionOutput: string;
      memoryUsed: number;
      timeTaken: string;
      errOutput: string | null;
      executionOutput?: string | null;
      compileOutput?: string | null;
      status?: {
        id: number;
        description: string;
      };
      status_id?: JudgeZeroStatus;
      systemInfo?: Record<string, string>;
    }[];
  };

export type OneCompilerCodeSolutionEvaluation =
  DefaultCodeSolutionEvaluation & {
    compiler: CodeCompiler.ONE_COMPILER;
  };

export type CodeSolutionEvaluation =
  | JudgeZeroCodeSolutionEvaluation
  | OneCompilerCodeSolutionEvaluation;

export type CodeAssessmentResultDetails = {
  compiler: CodeCompiler;
  compilerAssessmentData: Record<string, unknown>;
  codeEvaluation: CodeSolutionEvaluation[];
  timeUsed: string | number; // In seconds
  screenRecordingFileId?: string | number;
  talentRecordingFileId?: string | number;
  expectedTotalScoreForAssessment: number | string;
  score: number | string;
  // Extras
  screenRecordingFileUrls?: (string | FileType)[];
  talentRecordingFileUrls?: (string | FileType)[];
  talentSnapshotFiles?: FileType[];
};
