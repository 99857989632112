import { default as _91_46_46_46slug_93q2JjB0jTyJMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/[...slug].vue?macro=true";
import { default as acceptable_45useJmfEaATPxgMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/acceptable-use.vue?macro=true";
import { default as bank_45accountsWpUV6aGiuFMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/bank-accounts.vue?macro=true";
import { default as billingsHHEnyD7ykBMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/billings.vue?macro=true";
import { default as exchange_45ratespnr96wLHi9Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/exchange-rates.vue?macro=true";
import { default as offline_45paymentsQ0T4beYgtaMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/offline-payments.vue?macro=true";
import { default as online_45payments9CNALzfNZMMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/online-payments.vue?macro=true";
import { default as paymentstg1qsUDl5lMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments.vue?macro=true";
import { default as pricingYkb2z4nELAMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/pricing.vue?macro=true";
import { default as adminXjN1iAMnfeMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin.vue?macro=true";
import { default as create_45from_45libraryvalwQZNJV7Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments/create-from-library.vue?macro=true";
import { default as createOsQpNmYC32Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments/ide/create.vue?macro=true";
import { default as overviewRtU28z3cp4Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments/overview.vue?macro=true";
import { default as assessmentsXD9tQcj5QhMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments.vue?macro=true";
import { default as _91profileId_930SQjdHAU2PMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/companyprofile/[profileId].vue?macro=true";
import { default as connectionsMvgBaNZOs1Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/connections.vue?macro=true";
import { default as followersHRlMaiCN9tMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/followers.vue?macro=true";
import { default as followsZ2mO2vl7PRMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/follows.vue?macro=true";
import { default as home7MCjce4TrZMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/home.vue?macro=true";
import { default as indexR9l5nIw9CqMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/index.vue?macro=true";
import { default as suggestedconnectionskf0YnoQtpMMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/suggestedconnections.vue?macro=true";
import { default as suggestedfollowsbkIys17qMCMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/suggestedfollows.vue?macro=true";
import { default as _91profileId_938lo0hoARvkMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/talentprofile/[profileId].vue?macro=true";
import { default as communityLlZOAfc3fRMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community.vue?macro=true";
import { default as createlQ3SUaPb53Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/create.vue?macro=true";
import { default as createmUGjnfm0IGMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/departments/create.vue?macro=true";
import { default as _91id_93PH0VoTG9kiMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/departments/edit/[id].vue?macro=true";
import { default as index4LEcEQz7dgMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/departments/index.vue?macro=true";
import { default as editRYIvALCdR4Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/edit.vue?macro=true";
import { default as indexh9IpHAewwrMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/index.vue?macro=true";
import { default as settingsD5Zb5TizsyMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/settings.vue?macro=true";
import { default as createUORHNQysPPMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/talent/create.vue?macro=true";
import { default as _91email_93gJgvTaZ9LkMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/users/[email].vue?macro=true";
import { default as createwyTowkbxlJMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/users/create.vue?macro=true";
import { default as indexpIYlfkx2MnMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/users/index.vue?macro=true";
import { default as index6LF9VMf2EkMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/custom-messages/index.vue?macro=true";
import { default as sent_messages4MAAkcqdhnMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/custom-messages/sent_messages.vue?macro=true";
import { default as dashboardvNl41CVAJEMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/dashboard.vue?macro=true";
import { default as additional_45storageApxYwRuOJoMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/addons/additional-storage.vue?macro=true";
import { default as assessmentzHhdFMgLCfMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/addons/assessment.vue?macro=true";
import { default as addonsIyh6hglPMAMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/addons.vue?macro=true";
import { default as indexz692GwFLJ5Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/index.vue?macro=true";
import { default as subscriptionsguukrvMNE4Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/subscriptions.vue?macro=true";
import { default as wallet6JQu2kpR4oMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/wallet.vue?macro=true";
import { default as find_45a_45role4dKQM7L3mpMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/find-a-role.vue?macro=true";
import { default as forgot_45passwordFP1GR10EfXMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/forgot-password.vue?macro=true";
import { default as GlobalSearchUVy5gHrlooMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/GlobalSearch.vue?macro=true";
import { default as index8O9qC2ozJWMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/index.vue?macro=true";
import { default as createMzSunms4HNMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/interviews/create.vue?macro=true";
import { default as indexxZES5wokq9Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/interviews/index.vue?macro=true";
import { default as applicationUwYXxj8pdvMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/job/[jobTitle]--[jobUniqueId]/application.vue?macro=true";
import { default as indext7CvycbaVqMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/job/[jobTitle]--[jobUniqueId]/index.vue?macro=true";
import { default as create3vaqngZepyMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/job/create.vue?macro=true";
import { default as index5sgzEw3YFfMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/jobs/[jobTitle]--[jobUniqueId]/index.vue?macro=true";
import { default as jobs1cRuoxVH1SMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/jobs.vue?macro=true";
import { default as login3hbKaJnYSuMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/login.vue?macro=true";
import { default as mezc5gxZNZrgMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/me.vue?macro=true";
import { default as messagessG0X5drnKtMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/messages.vue?macro=true";
import { default as pricing_45plansfxj2Ae1aaaMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/pricing-plans.vue?macro=true";
import { default as pricingOzjys0pgQyMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/pricing.vue?macro=true";
import { default as privacyct035KUvKiMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/privacy.vue?macro=true";
import { default as editdNZPQBE881Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/company/edit.vue?macro=true";
import { default as indexmMgK3RL4oGMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/company/index.vue?macro=true";
import { default as edit12lwxi4pWuMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/edit.vue?macro=true";
import { default as createRiNf3xOdE3Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/talent/create.vue?macro=true";
import { default as viewt35zqPW7hHMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/view.vue?macro=true";
import { default as profileseDPPjyezIJMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profiles.vue?macro=true";
import { default as candidatesZ8voDUaurBMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/referral/candidates.vue?macro=true";
import { default as dashboardCX4WriN86xMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/referral/dashboard.vue?macro=true";
import { default as jobsiswE9BCksZMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/referral/jobs.vue?macro=true";
import { default as registerG4zSREzrE9Meta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/register.vue?macro=true";
import { default as talent_45settingsrdsZCtBblgMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/talent-settings.vue?macro=true";
import { default as talentskSoG6oCIYuMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/talents.vue?macro=true";
import { default as termsLEyfL24upgMeta } from "/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/terms.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93q2JjB0jTyJMeta?.name ?? "slug",
    path: _91_46_46_46slug_93q2JjB0jTyJMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93q2JjB0jTyJMeta || {},
    alias: _91_46_46_46slug_93q2JjB0jTyJMeta?.alias || [],
    redirect: _91_46_46_46slug_93q2JjB0jTyJMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: acceptable_45useJmfEaATPxgMeta?.name ?? "acceptable-use",
    path: acceptable_45useJmfEaATPxgMeta?.path ?? "/acceptable-use",
    meta: acceptable_45useJmfEaATPxgMeta || {},
    alias: acceptable_45useJmfEaATPxgMeta?.alias || [],
    redirect: acceptable_45useJmfEaATPxgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/acceptable-use.vue").then(m => m.default || m)
  },
  {
    name: adminXjN1iAMnfeMeta?.name ?? "admin",
    path: adminXjN1iAMnfeMeta?.path ?? "/admin",
    children: [
  {
    name: paymentstg1qsUDl5lMeta?.name ?? "admin-payments",
    path: paymentstg1qsUDl5lMeta?.path ?? "payments",
    children: [
  {
    name: bank_45accountsWpUV6aGiuFMeta?.name ?? "admin-payments-bank-accounts",
    path: bank_45accountsWpUV6aGiuFMeta?.path ?? "bank-accounts",
    meta: bank_45accountsWpUV6aGiuFMeta || {},
    alias: bank_45accountsWpUV6aGiuFMeta?.alias || [],
    redirect: bank_45accountsWpUV6aGiuFMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/bank-accounts.vue").then(m => m.default || m)
  },
  {
    name: billingsHHEnyD7ykBMeta?.name ?? "admin-payments-billings",
    path: billingsHHEnyD7ykBMeta?.path ?? "billings",
    meta: billingsHHEnyD7ykBMeta || {},
    alias: billingsHHEnyD7ykBMeta?.alias || [],
    redirect: billingsHHEnyD7ykBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/billings.vue").then(m => m.default || m)
  },
  {
    name: exchange_45ratespnr96wLHi9Meta?.name ?? "admin-payments-exchange-rates",
    path: exchange_45ratespnr96wLHi9Meta?.path ?? "exchange-rates",
    meta: exchange_45ratespnr96wLHi9Meta || {},
    alias: exchange_45ratespnr96wLHi9Meta?.alias || [],
    redirect: exchange_45ratespnr96wLHi9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/exchange-rates.vue").then(m => m.default || m)
  },
  {
    name: offline_45paymentsQ0T4beYgtaMeta?.name ?? "admin-payments-offline-payments",
    path: offline_45paymentsQ0T4beYgtaMeta?.path ?? "offline-payments",
    meta: offline_45paymentsQ0T4beYgtaMeta || {},
    alias: offline_45paymentsQ0T4beYgtaMeta?.alias || [],
    redirect: offline_45paymentsQ0T4beYgtaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/offline-payments.vue").then(m => m.default || m)
  },
  {
    name: online_45payments9CNALzfNZMMeta?.name ?? "admin-payments-online-payments",
    path: online_45payments9CNALzfNZMMeta?.path ?? "online-payments",
    meta: online_45payments9CNALzfNZMMeta || {},
    alias: online_45payments9CNALzfNZMMeta?.alias || [],
    redirect: online_45payments9CNALzfNZMMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments/online-payments.vue").then(m => m.default || m)
  }
],
    meta: paymentstg1qsUDl5lMeta || {},
    alias: paymentstg1qsUDl5lMeta?.alias || [],
    redirect: paymentstg1qsUDl5lMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/payments.vue").then(m => m.default || m)
  },
  {
    name: pricingYkb2z4nELAMeta?.name ?? "admin-pricing",
    path: pricingYkb2z4nELAMeta?.path ?? "pricing",
    meta: pricingYkb2z4nELAMeta || {},
    alias: pricingYkb2z4nELAMeta?.alias || [],
    redirect: pricingYkb2z4nELAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin/pricing.vue").then(m => m.default || m)
  }
],
    meta: adminXjN1iAMnfeMeta || {},
    alias: adminXjN1iAMnfeMeta?.alias || [],
    redirect: adminXjN1iAMnfeMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: assessmentsXD9tQcj5QhMeta?.name ?? "assessments",
    path: assessmentsXD9tQcj5QhMeta?.path ?? "/assessments",
    children: [
  {
    name: create_45from_45libraryvalwQZNJV7Meta?.name ?? "assessments-create-from-library",
    path: create_45from_45libraryvalwQZNJV7Meta?.path ?? "create-from-library",
    meta: create_45from_45libraryvalwQZNJV7Meta || {},
    alias: create_45from_45libraryvalwQZNJV7Meta?.alias || [],
    redirect: create_45from_45libraryvalwQZNJV7Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments/create-from-library.vue").then(m => m.default || m)
  },
  {
    name: createOsQpNmYC32Meta?.name ?? "assessments-ide-create",
    path: createOsQpNmYC32Meta?.path ?? "ide/create",
    meta: createOsQpNmYC32Meta || {},
    alias: createOsQpNmYC32Meta?.alias || [],
    redirect: createOsQpNmYC32Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments/ide/create.vue").then(m => m.default || m)
  },
  {
    name: overviewRtU28z3cp4Meta?.name ?? "assessments-overview",
    path: overviewRtU28z3cp4Meta?.path ?? "overview",
    meta: overviewRtU28z3cp4Meta || {},
    alias: overviewRtU28z3cp4Meta?.alias || [],
    redirect: overviewRtU28z3cp4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments/overview.vue").then(m => m.default || m)
  }
],
    meta: assessmentsXD9tQcj5QhMeta || {},
    alias: assessmentsXD9tQcj5QhMeta?.alias || [],
    redirect: assessmentsXD9tQcj5QhMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/assessments.vue").then(m => m.default || m)
  },
  {
    path: communityLlZOAfc3fRMeta?.path ?? "/community",
    children: [
  {
    name: _91profileId_930SQjdHAU2PMeta?.name ?? "community-companyprofile-profileId",
    path: _91profileId_930SQjdHAU2PMeta?.path ?? "companyprofile/:profileId()",
    meta: _91profileId_930SQjdHAU2PMeta || {},
    alias: _91profileId_930SQjdHAU2PMeta?.alias || [],
    redirect: _91profileId_930SQjdHAU2PMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/companyprofile/[profileId].vue").then(m => m.default || m)
  },
  {
    name: connectionsMvgBaNZOs1Meta?.name ?? "community-connections",
    path: connectionsMvgBaNZOs1Meta?.path ?? "connections",
    meta: connectionsMvgBaNZOs1Meta || {},
    alias: connectionsMvgBaNZOs1Meta?.alias || [],
    redirect: connectionsMvgBaNZOs1Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/connections.vue").then(m => m.default || m)
  },
  {
    name: followersHRlMaiCN9tMeta?.name ?? "community-followers",
    path: followersHRlMaiCN9tMeta?.path ?? "followers",
    meta: followersHRlMaiCN9tMeta || {},
    alias: followersHRlMaiCN9tMeta?.alias || [],
    redirect: followersHRlMaiCN9tMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/followers.vue").then(m => m.default || m)
  },
  {
    name: followsZ2mO2vl7PRMeta?.name ?? "community-follows",
    path: followsZ2mO2vl7PRMeta?.path ?? "follows",
    meta: followsZ2mO2vl7PRMeta || {},
    alias: followsZ2mO2vl7PRMeta?.alias || [],
    redirect: followsZ2mO2vl7PRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/follows.vue").then(m => m.default || m)
  },
  {
    name: home7MCjce4TrZMeta?.name ?? "community-home",
    path: home7MCjce4TrZMeta?.path ?? "home",
    meta: home7MCjce4TrZMeta || {},
    alias: home7MCjce4TrZMeta?.alias || [],
    redirect: home7MCjce4TrZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/home.vue").then(m => m.default || m)
  },
  {
    name: indexR9l5nIw9CqMeta?.name ?? "community",
    path: indexR9l5nIw9CqMeta?.path ?? "",
    meta: indexR9l5nIw9CqMeta || {},
    alias: indexR9l5nIw9CqMeta?.alias || [],
    redirect: indexR9l5nIw9CqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: suggestedconnectionskf0YnoQtpMMeta?.name ?? "community-suggestedconnections",
    path: suggestedconnectionskf0YnoQtpMMeta?.path ?? "suggestedconnections",
    meta: suggestedconnectionskf0YnoQtpMMeta || {},
    alias: suggestedconnectionskf0YnoQtpMMeta?.alias || [],
    redirect: suggestedconnectionskf0YnoQtpMMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/suggestedconnections.vue").then(m => m.default || m)
  },
  {
    name: suggestedfollowsbkIys17qMCMeta?.name ?? "community-suggestedfollows",
    path: suggestedfollowsbkIys17qMCMeta?.path ?? "suggestedfollows",
    meta: suggestedfollowsbkIys17qMCMeta || {},
    alias: suggestedfollowsbkIys17qMCMeta?.alias || [],
    redirect: suggestedfollowsbkIys17qMCMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/suggestedfollows.vue").then(m => m.default || m)
  },
  {
    name: _91profileId_938lo0hoARvkMeta?.name ?? "community-talentprofile-profileId",
    path: _91profileId_938lo0hoARvkMeta?.path ?? "talentprofile/:profileId()",
    meta: _91profileId_938lo0hoARvkMeta || {},
    alias: _91profileId_938lo0hoARvkMeta?.alias || [],
    redirect: _91profileId_938lo0hoARvkMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community/talentprofile/[profileId].vue").then(m => m.default || m)
  }
],
    name: communityLlZOAfc3fRMeta?.name ?? undefined,
    meta: communityLlZOAfc3fRMeta || {},
    alias: communityLlZOAfc3fRMeta?.alias || [],
    redirect: communityLlZOAfc3fRMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/community.vue").then(m => m.default || m)
  },
  {
    name: createlQ3SUaPb53Meta?.name ?? "company-create",
    path: createlQ3SUaPb53Meta?.path ?? "/company/create",
    meta: createlQ3SUaPb53Meta || {},
    alias: createlQ3SUaPb53Meta?.alias || [],
    redirect: createlQ3SUaPb53Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/create.vue").then(m => m.default || m)
  },
  {
    name: createmUGjnfm0IGMeta?.name ?? "company-departments-create",
    path: createmUGjnfm0IGMeta?.path ?? "/company/departments/create",
    meta: createmUGjnfm0IGMeta || {},
    alias: createmUGjnfm0IGMeta?.alias || [],
    redirect: createmUGjnfm0IGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/departments/create.vue").then(m => m.default || m)
  },
  {
    name: _91id_93PH0VoTG9kiMeta?.name ?? "company-departments-edit-id",
    path: _91id_93PH0VoTG9kiMeta?.path ?? "/company/departments/edit/:id()",
    meta: _91id_93PH0VoTG9kiMeta || {},
    alias: _91id_93PH0VoTG9kiMeta?.alias || [],
    redirect: _91id_93PH0VoTG9kiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/departments/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: index4LEcEQz7dgMeta?.name ?? "company-departments",
    path: index4LEcEQz7dgMeta?.path ?? "/company/departments",
    meta: index4LEcEQz7dgMeta || {},
    alias: index4LEcEQz7dgMeta?.alias || [],
    redirect: index4LEcEQz7dgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/departments/index.vue").then(m => m.default || m)
  },
  {
    name: editRYIvALCdR4Meta?.name ?? "company-edit",
    path: editRYIvALCdR4Meta?.path ?? "/company/edit",
    meta: editRYIvALCdR4Meta || {},
    alias: editRYIvALCdR4Meta?.alias || [],
    redirect: editRYIvALCdR4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/edit.vue").then(m => m.default || m)
  },
  {
    name: indexh9IpHAewwrMeta?.name ?? "company",
    path: indexh9IpHAewwrMeta?.path ?? "/company",
    meta: indexh9IpHAewwrMeta || {},
    alias: indexh9IpHAewwrMeta?.alias || [],
    redirect: indexh9IpHAewwrMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: settingsD5Zb5TizsyMeta?.name ?? "company-settings",
    path: settingsD5Zb5TizsyMeta?.path ?? "/company/settings",
    meta: settingsD5Zb5TizsyMeta || {},
    alias: settingsD5Zb5TizsyMeta?.alias || [],
    redirect: settingsD5Zb5TizsyMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/settings.vue").then(m => m.default || m)
  },
  {
    name: createUORHNQysPPMeta?.name ?? "company-talent-create",
    path: createUORHNQysPPMeta?.path ?? "/company/talent/create",
    meta: createUORHNQysPPMeta || {},
    alias: createUORHNQysPPMeta?.alias || [],
    redirect: createUORHNQysPPMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/talent/create.vue").then(m => m.default || m)
  },
  {
    name: _91email_93gJgvTaZ9LkMeta?.name ?? "company-users-email",
    path: _91email_93gJgvTaZ9LkMeta?.path ?? "/company/users/:email()",
    meta: _91email_93gJgvTaZ9LkMeta || {},
    alias: _91email_93gJgvTaZ9LkMeta?.alias || [],
    redirect: _91email_93gJgvTaZ9LkMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/users/[email].vue").then(m => m.default || m)
  },
  {
    name: createwyTowkbxlJMeta?.name ?? "company-users-create",
    path: createwyTowkbxlJMeta?.path ?? "/company/users/create",
    meta: createwyTowkbxlJMeta || {},
    alias: createwyTowkbxlJMeta?.alias || [],
    redirect: createwyTowkbxlJMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexpIYlfkx2MnMeta?.name ?? "company-users",
    path: indexpIYlfkx2MnMeta?.path ?? "/company/users",
    meta: indexpIYlfkx2MnMeta || {},
    alias: indexpIYlfkx2MnMeta?.alias || [],
    redirect: indexpIYlfkx2MnMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/company/users/index.vue").then(m => m.default || m)
  },
  {
    name: index6LF9VMf2EkMeta?.name ?? "custom-messages",
    path: index6LF9VMf2EkMeta?.path ?? "/custom-messages",
    meta: index6LF9VMf2EkMeta || {},
    alias: index6LF9VMf2EkMeta?.alias || [],
    redirect: index6LF9VMf2EkMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/custom-messages/index.vue").then(m => m.default || m)
  },
  {
    name: sent_messages4MAAkcqdhnMeta?.name ?? "custom-messages-sent_messages",
    path: sent_messages4MAAkcqdhnMeta?.path ?? "/custom-messages/sent_messages",
    meta: sent_messages4MAAkcqdhnMeta || {},
    alias: sent_messages4MAAkcqdhnMeta?.alias || [],
    redirect: sent_messages4MAAkcqdhnMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/custom-messages/sent_messages.vue").then(m => m.default || m)
  },
  {
    name: dashboardvNl41CVAJEMeta?.name ?? "dashboard",
    path: dashboardvNl41CVAJEMeta?.path ?? "/dashboard",
    meta: dashboardvNl41CVAJEMeta || {},
    alias: dashboardvNl41CVAJEMeta?.alias || [],
    redirect: dashboardvNl41CVAJEMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: addonsIyh6hglPMAMeta?.name ?? "finance-addons",
    path: addonsIyh6hglPMAMeta?.path ?? "/finance/addons",
    children: [
  {
    name: additional_45storageApxYwRuOJoMeta?.name ?? "finance-addons-additional-storage",
    path: additional_45storageApxYwRuOJoMeta?.path ?? "additional-storage",
    meta: additional_45storageApxYwRuOJoMeta || {},
    alias: additional_45storageApxYwRuOJoMeta?.alias || [],
    redirect: additional_45storageApxYwRuOJoMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/addons/additional-storage.vue").then(m => m.default || m)
  },
  {
    name: assessmentzHhdFMgLCfMeta?.name ?? "finance-addons-assessment",
    path: assessmentzHhdFMgLCfMeta?.path ?? "assessment",
    meta: assessmentzHhdFMgLCfMeta || {},
    alias: assessmentzHhdFMgLCfMeta?.alias || [],
    redirect: assessmentzHhdFMgLCfMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/addons/assessment.vue").then(m => m.default || m)
  }
],
    meta: addonsIyh6hglPMAMeta || {},
    alias: addonsIyh6hglPMAMeta?.alias || [],
    redirect: addonsIyh6hglPMAMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/addons.vue").then(m => m.default || m)
  },
  {
    name: indexz692GwFLJ5Meta?.name ?? "finance",
    path: indexz692GwFLJ5Meta?.path ?? "/finance",
    meta: indexz692GwFLJ5Meta || {},
    alias: indexz692GwFLJ5Meta?.alias || [],
    redirect: indexz692GwFLJ5Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/index.vue").then(m => m.default || m)
  },
  {
    name: subscriptionsguukrvMNE4Meta?.name ?? "finance-subscriptions",
    path: subscriptionsguukrvMNE4Meta?.path ?? "/finance/subscriptions",
    meta: subscriptionsguukrvMNE4Meta || {},
    alias: subscriptionsguukrvMNE4Meta?.alias || [],
    redirect: subscriptionsguukrvMNE4Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: wallet6JQu2kpR4oMeta?.name ?? "finance-wallet",
    path: wallet6JQu2kpR4oMeta?.path ?? "/finance/wallet",
    meta: wallet6JQu2kpR4oMeta || {},
    alias: wallet6JQu2kpR4oMeta?.alias || [],
    redirect: wallet6JQu2kpR4oMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/finance/wallet.vue").then(m => m.default || m)
  },
  {
    name: find_45a_45role4dKQM7L3mpMeta?.name ?? "find-a-role",
    path: find_45a_45role4dKQM7L3mpMeta?.path ?? "/find-a-role",
    meta: find_45a_45role4dKQM7L3mpMeta || {},
    alias: find_45a_45role4dKQM7L3mpMeta?.alias || [],
    redirect: find_45a_45role4dKQM7L3mpMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/find-a-role.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordFP1GR10EfXMeta?.name ?? "forgot-password",
    path: forgot_45passwordFP1GR10EfXMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordFP1GR10EfXMeta || {},
    alias: forgot_45passwordFP1GR10EfXMeta?.alias || [],
    redirect: forgot_45passwordFP1GR10EfXMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: GlobalSearchUVy5gHrlooMeta?.name ?? "GlobalSearch",
    path: GlobalSearchUVy5gHrlooMeta?.path ?? "/GlobalSearch",
    meta: GlobalSearchUVy5gHrlooMeta || {},
    alias: GlobalSearchUVy5gHrlooMeta?.alias || [],
    redirect: GlobalSearchUVy5gHrlooMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/GlobalSearch.vue").then(m => m.default || m)
  },
  {
    name: index8O9qC2ozJWMeta?.name ?? "index",
    path: index8O9qC2ozJWMeta?.path ?? "/",
    meta: index8O9qC2ozJWMeta || {},
    alias: index8O9qC2ozJWMeta?.alias || [],
    redirect: index8O9qC2ozJWMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/index.vue").then(m => m.default || m)
  },
  {
    name: createMzSunms4HNMeta?.name ?? "interviews-create",
    path: createMzSunms4HNMeta?.path ?? "/interviews/create",
    meta: createMzSunms4HNMeta || {},
    alias: createMzSunms4HNMeta?.alias || [],
    redirect: createMzSunms4HNMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/interviews/create.vue").then(m => m.default || m)
  },
  {
    name: indexxZES5wokq9Meta?.name ?? "interviews",
    path: indexxZES5wokq9Meta?.path ?? "/interviews",
    meta: indexxZES5wokq9Meta || {},
    alias: indexxZES5wokq9Meta?.alias || [],
    redirect: indexxZES5wokq9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/interviews/index.vue").then(m => m.default || m)
  },
  {
    name: applicationUwYXxj8pdvMeta?.name ?? "job-jobTitle--jobUniqueId-application",
    path: applicationUwYXxj8pdvMeta?.path ?? "/job/:jobTitle()--:jobUniqueId()/application",
    meta: applicationUwYXxj8pdvMeta || {},
    alias: applicationUwYXxj8pdvMeta?.alias || [],
    redirect: applicationUwYXxj8pdvMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/job/[jobTitle]--[jobUniqueId]/application.vue").then(m => m.default || m)
  },
  {
    name: indext7CvycbaVqMeta?.name ?? "job-jobTitle--jobUniqueId",
    path: indext7CvycbaVqMeta?.path ?? "/job/:jobTitle()--:jobUniqueId()",
    meta: indext7CvycbaVqMeta || {},
    alias: indext7CvycbaVqMeta?.alias || [],
    redirect: indext7CvycbaVqMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/job/[jobTitle]--[jobUniqueId]/index.vue").then(m => m.default || m)
  },
  {
    name: create3vaqngZepyMeta?.name ?? "job-create",
    path: create3vaqngZepyMeta?.path ?? "/job/create",
    meta: create3vaqngZepyMeta || {},
    alias: create3vaqngZepyMeta?.alias || [],
    redirect: create3vaqngZepyMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/job/create.vue").then(m => m.default || m)
  },
  {
    name: jobs1cRuoxVH1SMeta?.name ?? "jobs",
    path: jobs1cRuoxVH1SMeta?.path ?? "/jobs",
    children: [
  {
    name: index5sgzEw3YFfMeta?.name ?? "jobs-jobTitle--jobUniqueId",
    path: index5sgzEw3YFfMeta?.path ?? ":jobTitle()--:jobUniqueId()",
    meta: index5sgzEw3YFfMeta || {},
    alias: index5sgzEw3YFfMeta?.alias || [],
    redirect: index5sgzEw3YFfMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/jobs/[jobTitle]--[jobUniqueId]/index.vue").then(m => m.default || m)
  }
],
    meta: jobs1cRuoxVH1SMeta || {},
    alias: jobs1cRuoxVH1SMeta?.alias || [],
    redirect: jobs1cRuoxVH1SMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: login3hbKaJnYSuMeta?.name ?? "login",
    path: login3hbKaJnYSuMeta?.path ?? "/login",
    meta: login3hbKaJnYSuMeta || {},
    alias: login3hbKaJnYSuMeta?.alias || [],
    redirect: login3hbKaJnYSuMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mezc5gxZNZrgMeta?.name ?? "me",
    path: mezc5gxZNZrgMeta?.path ?? "/me",
    meta: mezc5gxZNZrgMeta || {},
    alias: mezc5gxZNZrgMeta?.alias || [],
    redirect: mezc5gxZNZrgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/me.vue").then(m => m.default || m)
  },
  {
    name: messagessG0X5drnKtMeta?.name ?? "messages",
    path: messagessG0X5drnKtMeta?.path ?? "/messages",
    meta: messagessG0X5drnKtMeta || {},
    alias: messagessG0X5drnKtMeta?.alias || [],
    redirect: messagessG0X5drnKtMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/messages.vue").then(m => m.default || m)
  },
  {
    name: pricing_45plansfxj2Ae1aaaMeta?.name ?? "pricing-plans",
    path: pricing_45plansfxj2Ae1aaaMeta?.path ?? "/pricing-plans",
    meta: pricing_45plansfxj2Ae1aaaMeta || {},
    alias: pricing_45plansfxj2Ae1aaaMeta?.alias || [],
    redirect: pricing_45plansfxj2Ae1aaaMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/pricing-plans.vue").then(m => m.default || m)
  },
  {
    name: pricingOzjys0pgQyMeta?.name ?? "pricing",
    path: pricingOzjys0pgQyMeta?.path ?? "/pricing",
    meta: pricingOzjys0pgQyMeta || {},
    alias: pricingOzjys0pgQyMeta?.alias || [],
    redirect: pricingOzjys0pgQyMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacyct035KUvKiMeta?.name ?? "privacy",
    path: privacyct035KUvKiMeta?.path ?? "/privacy",
    meta: privacyct035KUvKiMeta || {},
    alias: privacyct035KUvKiMeta?.alias || [],
    redirect: privacyct035KUvKiMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: editdNZPQBE881Meta?.name ?? "profile-company-edit",
    path: editdNZPQBE881Meta?.path ?? "/profile/company/edit",
    meta: editdNZPQBE881Meta || {},
    alias: editdNZPQBE881Meta?.alias || [],
    redirect: editdNZPQBE881Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/company/edit.vue").then(m => m.default || m)
  },
  {
    name: indexmMgK3RL4oGMeta?.name ?? "profile-company",
    path: indexmMgK3RL4oGMeta?.path ?? "/profile/company",
    meta: indexmMgK3RL4oGMeta || {},
    alias: indexmMgK3RL4oGMeta?.alias || [],
    redirect: indexmMgK3RL4oGMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/company/index.vue").then(m => m.default || m)
  },
  {
    name: edit12lwxi4pWuMeta?.name ?? "profile-edit",
    path: edit12lwxi4pWuMeta?.path ?? "/profile/edit",
    meta: edit12lwxi4pWuMeta || {},
    alias: edit12lwxi4pWuMeta?.alias || [],
    redirect: edit12lwxi4pWuMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: createRiNf3xOdE3Meta?.name ?? "profile-talent-create",
    path: createRiNf3xOdE3Meta?.path ?? "/profile/talent/create",
    meta: createRiNf3xOdE3Meta || {},
    alias: createRiNf3xOdE3Meta?.alias || [],
    redirect: createRiNf3xOdE3Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/talent/create.vue").then(m => m.default || m)
  },
  {
    name: viewt35zqPW7hHMeta?.name ?? "profile-view",
    path: viewt35zqPW7hHMeta?.path ?? "/profile/view",
    meta: viewt35zqPW7hHMeta || {},
    alias: viewt35zqPW7hHMeta?.alias || [],
    redirect: viewt35zqPW7hHMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profile/view.vue").then(m => m.default || m)
  },
  {
    name: profileseDPPjyezIJMeta?.name ?? "profiles",
    path: profileseDPPjyezIJMeta?.path ?? "/profiles",
    meta: profileseDPPjyezIJMeta || {},
    alias: profileseDPPjyezIJMeta?.alias || [],
    redirect: profileseDPPjyezIJMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/profiles.vue").then(m => m.default || m)
  },
  {
    name: candidatesZ8voDUaurBMeta?.name ?? "referral-candidates",
    path: candidatesZ8voDUaurBMeta?.path ?? "/referral/candidates",
    meta: candidatesZ8voDUaurBMeta || {},
    alias: candidatesZ8voDUaurBMeta?.alias || [],
    redirect: candidatesZ8voDUaurBMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/referral/candidates.vue").then(m => m.default || m)
  },
  {
    name: dashboardCX4WriN86xMeta?.name ?? "referral-dashboard",
    path: dashboardCX4WriN86xMeta?.path ?? "/referral/dashboard",
    meta: dashboardCX4WriN86xMeta || {},
    alias: dashboardCX4WriN86xMeta?.alias || [],
    redirect: dashboardCX4WriN86xMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/referral/dashboard.vue").then(m => m.default || m)
  },
  {
    name: jobsiswE9BCksZMeta?.name ?? "referral-jobs",
    path: jobsiswE9BCksZMeta?.path ?? "/referral/jobs",
    meta: jobsiswE9BCksZMeta || {},
    alias: jobsiswE9BCksZMeta?.alias || [],
    redirect: jobsiswE9BCksZMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/referral/jobs.vue").then(m => m.default || m)
  },
  {
    name: registerG4zSREzrE9Meta?.name ?? "register",
    path: registerG4zSREzrE9Meta?.path ?? "/register",
    meta: registerG4zSREzrE9Meta || {},
    alias: registerG4zSREzrE9Meta?.alias || [],
    redirect: registerG4zSREzrE9Meta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/register.vue").then(m => m.default || m)
  },
  {
    name: talent_45settingsrdsZCtBblgMeta?.name ?? "talent-settings",
    path: talent_45settingsrdsZCtBblgMeta?.path ?? "/talent-settings",
    meta: talent_45settingsrdsZCtBblgMeta || {},
    alias: talent_45settingsrdsZCtBblgMeta?.alias || [],
    redirect: talent_45settingsrdsZCtBblgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/talent-settings.vue").then(m => m.default || m)
  },
  {
    name: talentskSoG6oCIYuMeta?.name ?? "talents",
    path: talentskSoG6oCIYuMeta?.path ?? "/talents",
    meta: talentskSoG6oCIYuMeta || {},
    alias: talentskSoG6oCIYuMeta?.alias || [],
    redirect: talentskSoG6oCIYuMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/talents.vue").then(m => m.default || m)
  },
  {
    name: termsLEyfL24upgMeta?.name ?? "terms",
    path: termsLEyfL24upgMeta?.path ?? "/terms",
    meta: termsLEyfL24upgMeta || {},
    alias: termsLEyfL24upgMeta?.alias || [],
    redirect: termsLEyfL24upgMeta?.redirect || undefined,
    component: () => import("/var/lib/jenkins/workspace/Goodtalent Legacy DEV UI/pages/terms.vue").then(m => m.default || m)
  }
]